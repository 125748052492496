export const CloseBoldSVG = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4141 1.19489C16.3575 1.05854 16.2747 0.934662 16.1702 0.830316C16.0658 0.725636 15.9417 0.642581 15.8051 0.585902C15.6684 0.529192 15.5219 0.5 15.374 0.5C15.226 0.5 15.0795 0.529192 14.9428 0.585902C14.8063 0.642531 14.6824 0.72549 14.578 0.83004C14.5778 0.830189 14.5777 0.830339 14.5775 0.830488L0.829713 14.5783C0.618601 14.7894 0.5 15.0757 0.5 15.3743C0.5 15.6728 0.618601 15.9592 0.829713 16.1703C1.04082 16.3814 1.32715 16.5 1.62571 16.5C1.92427 16.5 2.2106 16.3814 2.42171 16.1703L16.1695 2.42248C16.1696 2.42237 16.1697 2.42226 16.1699 2.42214C16.1699 2.42211 16.1699 2.42207 16.17 2.42204C16.2745 2.31763 16.3575 2.19365 16.4141 2.05718C16.4708 1.92052 16.5 1.774 16.5 1.62604C16.5 1.47807 16.4708 1.33156 16.4141 1.19489Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M0.83004 0.83004C0.725491 0.934443 0.642531 1.05842 0.585902 1.19489C0.529191 1.33156 0.5 1.47807 0.5 1.62604C0.5 1.774 0.529191 1.92052 0.585902 2.05718C0.642561 2.19373 0.725579 2.31776 0.830206 2.4222C0.8303 2.42229 0.830394 2.42239 0.830488 2.42248L14.5783 16.1703C14.7894 16.3814 15.0757 16.5 15.3743 16.5C15.6728 16.5 15.9592 16.3814 16.1703 16.1703C16.3814 15.9592 16.5 15.6728 16.5 15.3743C16.5 15.0757 16.3814 14.7894 16.1703 14.5783L2.42248 0.830488C2.42239 0.830394 2.42229 0.8303 2.4222 0.830206C2.31776 0.725579 2.19373 0.642561 2.05718 0.585902C1.92052 0.529191 1.774 0.5 1.62604 0.5C1.47807 0.5 1.33156 0.529191 1.19489 0.585902C1.05842 0.642531 0.934443 0.725491 0.83004 0.83004Z"
        fill="black"
        stroke="black"
      />
    </svg>
  );
};
