export const ListeBlocsSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
    >
      <g clipPath="url(#clip0_46_928)">
        <rect x="-12" y="-12" width="51" height="51" fill="#D9D9D9" />
        <rect
          x="5.5"
          y="5.5"
          width="12"
          height="12"
          fill="#D9D9D9"
          stroke="black"
        />
        <rect
          x="21.5"
          y="5.5"
          width="12"
          height="12"
          fill="#D9D9D9"
          stroke="black"
        />
        <rect
          x="21.5"
          y="21.5"
          width="12"
          height="12"
          fill="#D9D9D9"
          stroke="black"
        />
        <rect
          x="5.5"
          y="21.5"
          width="12"
          height="12"
          fill="#D9D9D9"
          stroke="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_928">
          <rect width="39" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
