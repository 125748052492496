import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function ListeEmployeTable(props) {
  const { filteredEmployees } = props;
  const navigate = useNavigate();

  const LinkToPage = (slug) => {
    navigate("/collaborateurs/" + slug);
  };

  return (
    <div className="collabo-list">
      <Table bordered hover>
        <thead>
          <tr>
            <th>Nom Prénom </th>
            <th>Poste</th>
            <th>Type de Contrat</th>
            <th>Ville</th>
            <th>Statut</th>
          </tr>
        </thead>
        <tbody>
          {filteredEmployees.length === 0 ? (
            <tr>
              <td colSpan="5">Aucun résultat trouvé</td>
            </tr>
          ) : (
            filteredEmployees.map((employee, index) => (
              <tr key={index} onClick={() => LinkToPage(employee.slug)}>
                <td>
                  <strong>
                    <span style={{ textTransform: "uppercase" }}>
                      {employee.last_name}
                    </span>{" "}
                    {employee.first_name}
                  </strong>
                </td>
                <td>{employee.job}</td>
                <td>{employee.contract_type}</td>
                <td>{employee.city}</td>
                <td>{employee.status}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default ListeEmployeTable;
