import React, { useEffect, useState } from "react";
import flagFR from "../../../../../assets/img/fr.png";
import flagEN from "../../../../../assets/img/gb.png";
import { CloseSVG } from "../../../svg/close";
import { LoaderSVG } from "../../../svg/loading";

function JobEditPopup({
  nameFR,
  nameEN,
  onNameFRChange,
  onNameENChange,
  idJob,
  onUpdateJob,
  onCancelEdit,
}) {
  const [loading, setLoading] = useState(false);
  const [nameENUpdate, setnameENUpdate] = useState(nameEN);
  const [nameFRUpdate, setnameFRUpdate] = useState(nameFR);

  const handleUpdateJob = async () => {
    if (nameENUpdate && nameFRUpdate && idJob) {
      setLoading(true);
      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        id_job: idJob,
        name_job_eng: nameENUpdate,
        name_job_fr: nameFRUpdate,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_UPDATE_JOB,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 200) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)
          console.log(result);

          onNameFRChange(nameFRUpdate);
          onNameENChange(nameENUpdate);

          setLoading(false);
          onUpdateJob();
          onCancelEdit();
        } else {
          console.error("Erreur de requête:", response.status);
          setLoading(false);
          onCancelEdit();
        }
      } catch (error) {
        console.error("Erreur:", error);
        setLoading(false);
        onCancelEdit();
      }
    }
  };

  return (
    <div className="job-add-popup popup">
      <div className="popup-content">
        <span className="close-button" onClick={onCancelEdit}>
          <CloseSVG />
        </span>
        <h2>Modifier un Job</h2>
        <p>
          Merci de préciser le nom en anglais et français. Pour vous aider :
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.deepl.com/translator"
          >
            Deepl
          </a>
        </p>

        <div className="job-lang">
          <label htmlFor="nameEN">
            <img alt="EN" src={flagEN} />
          </label>
          <input
            type="text"
            id="nameEN"
            value={nameENUpdate ? nameENUpdate : ""}
            onChange={(e) => setnameENUpdate(e.target.value)}
            placeholder="Nom de l'emploi en anglais"
          />
        </div>

        <div className="job-lang">
          <label htmlFor="nameFR">
            <img alt="FR" src={flagFR} />
          </label>
          <input
            type="text"
            id="nameFR"
            value={nameFRUpdate ? nameFRUpdate : ""}
            onChange={(e) => setnameFRUpdate(e.target.value)}
            placeholder="Nom de l'emploi en français"
          />
        </div>

        <div className="buttons mt-3">
          {loading ? (
            <LoaderSVG />
          ) : (
            <>
              <button className="btn btn-secondary" onClick={onCancelEdit}>
                Annuler
              </button>
              <button className="btn btn-primary" onClick={handleUpdateJob}>
                Modifier le job
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobEditPopup;
