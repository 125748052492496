import React, { useContext, useState } from "react";
import { LoaderSVG } from "../../../svg/loading";
import { LoaderSVGWhite } from "../../../svg/loadingWhite";
import { AuthorizedUserContext } from "../../../../AuthorizedUserContext";

const Toggle3Positions = ({
  initialValue,
  leftText,
  toggleID,
  listId,
  role,
  onToggleChange,
}) => {
  const positions = [
    { text: "NC", value: null },
    { text: "ToDo", value: 0 },
    { text: "OK", value: 1 },
  ];

  const UserID = useContext(AuthorizedUserContext).id;

  const rolesUserRolesUser = useContext(AuthorizedUserContext).roles;

  const CanEdit = (rolesUserRolesUser) => {
    return rolesUserRolesUser.some(
      (roleUser) =>
        roleUser.id === role || roleUser.id === 3 || roleUser.id === 2
    );
  };

  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isEditingLoadingWhite, setIsEditingLoadingWhite] = useState(false);

  const [selectedPosition, setSelectedPosition] = useState(initialValue);

  const [containerClass, setContainerClass] = useState(
    initialValue === null
      ? "NC"
      : initialValue === false || initialValue === 0
      ? "ToDo"
      : "OK"
  );

  const handlePositionChange = async (value) => {
    if (CanEdit(rolesUserRolesUser)) {
      if (value === 0 || value === 1 || value === null) {
        if (value === null) {
          setIsEditingLoading(true);
          setIsEditingLoadingWhite(false);
        } else if (value === false) {
          setIsEditingLoading(true);
          setIsEditingLoadingWhite(false);
        } else {
          setIsEditingLoadingWhite(true);
          setIsEditingLoading(false);
        }

        // Préparez les en-têtes pour la requête POST
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

        const requestData = {
          id_task: toggleID,
          id_todo_list: listId,
          status: value,
          id_user: UserID,
        };

        // Préparez les options pour la requête POST
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(requestData),
        };

        try {
          // Effectuez la requête POST directement depuis l'application React
          const response = await fetch(
            process.env.REACT_APP_UPDATE_TODO_TASK,
            requestOptions
          );

          // Vérifiez si la requête OPTIONS préalable est nécessaire
          if (response.status === 200) {
            const result = await response.text();
            // Gérez la réponse du serveur (si nécessaire)

            if (value === null) {
              setContainerClass("NC");
            } else if (value === 0) {
              setContainerClass("ToDo");
            } else {
              setContainerClass("OK");
            }

            setSelectedPosition(value);

            setIsEditingLoadingWhite(false);
            setIsEditingLoading(false);
            onToggleChange();
          } else {
            console.error("Erreur de requête:", response.status);

            setIsEditingLoadingWhite(false);
            setIsEditingLoading(false);
          }
        } catch (error) {
          console.error("Erreur:", error);

          setIsEditingLoadingWhite(false);
          setIsEditingLoading(false);
        }
      }
    }
  };

  return (
    <div className={`toggle-container ${containerClass}`}>
      <div
        className="text-left"
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {isEditingLoading && <LoaderSVG />}
        {isEditingLoadingWhite && <LoaderSVGWhite />} <p>{leftText} :</p>
      </div>

      {!isEditingLoading && !isEditingLoadingWhite && (
        <div
          className={`toggles ${
            !CanEdit(rolesUserRolesUser) ? "disabled" : ""
          }`}
        >
          {positions.map((position) => (
            <button
              key={position.text}
              className={selectedPosition === position.value ? "active" : ""}
              onClick={() => handlePositionChange(position.value)}
            >
              {position.text}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Toggle3Positions;
