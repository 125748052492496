export const HomeSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.69231 19.2858V14.2789C7.69231 13.929 8.07692 13.5718 8.46154 13.5718H11.5385C11.9231 13.5718 12.3077 13.929 12.3077 14.2861V19.2858C12.3077 19.4752 12.3887 19.6569 12.533 19.7908C12.6773 19.9248 12.8729 20 13.0769 20H19.2308C19.4348 20 19.6304 19.9248 19.7747 19.7908C19.919 19.6569 20 19.4752 20 19.2858V9.2864C20.0002 9.19254 19.9804 9.09957 19.9419 9.0128C19.9034 8.92603 19.8468 8.84717 19.7754 8.78072L17.6923 6.84798V2.144C17.6923 1.95457 17.6113 1.7729 17.467 1.63895C17.3227 1.50501 17.1271 1.42976 16.9231 1.42976H15.3846C15.1806 1.42976 14.9849 1.50501 14.8407 1.63895C14.6964 1.7729 14.6154 1.95457 14.6154 2.144V3.99102L10.5446 0.209835C10.4732 0.14332 10.3883 0.0905481 10.2948 0.0545412C10.2014 0.0185342 10.1012 0 10 0C9.89882 0 9.79863 0.0185342 9.70518 0.0545412C9.61172 0.0905481 9.52684 0.14332 9.45538 0.209835L0.224617 8.78072C0.153229 8.84717 0.0966475 8.92603 0.0581064 9.0128C0.0195653 9.09957 -0.000179307 9.19254 1.22692e-06 9.2864V19.2858C1.22692e-06 19.4752 0.0810449 19.6569 0.225304 19.7908C0.369562 19.9248 0.565219 20 0.769232 20H6.92308C7.12709 20 7.32275 19.9248 7.46701 19.7908C7.61126 19.6569 7.69231 19.4752 7.69231 19.2858Z"
        fill="black"
      />
    </svg>
  );
};
