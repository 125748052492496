import React, { useContext, useState } from "react";
import { CloseSVG } from "../../../svg/close";
import { RolesContext } from "./RolesContext";

import { Button, Form } from "react-bootstrap";
import { LoaderSVG } from "../../../svg/loading";

function UserEditPopup({
  emailUser,
  rolesUser,
  idUser,
  onCancelEdit,
  changeEmail,
  changeRoles,
}) {
  const allRoles = useContext(RolesContext);

  const [email, setEmail] = useState(emailUser);
  const [loading, setLoading] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const isEmailValid = emailRegex.test(email);

  const [selectedRoles, setSelectedRoles] = useState(
    rolesUser.map(({ id, ...rest }) => ({ id_role: id, ...rest }))
  );

  const toggleRole = (role) => {
    setSelectedRoles((prevRoles) => {
      if (prevRoles.some((r) => r.id_role === role.id_role)) {
        return prevRoles.filter((r) => r.id_role !== role.id_role);
      } else {
        return [...prevRoles, role];
      }
    });
  };

  const handleUpdateUser = async () => {
    if (isEmailValid) {
      setLoading(true);

      const idRolesArray = selectedRoles.map((item) => item.id_role);

      const requestData = {
        userId: idUser,
        email: email,
        roles: idRolesArray,
      };

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      console.log(requestData);

      try {
        const response = await fetch(
          process.env.REACT_APP_UPDATE_USER,
          requestOptions
        );

        console.log(response);
        if (response.status === 200) {
          const result = await response.text();
          console.log(result);

          changeEmail(email);
          changeRoles(idRolesArray);

          setLoading(false);
          onCancelEdit(false);
        } else {
          console.error("Erreur de requête:", response.status);
          setLoading(false);
          onCancelEdit(false);
        }
      } catch (error) {
        console.error("Erreur:", error);
        setLoading(false);
        onCancelEdit(false);
      }
    } else {
      alert(
        "Oups, il semble que votre adresse e-mail ne soit pas valide. Veuillez la vérifier et réessayer."
      );
    }
  };

  const isButtonDisabled = selectedRoles.length === 0;

  return (
    <div className="user-edit-popup popup">
      <div className="popup-content">
        <span className="close-button" onClick={() => onCancelEdit(false)}>
          <CloseSVG />
        </span>
        <h2>Éditer un utilisateur</h2>

        <label htmlFor="email">Adresse e-mail</label>
        <Form.Control
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="email"
          id="email"
          placeholder="Adresse e-mail de l'utilisateur"
        />

        <label>Rôles</label>
        <div className="buttons buttons-container">
          {allRoles.map((role) => (
            <Button
              key={role.id_role}
              className="button-select"
              variant={
                selectedRoles.some((r) => r.id_role === role.id_role)
                  ? "primary"
                  : "secondary"
              }
              onClick={() => toggleRole(role)}
            >
              {role.name_role}
            </Button>
          ))}
        </div>

        <div className="buttons">
          {loading ? (
            <LoaderSVG />
          ) : (
            <>
              <button
                className="btn btn-secondary"
                onClick={() => onCancelEdit(false)}
              >
                Annuler
              </button>
              <button
                className="btn btn-primary"
                onClick={handleUpdateUser}
                disabled={isButtonDisabled}
              >
                Modifier l'utilisateur
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserEditPopup;
