import React, { useEffect, useState } from "react";
import JobCard from "./cards/jobs/JobCard";
import AddJob from "./cards/jobs/AddJob";
import Loader from "../Loader";

function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // jobs
        const jobsResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "jobs.json?" + new Date().getTime()
        );
        const jobsParsed = await jobsResponse.json();
        setJobs(jobsParsed.jobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddJob = (id, nameEN, nameFR) => {
    // Créez un nouvel job en ajoutant les noms en français et en anglais au tableau des jobs
    const newJob = {
      id_job: id, // Utilisez un identifiant unique
      name_job_fr: nameFR,
      name_job_eng: nameEN,
    };

    setJobs([...jobs, newJob]);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="title-section">
            <h2>Jobs</h2>
            <p>Vous pouvez mettre à jour les différents jobs</p>
          </div>

          <div className="listing-cards">
            {jobs.map((job) => (
              <JobCard
                addJob={handleAddJob}
                key={job.id_job}
                idJob={job.id_job}
                job={job}
              />
            ))}

            <AddJob onAddJob={handleAddJob} />
          </div>
        </>
      )}
    </div>
  );
}

export default Jobs;
