import React, { Component } from "react";

export class Reglages extends Component {
  render() {
    return (
      <div>
        <h1>Reglages</h1>
      </div>
    );
  }
}

export default Reglages;
