import { createForm } from "@use-form/use-form";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  personal_email: "",
  tel: "",
  birthdate: "",
  attachment: "",
  activity: "",
  company: "",
  contract: "",
  package: "",
  potential_incoming_date: "",
  incoming_date: "",
  exiting_date: "",
  job: "",
  team: "",
  manager: "",
  comment: "",
};

export const usePersonForm = createForm({
  initialValues,
});
