import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useWizard } from "react-use-wizard";
import { usePersonForm } from "./usePersonForm";

import { useEffect } from "react";

export function AddressStep() {
  const { previousStep, nextStep } = useWizard();
  const { register, state, setFieldValue } = usePersonForm();
  const { values } = state;

  const [selectedButtons, setSelectedButtons] = useState({
    attachment: "",
    activity: "",
    company: "",
  });

  useEffect(() => {
    setSelectedButtons({
      attachment: Boolean(values.attachment),
      activity: Boolean(values.activity),
      company: Boolean(values.company),
    });
  }, [values]);

  const [activityOptions, setLieuxActiviteOptions] = useState([]);
  const [attachmentOptions, setLieuxRattachementOptions] = useState([]);
  const [companyOptions, setEntreprisesOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //attachement
        const attachementResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "attachment_places.json?" +
            new Date().getTime()
        );
        const attachementParsed = await attachementResponse.json();
        setLieuxRattachementOptions(attachementParsed.attachment_places);

        //activity
        const activitiesResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "activity_places.json?" +
            new Date().getTime()
        );
        const activitiesParsed = await activitiesResponse.json();
        setLieuxActiviteOptions(activitiesParsed.activity_places);

        //companies
        const companiesResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "companies.json?" +
            new Date().getTime()
        );
        const companiesParsed = await companiesResponse.json();
        setEntreprisesOptions(companiesParsed.companies);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleOptionClick = (fieldName, optionId) => {
    setSelectedButtons((prevSelectedButtons) => ({
      ...prevSelectedButtons,
      [fieldName]: optionId,
    }));

    setFieldValue(fieldName, optionId);
  };

  const isAnyButtonNotSelected = () => {
    return (
      !selectedButtons.attachment ||
      !selectedButtons.activity ||
      !selectedButtons.company
    );
  };

  return (
    <Container>
      <h3 className="mt-4">Lieu de rattachement*</h3>
      <Form.Group as={Row}>
        <Form.Control type="hidden" {...register("attachment")} disabled />
        <div className="buttons buttons-container">
          {attachmentOptions.map((option) => (
            <Button
              key={option.id_attachment_place}
              className="button-select"
              variant={
                selectedButtons.attachment === option.id_attachment_place ||
                state.values.attachment === option.id_attachment_place
                  ? "primary"
                  : "secondary"
              }
              onClick={() =>
                handleOptionClick("attachment", option.id_attachment_place)
              }
            >
              {option.name_attachment_place}
            </Button>
          ))}
        </div>
      </Form.Group>

      <h3 className="mt-4">Lieu d'activité*</h3>
      <Form.Group as={Row}>
        <Form.Control type="hidden" {...register("activity")} disabled />
        <div className="buttons buttons-container">
          {activityOptions.map((option) => (
            <Button
              key={option.id_activity_place}
              className="button-select"
              variant={
                selectedButtons.activity === option.id_activity_place ||
                state.values.activity === option.id_activity_place
                  ? "primary"
                  : "secondary"
              }
              onClick={() =>
                handleOptionClick("activity", option.id_activity_place)
              }
            >
              {option.name_activity_place}
            </Button>
          ))}
        </div>
      </Form.Group>

      <h3 className="mt-4">Entreprise*</h3>
      <Form.Group as={Row}>
        <Form.Control type="hidden" {...register("company")} disabled />

        <div className="buttons buttons-container">
          {companyOptions.map((option) => (
            <Button
              key={option.id_company}
              className="button-select"
              variant={
                selectedButtons.company === option.id_company ||
                state.values.company === option.id_company
                  ? "primary"
                  : "secondary"
              }
              onClick={() => handleOptionClick("company", option.id_company)}
            >
              {option.name_company}
            </Button>
          ))}
        </div>
      </Form.Group>

      <div className="buttons mt-4">
        <button
          className="btn btn-primary mt-4"
          style={{ minWidth: "225px" }}
          onClick={nextStep}
          disabled={isAnyButtonNotSelected()}
        >
          Suivant
        </button>
      </div>
      <button className="no-style-btn" onClick={previousStep}>
        {"<"} Page précédente
      </button>
    </Container>
  );
}
