import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { usePersonForm } from "./usePersonForm";
import { useWizard } from "react-use-wizard";
import Select from "react-select";
import { LoaderSVG } from "../../svg/loading";

export function TeamStep(loader) {
  const { previousStep } = useWizard();
  const { register, setFieldValue, state } = usePersonForm();
  const { values } = state;

  const [selectedTeam, setSelectedTeam] = useState("");

  const [selectedManager, setSelectedManager] = useState(null);

  useEffect(() => {
    setSelectedTeam(Boolean(values.team));
  }, [values]);

  const handleTeamButtonClick = (team) => {
    setSelectedTeam(team);
    setFieldValue("team", team);
  };

  const [teamOptions, setTeamOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);

  const isCreateButtonDisabled = () => {
    // Check if a team is selected
    const isTeamSelected = Boolean(selectedTeam);

    return !isTeamSelected;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        //teams
        const teamsResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "teams.json?" + new Date().getTime()
        );
        const teamsParsed = await teamsResponse.json();
        setTeamOptions(teamsParsed.teams);

        //managers
        const managersResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "managers.json?" +
            new Date().getTime()
        );
        const managersParsed = await managersResponse.json();
        setManagerOptions(managersParsed.managers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const containerStyle = {
    // Vos propriétés de style vont ici
    ...(loader.loader && { pointerEvents: "none" }),
    opacity: loader.loader ? 0.5 : 1,
  };

  return (
    <Container>
      <div style={containerStyle}>
        <h3 className="mt-4">Team*</h3>
        <Form.Group as={Row}>
          <Form.Control type="hidden" {...register("team")} disabled />
          <div className="buttons buttons-container">
            {teamOptions.map((option) => (
              <Button
                key={option.id_team}
                className="button-select"
                variant={
                  selectedTeam.id_team === option.id_team ||
                  state.values.team.id_team === option.id_team
                    ? "primary"
                    : "secondary"
                }
                onClick={() => handleTeamButtonClick(option)}
              >
                {option.name_team}
              </Button>
            ))}
          </div>
        </Form.Group>

        <h3 className="mt-4">N+1</h3>
        <Form.Group as={Row}>
          <Col>
            <Form.Control type="hidden" {...register("manager")} disabled />
            <Select
              options={managerOptions.map((nPlus1) => ({
                value: nPlus1.id,
                label: nPlus1.first_name + " " + nPlus1.last_name,
              }))}
              value={state.values.manager}
              onChange={(selectedOption) => {
                setSelectedManager(selectedOption);
                setFieldValue("manager", selectedOption);
              }}
              placeholder="Sélectionnez N+1"
              isClearable={true}
            />
          </Col>
        </Form.Group>

        <h3 className="mt-4">Commentaire</h3>
        <Form.Group as={Row}>
          <Col>
            <Form.Control as="textarea" {...register("comment")} />
          </Col>
        </Form.Group>
      </div>
      {loader.loader ? (
        <LoaderSVG />
      ) : (
        <>
          <div className="buttons mt-4">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ minWidth: "225px" }}
              disabled={isCreateButtonDisabled()} // Disable the button based on the condition
            >
              Créer le collaborateur
            </button>
          </div>

          <button className="no-style-btn" onClick={previousStep}>
            {"<"} Page précédente
          </button>
        </>
      )}
    </Container>
  );
}
