export const LicenceSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M1.75 5.25C1.75 4.78587 1.93437 4.34075 2.26256 4.01256C2.59075 3.68437 3.03587 3.5 3.5 3.5H24.5C24.9641 3.5 25.4092 3.68437 25.7374 4.01256C26.0656 4.34075 26.25 4.78587 26.25 5.25H1.75ZM14 19.25C14.9283 19.25 15.8185 18.8813 16.4749 18.2249C17.1313 17.5685 17.5 16.6783 17.5 15.75C17.5 14.8217 17.1313 13.9315 16.4749 13.2751C15.8185 12.6187 14.9283 12.25 14 12.25C13.0717 12.25 12.1815 12.6187 11.5251 13.2751C10.8687 13.9315 10.5 14.8217 10.5 15.75C10.5 16.6783 10.8687 17.5685 11.5251 18.2249C12.1815 18.8813 13.0717 19.25 14 19.25Z"
        fill="black"
      />
      <path
        d="M0 8.75C0 8.28587 0.184374 7.84075 0.512563 7.51256C0.840752 7.18437 1.28587 7 1.75 7H26.25C26.7141 7 27.1592 7.18437 27.4874 7.51256C27.8156 7.84075 28 8.28587 28 8.75V22.75C28 23.2141 27.8156 23.6592 27.4874 23.9874C27.1592 24.3156 26.7141 24.5 26.25 24.5H1.75C1.28587 24.5 0.840752 24.3156 0.512563 23.9874C0.184374 23.6592 0 23.2141 0 22.75V8.75ZM5.25 8.75C5.25 9.67826 4.88125 10.5685 4.22487 11.2249C3.5685 11.8813 2.67826 12.25 1.75 12.25V19.25C2.67826 19.25 3.5685 19.6187 4.22487 20.2751C4.88125 20.9315 5.25 21.8217 5.25 22.75H22.75C22.75 21.8217 23.1187 20.9315 23.7751 20.2751C24.4315 19.6187 25.3217 19.25 26.25 19.25V12.25C25.3217 12.25 24.4315 11.8813 23.7751 11.2249C23.1187 10.5685 22.75 9.67826 22.75 8.75H5.25Z"
        fill="black"
      />
    </svg>
  );
};
