import React, { useState } from "react";
import { PenSVG } from "../../../svg/pen";
import { TrashSVG } from "../../../svg/trash";
import JobEditPopup from "./JobEditPopup"; // Import your edit popup component
import ConfirmationRemoveJobPopUp from "./ConfirmationRemoveJobPopUp";

function JobCard(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [nameFR, setNameFR] = useState(props.job.name_job_fr);
  const [nameEN, setNameEN] = useState(props.job.name_job_eng);
  const [idJob] = useState(props.idJob);
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleNameFRChange = (name) => {
    setNameFR(name);
  };

  const handleNameENChange = (name) => {
    setNameEN(name);
  };

  const showDeleteConfirmation = () => {
    setShowConfirmation(true);
  };

  const hideDeleteConfirmation = () => {
    setShowConfirmation(false);
  };

  const UpdateJob = () => {
    setIsEditing(false);
    // Perform the actual update operation here with the new nameFR and nameEN values
  };

  const DeleteJob = () => {
    showDeleteConfirmation();
  };

  const confirmDelete = () => {
    setIsCardVisible(false);
    hideDeleteConfirmation();
  };

  return (
    isCardVisible && (
      <div className="admin-card job" data-id={props.idJob}>
        {isEditing && (
          <JobEditPopup
            nameFR={nameFR}
            nameEN={nameEN}
            onNameFRChange={handleNameFRChange}
            onNameENChange={handleNameENChange}
            idJob={idJob}
            onUpdateJob={UpdateJob}
            onCancelEdit={toggleEditing}
          />
        )}

        <div className="title">
          <p className="mb-0" style={{ fontWeight: 600 }}>
            {nameFR && nameFR}
            {nameFR && nameEN && " / "} {nameEN && nameEN}
          </p>
        </div>

        <div className="modify">
          {!isEditing && (
            <>
              <button onClick={toggleEditing}>
                <PenSVG />
              </button>
              <button onClick={DeleteJob}>
                <TrashSVG />
              </button>
            </>
          )}
        </div>

        {showConfirmation && (
          <ConfirmationRemoveJobPopUp
            message={`Êtes-vous sûr de vouloir supprimer le job : ${
              nameEN + (nameFR ? " / " + nameFR : "")
            } ?`}
            onConfirm={confirmDelete}
            onCancel={hideDeleteConfirmation}
            jobObject={idJob}
            addjob={props.addJob}
          />
        )}
      </div>
    )
  );
}

export default JobCard;
