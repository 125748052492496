import React, { useState } from "react";
import { PenSVG } from "../../../svg/pen";
import { TrashSVG } from "../../../svg/trash";
import { ValidSVG } from "../../../svg/valide";
import { CloseBoldSVG } from "../../../svg/close-bold";
import { LoaderSVG } from "../../../svg/loading";
import ConfirmationRemoveContractPopUp from "./ConfirmationRemoveContractPopUp";

function ContractCard(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(props.name);
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [tmpName, setTmpName] = useState(props.name);

  const toggleEditing = () => {
    setTmpName(name);
    setIsEditing(!isEditing);
  };

  const EditContract = async () => {
    if (name) {
      setIsEditingLoading(true);
      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        id_contract: props.idContract,
        name_contract: name,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_UPDATE_CONTRACT_TYPE,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 200) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)
          console.log(result);
          setName([name]);
          setIsEditing(false);
          setIsEditingLoading(false);
        } else {
          console.error("Erreur de requête:", response.status);
          setIsEditingLoading(false);
        }
      } catch (error) {
        console.error("Erreur:", error);
        setIsEditingLoading(false);
      }
    }
  };
  const handleCloseClick = () => {
    setIsEditing(false); // Réinitialiser à l'état initial (mode lecture seule)
    setName(tmpName); // Réinitialiser la valeur de 'name' avec la valeur initiale
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const confirmDelete = () => {
    setIsCardVisible(false);
    hideDeleteConfirmation();
  };

  const showDeleteConfirmation = () => {
    setShowConfirmation(true);
  };

  const hideDeleteConfirmation = () => {
    setShowConfirmation(false);
  };

  return isCardVisible ? (
    <div className="admin-card contract" data-id={props.idContract}>
      {showConfirmation && (
        <ConfirmationRemoveContractPopUp
          message={`Êtes-vous sûr de vouloir supprimer le contrat : ${name}?`}
          contractObject={props.idContract}
          onCancel={hideDeleteConfirmation}
          onConfirm={confirmDelete}
          loader={isDeleteLoading}
        />
      )}
      {isEditing ? (
        <div className="edit-mode">
          <input type="text" value={name} onChange={handleNameChange} />

          {isEditingLoading ? (
            <LoaderSVG />
          ) : (
            <>
              <button className="validBtn bg-blue" onClick={EditContract}>
                <ValidSVG />
              </button>

              <button className="closeBtn bg-red" onClick={handleCloseClick}>
                <CloseBoldSVG />
              </button>
            </>
          )}
        </div>
      ) : (
        <div className="title">{name}</div>
      )}
      {isEditing ? null : (
        <div className="modify">
          <button onClick={toggleEditing}>
            <PenSVG />
          </button>
          <button onClick={showDeleteConfirmation}>
            <TrashSVG />
          </button>
        </div>
      )}
    </div>
  ) : null;
}

export default ContractCard;
