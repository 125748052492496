import React, { useEffect, useState } from "react";
import { CloseSVG } from "../../svg/close";
import { Form } from "react-bootstrap";
import ReactSelect from "react-select";
import { LoaderSVG } from "../../svg/loading";

function UpdateTaskModal({ task, onClose, onUpdate }) {
  const [loader, setLoader] = useState(false);
  const [auto, setAuto] = useState(task.automatic);

  const [taskName, setTaskName] = useState(task.name);
  const [rappel, setRappel] = useState(task.active);
  const [daysNumber, setDaysNumber] = useState(task.days ? task.days : 1);
  const [temporality, setTemporality] = useState(
    task.temporality ? task.temporality : 0
  );

  const [referentialsOptions, setReferentialsOptions] = useState([]);
  const [referential, setReferential] = useState(null); // Modifié pour utiliser null comme valeur initiale

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

  console.log(task);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const referentialsResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "referentials.json?" +
            new Date().getTime()
        );
        const referentialsParsed = await referentialsResponse.json();

        setReferentialsOptions(
          referentialsParsed.referentials.map((ref) => ({
            value: ref.id_referential,
            label: ref.label_referential,
          }))
        );

        // Définir l'option sélectionnée en utilisant l'état de la tâche actuelle
        if (task.referential) {
          const selectedOption = referentialsParsed.referentials.find(
            (ref) => ref.id_referential === task.referential
          );
          if (selectedOption) {
            setReferential({
              // Assurez-vous de définir un objet conforme aux attentes de ReactSelect
              value: selectedOption.id_referential,
              label: selectedOption.label_referential,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [task.referential]); // Ici, je suppose que task.referential ne change pas. Sinon, ajoutez d'autres dépendances selon le cas.

  if (!task) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const taskData = {
      id_task: task.id,
      label: taskName,
      active: rappel ? 1 : 0,
      days: parseInt(daysNumber),
      temporality: temporality,
      referential: referential.value ? referential.value : null,
      automatic: auto ? 1 : 0,
    };

    console.log(taskData);

    try {
      const response = await fetch(process.env.REACT_APP_UPDATE_TASK, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(taskData),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Task updated successfully:", responseData);

      await onUpdate();

      await onClose();
      // Traiter la réponse ici, par exemple, mettre à jour l'interface utilisateur
    } catch (error) {
      console.error("Failed to create task:", error);
    }

    setLoader(false);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onClose}>
          <CloseSVG />
        </span>
        <h2>Modifier Tâche: {task.name}</h2>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Nom de la tâche</Form.Label>
            <Form.Control
              /*  placeholder="Nom de la tâche" */
              type="text"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              id="default-checkbox"
              label="Rappel hebdo"
              checked={rappel} // Use `checked` for controlling the checkbox's checked state
              onChange={(e) => setRappel(e.target.checked)} // Update based on `e.target.checked`
            />
          </Form.Group>

          {/*   <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              id="auto-checkbox"
              label="Automatisation"
              checked={auto} // Use `checked` for controlling the checkbox's checked state
              onChange={(e) => setAuto(e.target.checked)} // Update based on `e.target.checked`
            />
          </Form.Group> */}

          <Form.Group className="mb-3">
            <Form.Label>Jours</Form.Label>
            <Form.Control
              /*  placeholder="Nom de la tâche" */
              type="number"
              value={daysNumber}
              onChange={(e) => setDaysNumber(e.target.value)}
            />
          </Form.Group>

          <Form.Label>Temporalité</Form.Label>
          <Form.Group className="mb-3 d-flex gap-3">
            <Form.Check
              label="Avant"
              name="tamporalite"
              type="radio"
              id={`radio-1`}
              onChange={() => setTemporality(0)}
              checked={temporality === 0}
            />
            <Form.Check
              label="Après"
              name="tamporalite"
              type="radio"
              id={`radio-2`}
              onChange={() => setTemporality(1)}
              checked={temporality === 1}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Réferentiel</Form.Label>
            <ReactSelect
              options={referentialsOptions}
              onChange={(selectedOption) => {
                // Mettre à jour l'état avec la nouvelle option sélectionnée
                setReferential(selectedOption);
                console.log(selectedOption.value);
              }}
              value={referential} // Passer l'objet de l'option sélectionnée
              placeholder="Référentiel"
            />
          </Form.Group>

          <div className="buttons">
            {loader ? (
              <LoaderSVG />
            ) : (
              <>
                <button
                  type="button"
                  onClick={onClose}
                  className="btn btn-secondary"
                >
                  Annuler
                </button>

                <button type="submit" className="btn btn-primary">
                  Confirmer la modification
                </button>
              </>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
}

export default UpdateTaskModal;
