export const ContractSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_46_696)">
        <path
          d="M14.5203 0H6.25C5.4212 0 4.62634 0.32924 4.04029 0.915291C3.45424 1.50134 3.125 2.2962 3.125 3.125V21.875C3.125 22.7038 3.45424 23.4987 4.04029 24.0847C4.62634 24.6708 5.4212 25 6.25 25H18.75C19.5788 25 20.3737 24.6708 20.9597 24.0847C21.5458 23.4987 21.875 22.7038 21.875 21.875V7.35469C21.8749 6.94032 21.7102 6.54296 21.4172 6.25L15.625 0.457813C15.332 0.164767 14.9347 8.84959e-05 14.5203 0ZM14.8438 5.46875V2.34375L19.5312 7.03125H16.4062C15.9918 7.03125 15.5944 6.86663 15.3014 6.5736C15.0084 6.28058 14.8438 5.88315 14.8438 5.46875ZM7.03125 14.0625C6.82405 14.0625 6.62534 13.9802 6.47882 13.8337C6.33231 13.6872 6.25 13.4885 6.25 13.2812C6.25 13.074 6.33231 12.8753 6.47882 12.7288C6.62534 12.5823 6.82405 12.5 7.03125 12.5H17.9688C18.176 12.5 18.3747 12.5823 18.5212 12.7288C18.6677 12.8753 18.75 13.074 18.75 13.2812C18.75 13.4885 18.6677 13.6872 18.5212 13.8337C18.3747 13.9802 18.176 14.0625 17.9688 14.0625H7.03125ZM6.25 16.4062C6.25 16.199 6.33231 16.0003 6.47882 15.8538C6.62534 15.7073 6.82405 15.625 7.03125 15.625H17.9688C18.176 15.625 18.3747 15.7073 18.5212 15.8538C18.6677 16.0003 18.75 16.199 18.75 16.4062C18.75 16.6135 18.6677 16.8122 18.5212 16.9587C18.3747 17.1052 18.176 17.1875 17.9688 17.1875H7.03125C6.82405 17.1875 6.62534 17.1052 6.47882 16.9587C6.33231 16.8122 6.25 16.6135 6.25 16.4062ZM7.03125 20.3125C6.82405 20.3125 6.62534 20.2302 6.47882 20.0837C6.33231 19.9372 6.25 19.7385 6.25 19.5312C6.25 19.324 6.33231 19.1253 6.47882 18.9788C6.62534 18.8323 6.82405 18.75 7.03125 18.75H13.2812C13.4885 18.75 13.6872 18.8323 13.8337 18.9788C13.9802 19.1253 14.0625 19.324 14.0625 19.5312C14.0625 19.7385 13.9802 19.9372 13.8337 20.0837C13.6872 20.2302 13.4885 20.3125 13.2812 20.3125H7.03125Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_696">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
