import React, { useEffect, useState } from "react";

import CompanyCard from "./cards/companies/CompanyCard";
import AddCompany from "./cards/companies/AddCompany";
import LoadCard from "./cards/LoadCard";

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [loadingCompany, setLoadingCompany] = useState(false);

  const loadData = async () => {
    try {
      //companies
      const companiesResponse = await fetch(
        process.env.REACT_APP_DATA_URL +
          "companies.json?" +
          new Date().getTime()
      );
      const companiesParsed = await companiesResponse.json();
      setCompanies(companiesParsed.companies);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Chargez les données initiales
    loadData();
  }, []);

  const handleAddCompany = async (name) => {
    setLoadingCompany(true);
    if (name) {
      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        name_company: name,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_CREATE_COMPANY,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 201) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)

          let idCompany = JSON.parse(result).companyId;

          const newCompany = {
            id_company: idCompany,
            name_company: name,
          };
          setCompanies([...companies, newCompany]);
          setLoadingCompany(false);
        } else {
          console.error("Erreur de requête:", response.status);
          setLoadingCompany(false);
        }
      } catch (error) {
        console.error("Erreur:", error);
      }
    }
  };

  return (
    <div>
      <div className="title-section">
        <h2>Entreprises</h2>
        <p>Vous pouvez mettre à jour les différentes entreprises.</p>
      </div>

      <div className="listing-cards">
        {companies.map((company) => (
          <CompanyCard
            key={company.id_company}
            idCompany={company.id_company}
            name={company.name_company}
          />
        ))}

        {loadingCompany && <LoadCard />}

        <AddCompany onAddCompany={handleAddCompany} />
      </div>
    </div>
  );
}

export default Companies;
