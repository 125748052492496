import React from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ErrorPage = () => {
  return (
    <Container>
      <div style={{ textAlign: "center" }}>
        <h1>404</h1>
        <p>Page introuvable</p>
      </div>
      <Row>
        <div className="buttons">
          <NavLink className="btn btn-primary  btn-menu" to="/">
            Retour à l'accueil
          </NavLink>
        </div>
      </Row>
    </Container>
  );
};

export default ErrorPage;
