import React, { Component } from "react";

export class Unauthorized extends Component {
  render() {
    return (
      <div>
        <h2>Vous n'avez pas le droit d'acceder à cette page.</h2>
      </div>
    );
  }
}

export default Unauthorized;
