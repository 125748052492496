import React, { useEffect, useState } from "react";
import { CloseSVG } from "../../../svg/close";

import Select from "react-select";

import { LoaderSVG } from "../../../svg/loading";

function ConfirmationRemoveAttachmentPopUp({
  message,
  onConfirm,
  onCancel,
  attachmentPlaceObject,
}) {
  const [AttachmentPlaceOptions, setAttachmentPlaceOptions] = useState([]);
  const [selectedAttachmentPlace, setSelectedAttachmentPlace] = useState(null);

  const [buttonDisable, setButtonDisable] = useState(true);
  const [IsDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const attachmentPlacesResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "attachment_places.json?" +
            new Date().getTime()
        );
        const attachmentPlacesParsed = await attachmentPlacesResponse.json();

        const filteredAttachmentPlaceOptions =
          attachmentPlacesParsed.attachment_places.filter(
            (attachmentPlace) =>
              attachmentPlace.id_attachment_place !== attachmentPlaceObject
          );

        setAttachmentPlaceOptions(filteredAttachmentPlaceOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [attachmentPlaceObject]);

  const deleteAttachmentPlace = async () => {
    if (selectedAttachmentPlace) {
      setIsDeleteLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        id_attachment: attachmentPlaceObject,
        new_attachment: selectedAttachmentPlace.value,
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      console.log(requestData);

      try {
        const response = await fetch(
          process.env.REACT_APP_DELETE_ATTACHMENT_PLACE,
          requestOptions
        );

        if (response.status === 200) {
          const result = await response.text();
          console.log(result);

          setIsDeleteLoading(false);
          onConfirm();
        } else {
          console.error("Request error:", response.status);
          setIsDeleteLoading(false);
          alert("Deletion error");
        }
      } catch (error) {
        setIsDeleteLoading(false);
        console.error("Error:", error);
      }
    } else {
      console.warn("No attachment place selected.");
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onCancel}>
          <CloseSVG />
        </span>
        <h2>Êtes-vous sûr de vouloir supprimer ?</h2>
        <p>{message}</p>

        {IsDeleteLoading ? (
          <LoaderSVG />
        ) : (
          <>
            <div
              className="input-container"
              style={{ display: "flex", marginBottom: "2rem", gap: "10px" }}
            >
              <div className="attachment-place-fr">
                <label>Assigner à l'employeé le lieu de rattachment:</label>

                <Select
                  options={AttachmentPlaceOptions.map((attachmentPlace) => ({
                    value: attachmentPlace.id_attachment_place,
                    label: attachmentPlace.name_attachment_place
                      ? attachmentPlace.name_attachment_place
                      : "",
                  }))}
                  placeholder="Choisir le lieu de rattachment"
                  isClearable={true}
                  onChange={(selectedOption) => {
                    setSelectedAttachmentPlace(selectedOption);
                    setButtonDisable(!selectedOption);
                  }}
                />
              </div>
            </div>

            <div className="buttons">
              <button onClick={onCancel} className="btn btn-secondary">
                Annuler
              </button>

              <button
                onClick={deleteAttachmentPlace}
                disabled={buttonDisable}
                className="btn btn-primary"
              >
                Confirmer la suppression
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ConfirmationRemoveAttachmentPopUp;
