import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ReactSelect from "react-select";
import { CloseSVG } from "../../svg/close";
import { LoaderSVG } from "../../svg/loading";

function AddTaskModal({
  onClose,

  category,
  categoryID,
  taskType,
  onReloadparent,
}) {
  const [taskName, setTaskName] = useState("");
  const [rappel, setRappel] = useState(false);
  const [auto, setAuto] = useState(false);
  const [loader, setLoader] = useState(false);
  const [taskNameError, setTaskNameError] = useState(""); // Ajout d'un état pour gérer l'erreur de nom de la tâche

  const [daysNumber, setDaysNumber] = useState(1);
  const [temporality, setTemporality] = useState(0);
  const [referential, setReferential] = useState(null);

  const [referentialsOptions, setReferentialsOptions] = useState([]);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const referentialsResponse = await fetch(
          `${
            process.env.REACT_APP_DATA_URL
          }referentials.json?${new Date().getTime()}`
        );
        const referentialsParsed = await referentialsResponse.json();
        const options = referentialsParsed.referentials.map((referential) => ({
          value: referential.id_referential,
          label: referential.label_referential,
        }));
        setReferentialsOptions(options);
        // Set default referential if options array is not empty
        if (options.length > 0) {
          setReferential(options[0]); // Set the first option as default, adjust as needed
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const taskData = {
      label: taskName,
      id_role: categoryID,
      type: taskType,
      active: rappel ? 1 : 0,
      days: parseInt(daysNumber),
      temporality: temporality,
      referential: referential && referential.value ? referential.value : null,
      automatic: auto ? 1 : 0,
    };

    console.log(taskData);

    if (!taskName.trim()) {
      setTaskNameError("Le nom de la tâche est obligatoire.");
      setLoader(false);
      return;
    } else {
      setTaskNameError(""); // Réinitialisez l'erreur si le nom est valide
    }

    try {
      const response = await fetch(process.env.REACT_APP_CREATE_TASK, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(taskData),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Task created successfully:", responseData);

      await onReloadparent();
      await onClose();
      // Traiter la réponse ici, par exemple, mettre à jour l'interface utilisateur
    } catch (error) {
      console.error("Failed to create task:", error);
    }

    setLoader(false);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onClose}>
          <CloseSVG />
        </span>
        <h2>Ajouter une nouvelle tâche</h2>
        Attribué à : <strong>{category}</strong>
        <br />
        Liste des tâches : <strong>{taskType}</strong>
        <br />
        <br />
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Nom de la tâche</Form.Label>
            <Form.Control
              type="text"
              value={taskName}
              onChange={(e) => {
                setTaskName(e.target.value);
                if (taskNameError) setTaskNameError(""); // Réinitialise l'erreur lors de la modification du champ
              }}
            />
            {taskNameError && (
              <div style={{ color: "red" }}>{taskNameError}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              id="default-checkbox"
              label="Rappel hebdo"
              checked={rappel} // Use `checked` for controlling the checkbox's checked state
              onChange={(e) => setRappel(e.target.checked)} // Update based on `e.target.checked`
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              id="dauto-checkbox"
              label="Automatisation"
              checked={auto} // Use `checked` for controlling the checkbox's checked state
              onChange={(e) => setAuto(e.target.checked)} // Update based on `e.target.checked`
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Jours</Form.Label>
            <Form.Control
              /*  placeholder="Nom de la tâche" */
              type="number"
              value={daysNumber}
              onChange={(e) => setDaysNumber(e.target.value)}
            />
          </Form.Group>

          <Form.Label>Temporalité</Form.Label>
          <Form.Group className="mb-3 d-flex gap-3">
            <Form.Check
              label="Avant"
              name="tamporalite"
              type="radio"
              id={`radio-1`}
              checked
              onChange={() => setTemporality(0)}
            />
            <Form.Check
              label="Après"
              name="tamporalite"
              type="radio"
              id={`radio-2`}
              onChange={() => setTemporality(1)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Réferentiel</Form.Label>
            <ReactSelect
              options={referentialsOptions}
              onChange={(selectedOption) => {
                setReferential(selectedOption);
              }}
              value={referential} // Ensure ReactSelect displays the current referential state
              placeholder="Réferentiel"
            />
          </Form.Group>

          <div className="buttons">
            {loader ? (
              <LoaderSVG />
            ) : (
              <>
                <button
                  type="button"
                  onClick={onClose}
                  className="btn btn-secondary"
                >
                  Annuler
                </button>

                <button type="submit" className="btn btn-primary">
                  Confirmer l'ajout
                </button>
              </>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddTaskModal;
