import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Toggle3Positions from "./components/Toggle3Positions";
import { AuthorizedUserContext } from "../../../AuthorizedUserContext";
import { LoaderSVG } from "../../svg/loading";

function ProfilCreate(props) {
  const data = props.data;
  const idEmployee = props.idEmployee;

  const TogglesHR = data.data.RH;
  const TogglesAccounting = data.data.Compta;
  const TogglesIt = data.data.IT;
  const TogglesOther = data.data.Autres;
  const [IsCreateLoading, setIsCreateLoading] = useState(false);
  const User = useContext(AuthorizedUserContext);

  const rolesUserRolesUser = useContext(AuthorizedUserContext).roles;

  const autoriserAffichageRH = (rolesUserRolesUser) => {
    return rolesUserRolesUser.some((role) => role.id === 2 || role.id === 3);
  };

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

  const onToggleChange = async () => {
    // Vous pouvez passer des arguments à cette fonction si nécessaire, par exemple l'ID du toggle qui a changé
    console.log("Un toggle a changé, mise à jour nécessaire");
    props.onReloadParent(); // Si vous utilisez cette méthode pour informer le parent de recharger les données
  };

  useEffect(() => {
    // Logic to reload the component when reloadParent changes
    console.log("ProfilCreate component reloaded");
  }, [props.profileData, props.onReloadParent]);

  const CancelList = async () => {
    setIsCreateLoading(true);

    const confirmCancel = window.confirm(
      "Êtes-vous sûr de vouloir supprimer la liste des tâches?"
    );

    if (confirmCancel) {
      const requestData = {
        todo_list_id: data.id_todo_list,
      };

      // Prepare options for the POST request
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Perform the POST request directly from the React application
        const response = await fetch(
          process.env.REACT_APP_CANCEL_TODO_LIST,
          requestOptions
        );

        //update employee status
        const statusRequest = {
          id_employee: idEmployee,
          status: 1,
        };

        const statusRequestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(statusRequest),
        };

        await fetch(
          process.env.REACT_APP_UPDATE_EMPLOYEE_STATUS,
          statusRequestOptions
        ).then((response) => {
          if (!response.ok) {
            throw new Error(
              `Failed to update employee status, status: ${response.status}`
            );
          }
        });

        // Check if the OPTIONS request is necessary
        if (response.status === 200) {
          const result = await response.text();
          // Handle the server response if needed
          console.log(result);

          setIsCreateLoading(false);
          // Call the parent callback to trigger a reload
          props.onReloadParent();
        } else {
          console.error("Request error:", response.status);
          alert("Error : can't cancel todo list");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setIsCreateLoading(false);
    }
  };

  const CreateEmploye = async () => {
    setIsCreateLoading(true);
    const confirmCreate = window.confirm(
      "Êtes-vous sûr de vouloir valider l'entrée ?"
    );

    if (confirmCreate) {
      const requestData = {
        id_todo_list: data.id_todo_list,
        type: "entree",
        id_employee: idEmployee,
        validator: User.id,
      };

      console.log(requestData);

      // Prepare options for the POST request
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Perform the POST request directly from the React application
        const response = await fetch(
          process.env.REACT_APP_CONFIRM_TODO_LIST_CLOSE,
          requestOptions
        );

        //add history
        const historyRequest = {
          employe: idEmployee,
          type_log: 1,
          comment: 'Liste des tâches "Création" validé',
          validator: User.id,
        };

        const historyRequestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(historyRequest),
        };

        await fetch(
          process.env.REACT_APP_CREATE_HISTORY,
          historyRequestOptions
        ).then((response) => {
          if (!response.ok) {
            throw new Error(
              `Failed to create history, status: ${response.status}`
            );
          }
        });

        // Check if the OPTIONS request is necessary
        if (response.status === 200) {
          const result = await response.text();
          // Handle the server response if needed
          console.log(result);

          // Call the parent callback to trigger a reload
          props.onReloadParent();

          setIsCreateLoading(false);
        } else {
          console.error("Request error:", response.status);
          alert("Error creating employee");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setIsCreateLoading(false);
      console.log("Creation canceled");
    }
  };

  return (
    <div className="bg-gray p-4 mb-4 create-checks checks">
      <h3 className="mb-2">Création</h3>

      <Row>
        <Col size={6}>
          {TogglesHR && TogglesHR.length > 0 && (
            <div className="user-checks">
              <h5>Ressources Humaines</h5>

              <div className="toggle-list">
                {TogglesHR.map((toggle, index) => (
                  <Toggle3Positions
                    key={index}
                    role={toggle.role}
                    listId={data.id_todo_list}
                    toggleID={toggle.id}
                    initialValue={toggle.status}
                    leftText={toggle.text}
                    onToggleChange={onToggleChange}
                  />
                ))}
              </div>
            </div>
          )}
          {TogglesAccounting && TogglesAccounting.length > 0 && (
            <div className="user-checks">
              <h5>Compta</h5>

              <div className="toggle-list">
                {TogglesAccounting.map((toggle, index) => (
                  <Toggle3Positions
                    key={index}
                    role={toggle.role}
                    listId={data.id_todo_list}
                    toggleID={toggle.id}
                    initialValue={toggle.status}
                    leftText={toggle.text}
                    onToggleChange={onToggleChange}
                  />
                ))}
              </div>
            </div>
          )}
        </Col>

        <Col size={6}>
          {TogglesIt && TogglesIt.length > 0 && (
            <div className="user-checks">
              <h5>IT</h5>
              <div className="toggle-list">
                {TogglesIt.map((toggle, index) => (
                  <Toggle3Positions
                    key={index}
                    role={toggle.role}
                    listId={data.id_todo_list}
                    toggleID={toggle.id}
                    initialValue={toggle.status}
                    leftText={toggle.text}
                    onToggleChange={onToggleChange}
                  />
                ))}
              </div>
            </div>
          )}
          {TogglesOther && TogglesOther.length > 0 && (
            <div className="user-checks">
              <h5>Autres</h5>
              <div className="toggle-list">
                {TogglesOther.map((toggle, index) => (
                  <Toggle3Positions
                    key={index}
                    role={toggle.role}
                    listId={data.id_todo_list}
                    toggleID={toggle.id}
                    initialValue={toggle.status}
                    leftText={toggle.text}
                    onToggleChange={onToggleChange}
                  />
                ))}
              </div>
            </div>
          )}
        </Col>
      </Row>

      {autoriserAffichageRH(rolesUserRolesUser) && (
        <Row>
          <div className="buttons">
            {IsCreateLoading ? (
              <LoaderSVG />
            ) : (
              <>
                <button
                  type="button"
                  onClick={CancelList}
                  className="btn btn-secondary  p-15"
                >
                  Annuler la liste de tâches
                </button>
                <button
                  type="button"
                  onClick={CreateEmploye}
                  className="btn btn-primary  p-15"
                >
                  Valider la création
                </button>
              </>
            )}
          </div>
        </Row>
      )}
    </div>
  );
}

export default ProfilCreate;
