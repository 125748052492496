import React, { useContext, useState } from "react";
import { PenSVG } from "../../../svg/pen";
import { TrashSVG } from "../../../svg/trash";
import ConfirmationPopup from "../ConfirmationPopUp";
import UserEditPopup from "./UserEditPopup"; // Importez le composant d'édition des utilisateurs
import { RolesContext } from "./RolesContext";

function UserCard(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState(props.email);
  const [roles, setRoles] = useState(props.roles);
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const allRoles = useContext(RolesContext);

  const toggleEditing = () => {
    if (isEditing) {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const handleEmailChange = (email) => {
    setEmail(email);
  };

  const handleRolesChange = (rolesID) => {
    setRoles([]);

    const userRoles = rolesID.map((roleId) => {
      const matchingRole = allRoles.find((role) => role.id_role === roleId);
      return matchingRole
        ? { id: matchingRole.id_role, name: matchingRole.name_role }
        : null;
    });

    setRoles(userRoles);
  };

  const showDeleteConfirmation = () => {
    setShowConfirmation(true);
  };

  const hideDeleteConfirmation = () => {
    setShowConfirmation(false);
  };

  const DeleteUser = () => {
    showDeleteConfirmation();
  };

  const confirmDelete = async (id) => {
    if (id) {
      setIsDeleteLoading(true);
      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        userId: id,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_DELETE_USER,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 200) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)
          console.log(result);

          setIsCardVisible(false);
          hideDeleteConfirmation();
          setIsDeleteLoading(false);
        } else {
          console.error("Erreur de requête:", response.status);
          setIsDeleteLoading(false);
          alert(
            "Cet utilisateur ne peut pas être supprimé, contacter l'administrateur"
          );
        }
      } catch (error) {
        setIsDeleteLoading(false);
        console.error("Erreur:", error);
      }
    }
  };

  return isCardVisible ? (
    <div className="admin-card user" key={props.idUser} data-id={props.idUser}>
      {isEditing ? (
        <UserEditPopup
          emailUser={email}
          rolesUser={roles}
          idUser={props.idUser}
          onCancelEdit={toggleEditing}
          changeEmail={handleEmailChange}
          changeRoles={handleRolesChange}
        />
      ) : (
        <></>
      )}

      <div className="title">
        <p>{email}</p>

        <div className="roles-list">
          {" "}
          {roles.map((role) => (
            <span className="role" key={role.id}>
              {role.name}
            </span>
          ))}
        </div>
      </div>

      <div className="modify">
        {isEditing ? (
          <></>
        ) : (
          <>
            <button onClick={toggleEditing}>
              <PenSVG />
            </button>
            <button onClick={DeleteUser}>
              <TrashSVG />
            </button>
          </>
        )}
      </div>

      {showConfirmation && (
        <ConfirmationPopup
          message={`Êtes-vous sûr de vouloir supprimer l'utilisateur avec l'adresse e-mail : ${email} ?`}
          onConfirm={() => confirmDelete(props.idUser)}
          onCancel={hideDeleteConfirmation}
          loader={isDeleteLoading}
        />
      )}
    </div>
  ) : null;
}

export default UserCard;
