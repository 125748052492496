import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { usePersonForm } from "./usePersonForm";
import { useWizard } from "react-use-wizard";
import Select from "react-select";
import JobAddPopup from "../../admin/cards/jobs/JobAddPopup";

export function ContractStep() {
  const { previousStep, nextStep } = useWizard();
  const { register, state, setFieldValue } = usePersonForm();
  const { values } = state;

  const [selectedButtons, setSelectedButtons] = useState({
    contract: "",
    package: "",
  });

  useEffect(() => {
    setSelectedButtons({
      contract: Boolean(values.contract),
      package: Boolean(values.package),
      potential_incoming_date: Boolean(values.potential_incoming_date),
      incoming_date: Boolean(values.incoming_date),
      exiting_date: Boolean(values.exiting_date),
      job: Boolean(values.job),
    });
  }, [values]);

  const [selectedJobOption, setSelectedJobOption] = useState(null);
  const [contractOptions, setContractOptions] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //contracts
        const contractsResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "contracts.json?" +
            new Date().getTime()
        );
        const contractsParsed = await contractsResponse.json();
        setContractOptions(contractsParsed.contracts);

        //packages
        const packagesResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "contract_packages.json?" +
            new Date().getTime()
        );
        const packagesParsed = await packagesResponse.json();
        setPackageOptions(packagesParsed.contract_packages);

        //jobs
        const jobsResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "jobs.json?" + new Date().getTime()
        );
        const jobsParsed = await jobsResponse.json();
        setJobOptions(jobsParsed.jobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const addOneJob = (id, fr, en) => {
    setJobOptions((prevJobOptions) => [
      ...prevJobOptions,
      {
        id_job: id,
        name_job_fr: fr,
        name_job_eng: en,
      },
    ]);
  };

  const handleOptionClick = (fieldName, optionId) => {
    setSelectedButtons((prevSelectedButtons) => ({
      ...prevSelectedButtons,
      [fieldName]: optionId,
    }));
    setFieldValue(fieldName, optionId);
  };

  const validateField = (field, value) => {
    setFieldValue(field, value);
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const isContractAndPackageSelected =
      selectedButtons.contract && selectedButtons.package;
    const isJobSelected = values.job;
    const isAtLeastOneDateProvided =
      values.incoming_date || values.potential_incoming_date;

    setIsNextDisabled(
      !(
        isContractAndPackageSelected &&
        isJobSelected &&
        isAtLeastOneDateProvided
      )
    );
  }, [
    selectedButtons,
    values.contract,
    values.package,
    values.job,
    values.incoming_date,
    values.potential_incoming_date,
  ]);

  const [AddJobView, setAddJobView] = useState(false);
  const [AddJob, setAddJob] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  // Ajoutez cette fonction pour mettre à jour l'état du bouton "Suivant"
  const updateNextButtonState = () => {
    const isContractAndPackageSelected =
      Boolean(selectedButtons.contract) && Boolean(selectedButtons.package);
    const isJobSelected = Boolean(values.job);
    const isAtLeastOneDateProvided =
      Boolean(values.incoming_date) || Boolean(values.potential_incoming_date);

    setIsNextDisabled(
      !(
        isContractAndPackageSelected &&
        isJobSelected &&
        isAtLeastOneDateProvided
      )
    );
  };

  const handleDateChange = (field, value) => {
    setFieldValue(field, value); // Met à jour le champ de formulaire avec la nouvelle valeur

    // Détermine si au moins l'un des champs de date a une valeur
    const isAtLeastOneDateProvided =
      field === "potential_incoming_date"
        ? Boolean(value) || Boolean(values.incoming_date) // Si le champ modifié est potential_incoming_date, vérifiez incoming_date
        : Boolean(values.potential_incoming_date) || Boolean(value); // Si le champ modifié est incoming_date, vérifiez potential_incoming_date

    // Autres vérifications pour les autres champs requis
    const isContractAndPackageSelected =
      Boolean(selectedButtons.contract) && Boolean(selectedButtons.package);
    const isJobSelected = Boolean(values.job);

    // Définit l'état de désactivation du bouton Suivant
    setIsButtonDisabled(
      !(
        isContractAndPackageSelected &&
        isJobSelected &&
        isAtLeastOneDateProvided
      )
    );
  };

  return (
    <Container>
      {AddJobView && (
        <JobAddPopup onAddClick={addOneJob} onCancelAdd={setAddJobView} />
      )}

      <h3 className="mt-4">Contrat *</h3>

      <Form.Group as={Row}>
        <Form.Control type="hidden" {...register("contract")} disabled />
        <div className="buttons buttons-container">
          {contractOptions.map(
            (option) =>
              option.visible && (
                <Button
                  key={option.id_contract}
                  className="button-select"
                  variant={
                    selectedButtons.contract === option.id_contract ||
                    state.values.contract === option.id_contract
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() =>
                    handleOptionClick("contract", option.id_contract)
                  }
                >
                  {option.name_contract}
                </Button>
              )
          )}
        </div>
      </Form.Group>

      <h3 className="mt-4">Type de forfait*</h3>
      <Form.Group as={Row}>
        <Form.Control type="hidden" {...register("package")} disabled />
        <div className="buttons buttons-container">
          {packageOptions.map((option) => (
            <Button
              key={option.id_payment}
              className="button-select"
              variant={
                selectedButtons.package === option.id_payment ||
                state.values.package === option.id_payment
                  ? "primary"
                  : "secondary"
              }
              onClick={() => handleOptionClick("package", option.id_payment)}
            >
              {option.name_payment}
            </Button>
          ))}
        </div>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <h3 className="mt-4">Date d'arrivée potentielle</h3>
          <Form.Control
            type="date"
            {...register("potential_incoming_date")}
            onChange={(e) =>
              handleDateChange("potential_incoming_date", e.target.value)
            }
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <h3 className="mt-4">Date d’arrivée</h3>
          <Form.Control
            type="date"
            {...register("incoming_date")}
            onChange={(e) => handleDateChange("incoming_date", e.target.value)}
          />
        </Col>

        <Col>
          <h3 className="mt-4">Date de sortie</h3>
          <Form.Control
            type="date"
            {...register("exiting_date")}
            onChange={(e) => {
              validateField("exiting_date", e.target.value);
            }}
          />
        </Col>
      </Form.Group>

      <h3 className="mt-4">Fonction (FR/EN)*</h3>
      <Form.Group as={Row}>
        <Col>
          <Form.Control type="hidden" {...register("job")} disabled />
          <Select
            value={state.values.job}
            onChange={(selectedOption) => {
              setFieldValue("job", selectedOption);
              setSelectedJobOption(selectedOption);
            }}
            options={jobOptions.map((fonction) => ({
              value: fonction.id_job,
              label:
                fonction.name_job_eng +
                (fonction.name_job_fr ? " / " + fonction.name_job_fr : ""),
            }))}
            placeholder="Sélectionner un emploi"
            isClearable={true}
          />
        </Col>
        <Col style={{ display: "flex" }}>
          <button
            className="add-job"
            type="button"
            onClick={() => setAddJobView(true)}
          >
            + Ajouter une fonction
          </button>
        </Col>
      </Form.Group>

      <div className="buttons mt-4">
        <button
          className="btn btn-primary mt-4"
          style={{ minWidth: "225px" }}
          onClick={nextStep}
          disabled={isNextDisabled}
        >
          Suivant
        </button>
      </div>

      <button className="no-style-btn " onClick={previousStep}>
        {"<"} Page précédente
      </button>
    </Container>
  );
}
