export const LockSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
    >
      <path
        d="M10 1.5C11.0609 1.5 12.0783 1.81607 12.8284 2.37868C13.5786 2.94129 14 3.70435 14 4.5V10.5H6V4.5C6 3.70435 6.42143 2.94129 7.17157 2.37868C7.92172 1.81607 8.93913 1.5 10 1.5ZM16 10.5V4.5C16 3.30653 15.3679 2.16193 14.2426 1.31802C13.1174 0.474106 11.5913 0 10 0C8.4087 0 6.88258 0.474106 5.75736 1.31802C4.63214 2.16193 4 3.30653 4 4.5V10.5C2.93913 10.5 1.92172 10.8161 1.17157 11.3787C0.421427 11.9413 0 12.7044 0 13.5V21C0 21.7956 0.421427 22.5587 1.17157 23.1213C1.92172 23.6839 2.93913 24 4 24H16C17.0609 24 18.0783 23.6839 18.8284 23.1213C19.5786 22.5587 20 21.7956 20 21V13.5C20 12.7044 19.5786 11.9413 18.8284 11.3787C18.0783 10.8161 17.0609 10.5 16 10.5Z"
        fill="black"
      />
    </svg>
  );
};
