import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbComponent from "./Breadcrumb";
import { ListeTableSVG } from "./svg/listeTable";
import { ListeBlocsSVG } from "./svg/listeBlocs";

import ListeEmployeBlocs from "./employe/ListeEmployeBlocs";
import FilterEmploye from "./employe/FilterEmploye";
import ListeEmployeTable from "./employe/ListeEmployeTable";
import Loader from "./Loader";
import { LoaderSVG } from "./svg/loading";

function Collaborateurs() {
  /* blocs ou liste */
  const [view, setView] = useState("liste"); // "liste" est la valeur initiale

  const location = useLocation();

  const [dataEmployees, setDataEmployees] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_DATA_URL +
            "employees.json?" +
            new Date().getTime()
        );
        const parsed = await response.json();
        setDataEmployees(parsed);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");

    if (tabParam) {
      setView(tabParam);
    }
  }, [location.search]);

  const [filterOptions, setFilterOptions] = useState({
    contrat: "",
    poste: "",
    city: "",
    status: "",
    search: "",
  });

  function normalizeString(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }

  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Liste des collaborateurs" },
  ];

  // Check if dataEmployees.employees is available before filtering
  const filteredEmployees =
    dataEmployees && dataEmployees.employees
      ? dataEmployees.employees.filter((employee) => {
          const contratFilter =
            !filterOptions.contrat ||
            employee.contract_type === filterOptions.contrat;

          const posteFilter =
            !filterOptions.poste || employee.job === filterOptions.poste;

          const cityFilter =
            !filterOptions.city || employee.city === filterOptions.city;

          const statusFilter =
            !filterOptions.status || employee.status === filterOptions.status;

          const searchFilter =
            !filterOptions.search ||
            normalizeString(
              employee.first_name + " " + employee.last_name
            ).includes(normalizeString(filterOptions.search));

          return (
            contratFilter &&
            posteFilter &&
            cityFilter &&
            statusFilter &&
            searchFilter
          );
        })
      : [];

  return (
    <div>
      <BreadcrumbComponent links={breadcrumbLinks} />

      <div className="changeview mb-3">
        <button
          className={view === "liste" ? "active" : ""}
          onClick={() => setView("liste")}
        >
          <ListeTableSVG />
        </button>
        <button
          className={view === "blocs" ? "active" : ""}
          onClick={() => setView("blocs")}
        >
          <ListeBlocsSVG />
        </button>
      </div>

      <FilterEmploye
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
      />

      {loading ? (
        <LoaderSVG />
      ) : (
        <div>
          {view === "liste" && (
            <ListeEmployeTable filteredEmployees={filteredEmployees} />
          )}
          {view === "blocs" && (
            <ListeEmployeBlocs filteredEmployees={filteredEmployees} />
          )}
        </div>
      )}
    </div>
  );
}

export default Collaborateurs;
