import React, { useState } from "react";
import { ValidSVG } from "../../../svg/valide";
import { CloseBoldSVG } from "../../../svg/close-bold";

function AddPackage({ onAddPackage }) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleCardClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const handleCancelClick = () => {
    setInputValue(""); // Réinitialiser l'input de texte
    setIsEditing(false); // Désactiver le mode d'édition
  };

  const handleSaveClick = () => {
    if (inputValue) {
      onAddPackage(inputValue);
      setInputValue(""); // Réinitialiser l'input de texte
      setIsEditing(false); // Désactiver le mode d'édition
    }
  };

  return (
    <div className="admin-card add-contract" onClick={handleCardClick}>
      {isEditing ? (
        <>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Intitulé du forfait"
          />

          <button className="validBtn bg-blue" onClick={handleSaveClick}>
            <ValidSVG />
          </button>

          <button className="closeBtn bg-red" onClick={handleCancelClick}>
            <CloseBoldSVG />
          </button>
        </>
      ) : (
        <span className="pointer">+ Ajouter un forfait</span>
      )}
    </div>
  );
}

export default AddPackage;
