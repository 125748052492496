import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "./Breadcrumb";
import { Row, Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import Incoming from "./inout/Incoming";
import Exiting from "./inout/Exiting";
import "../../assets/style/datatables.scss";
import { useLocation } from "react-router-dom";

function InOut() {
  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Liste des collaborateurs", to: "/collaborateurs" },
    { label: "Entrée - Sortie collaborateurs" },
  ];

  const [key, setKey] = useState("incoming");

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");

    if (tabParam) {
      setKey(tabParam);
    }
  }, [location.search]);

  return (
    <div id="page-inout">
      <BreadcrumbComponent links={breadcrumbLinks} />

      <Row>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="incoming" title="Date d'entrée prochaine">
            <Incoming />
          </Tab>
          <Tab eventKey="exiting" title="Date de fin défini">
            <Exiting />
          </Tab>
        </Tabs>
      </Row>
    </div>
  );
}

export default InOut;
