import React, { useState } from "react";
import { CloseSVG } from "../../svg/close";
import { LoaderSVG } from "../../svg/loading";
import PropTypes from "prop-types";

function DeleteEmploye(props) {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [exitDate, setExitDate] = useState("");

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);
  const archiveEmployee = async () => {
    try {
      setLoadingDelete(true);

      const archiveRequestDataRemoveTodos = {
        id_employee: props.idEmployee,
      };

      const requestOptionsRemoveTodos = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(archiveRequestDataRemoveTodos),
      };

      await fetch(
        process.env.REACT_APP_DELETE_ALL_TODO_OF_EMPLOYEE,
        requestOptionsRemoveTodos
      );

      const archiveRequestData = {
        id_employee: props.idEmployee,
        exit_date: exitDate, // Utilisez la valeur de l'état ici
        type: "sortie",
        prive: null,
      };

      console.log(archiveRequestData);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(archiveRequestData),
      };

      const response = await fetch(
        process.env.REACT_APP_CREATE_TODO_LIST,
        requestOptions
      );

      if (response.ok) {
        const result = await response.text();
        console.log("Archive successful:", result);
        setLoadingDelete(false);
        props.deletePopVisibility(false);
        props.onReloadParent();
      } else {
        console.error("Archive request failed:", response.status);
        alert("Error archiving employee. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred during archiving:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span
          className="close-button"
          onClick={() => props.deletePopVisibility(false)}
        >
          <CloseSVG />
        </span>
        <h2>Êtes vous sur de vouloir passer le profil en mode suppression ?</h2>
        <div className="form-group">
          <label htmlFor="exit-date">Date de sortie nécessaire*</label>
          <input
            type="date"
            className="form-control"
            id="exit-date"
            value={exitDate}
            onChange={(e) => setExitDate(e.target.value)}
            required
          />
        </div>
        <div className="buttons mt-5">
          {loadingDelete ? (
            <LoaderSVG />
          ) : (
            <>
              <button
                className="btn btn-primary"
                onClick={archiveEmployee}
                disabled={!exitDate} // Désactive le bouton si aucune date n'est sélectionnée
              >
                Valider
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => props.deletePopVisibility(false)}
              >
                Annuler
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

DeleteEmploye.propTypes = {
  idEmployee: PropTypes.number.isRequired, // Modifié de string à number
  deletePopVisibility: PropTypes.func.isRequired,
  onReloadParent: PropTypes.func.isRequired,
};

export default DeleteEmploye;
