import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function ListeArchivedEmployeTable(props) {
  const { filteredEmployees } = props;
  const navigate = useNavigate();

  const LinkToPage = (slug) => {
    navigate("/archives/" + slug);
  };

  function convertDateFR(dateAuFormatISO) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(dateAuFormatISO);
    return date.toLocaleDateString("fr-FR", options);
  }

  return (
    <div className="collabo-list">
      <Table bordered hover>
        <thead>
          <tr>
            <th>Nom Prénom </th>
            <th>Poste</th>
            <th>Type de Contrat</th>
            <th>Ville</th>
            <th>Date de sortie</th>
          </tr>
        </thead>
        <tbody>
          {filteredEmployees.length === 0 ? (
            <tr>
              <td colSpan="5">Aucun résultat trouvé</td>
            </tr>
          ) : (
            filteredEmployees.map((employee, index) => (
              <tr key={index} onClick={() => LinkToPage(employee.slug)}>
                <td>
                  <strong>
                    <span style={{ textTransform: "uppercase" }}>
                      {employee.last_name}
                    </span>{" "}
                    {employee.first_name}
                  </strong>
                </td>
                <td>{employee.job}</td>
                <td>{employee.contract_type}</td>
                <td>{employee.city}</td>
                <td>{convertDateFR(employee.exit_date)}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default ListeArchivedEmployeTable;
