import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "./Breadcrumb";
import OrganizationChart from "@dabeng/react-orgchart";
import "../../assets/style/organigramme.scss";
import OrganigrammeJson from "../../json/organigramme.json";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LoaderSVG } from "./svg/loading";

function Organigramme() {
  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Organigramme" },
  ];
  const [orgaEmployees, setOrgaEmployees] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_DATA_URL +
            "organigramme.json?" +
            new Date().getTime()
        );
        const parsed = await response.json();
        setOrgaEmployees(parsed);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [chartKey, setChartKey] = useState(0); // Initialize key state

  const reloadOrganigramme = () => {
    // Increment the key to trigger a re-render of the OrganizationChart component
    setChartKey((prevKey) => prevKey + 1);
  };

  const createCustomNode = (data) => {
    return (
      <>
        <div className="oc-heading">
          {data.nodeData.last_name} {data.nodeData.first_name}
        </div>

        {(data.nodeData.job || data.nodeData.slug) && (
          <div className="oc-content">
            {data.nodeData.job && <div> {data.nodeData.job}</div>}
            {data.nodeData.slug && (
              <div>
                <Link to={"/collaborateurs/" + data.nodeData.slug}>
                  <u>voir le détail</u>
                </Link>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  console.log(orgaEmployees);
  console.log(orgaEmployees.organigramme);

  return (
    <div>
      <BreadcrumbComponent links={breadcrumbLinks} />
      <h1>Organigramme</h1>

      {loading ? (
        <LoaderSVG />
      ) : (
        <>
          <Row>
            <div className="">
              <button
                className="btn btn-primary float-end"
                onClick={reloadOrganigramme}
              >
                Recharger l'organigramme
              </button>
            </div>
          </Row>

          <Row>
            <OrganizationChart
              key={chartKey}
              datasource={orgaEmployees.organigramme[0]}
              pan={true}
              draggable={false}
              collapsible={true}
              zoom={false}
              depth={1}
              NodeTemplate={createCustomNode}
            />
          </Row>
        </>
      )}
    </div>
  );
}

export default Organigramme;
