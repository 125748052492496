import React, { Component } from "react";
import BreadcrumbComponent from "../Breadcrumb";
import { LicenceForm } from "./CreateForm/Form";

function CreateLicence() {
  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Liste des licences", to: "/licences" },
    { label: "Ajouter un licence" },
  ];

  return (
    <div>
      <BreadcrumbComponent links={breadcrumbLinks} />
      <h1>Création de licence</h1>

      <LicenceForm />
    </div>
  );
}

export default CreateLicence;
