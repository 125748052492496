import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import "../../../../node_modules/primereact/resources/themes/saga-blue/theme.css";
import "../../../../node_modules/primereact/resources/primereact.min.css";
import "../../../../node_modules/primeicons/primeicons.css";

import { useNavigate } from "react-router-dom";
import { LoaderSVG } from "../svg/loading";

function Incoming() {
  const [employees, setEmployees] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

        const response = await fetch(process.env.REACT_APP_GET_INCOMING, {
          method: "GET",
          hostname: process.env.REACT_APP_HOSTNAME,
          headers: myHeaders,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();

        setEmployees(jsonData.employees);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "long" });

    // Mettre la première lettre du mois en majuscule
    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

    return `${capitalizedMonth} ${year}`;
  }

  function formatDatedmy(dateStr) {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const year = date.getFullYear();

    // Ensure that day and month have leading zeros if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  function groupByMonthAndYear(data) {
    const groupedData = {};
    data.forEach((item) => {
      const formattedDate = formatDate(item.entry_date);
      if (!groupedData[formattedDate]) {
        groupedData[formattedDate] = [];
      }
      groupedData[formattedDate].push(item);
    });
    return groupedData;
  }

  function sortDataByDate(data) {
    data.sort((a, b) => {
      const dateA = new Date(a.entry_date);
      const dateB = new Date(b.entry_date);
      return dateA - dateB;
    });
  }

  function filtrerObjetsParEntryDateNonNulle(tableau) {
    return tableau.filter(function (objet) {
      return objet.entry_date !== null;
    });
  }

  const resultatFiltre = filtrerObjetsParEntryDateNonNulle(employees);

  useEffect(() => {
    sortDataByDate(resultatFiltre);
    const grouped = groupByMonthAndYear(resultatFiltre);
    setGroupedData(grouped);
  }, [employees]);

  const navigate = useNavigate();

  function LinkToPage(rowData) {
    // Vérifiez ici si rowData.active === 1 avant de naviguer
    if (rowData.active === 1) {
      navigate("/collaborateurs/" + rowData.slug);
    } else {
      // Vous pouvez également gérer le cas où rowData.active n'est pas 1, par exemple:
      // Afficher un message, ne rien faire, etc.
      alert("L'employé n'est plus actif.");
    }
  }

  return (
    <div>
      <div className="title-section">
        <h2>Employé.e.s en attente d'arrivé.e </h2>
        <p>(rétro-actif sur 12 mois)</p>
      </div>

      {loading ? (
        <LoaderSVG />
      ) : (
        <>
          {Object.keys(groupedData)
            .reverse()
            .map((key) => (
              <div className="mt-5" key={key}>
                <h3 className="ml-5">
                  {key} ({groupedData[key].length})
                </h3>
                <DataTable
                  value={groupedData[key]}
                  selectionMode="single"
                  onSelectionChange={(e) => LinkToPage(e.value)}
                >
                  <Column
                    field="entry_date"
                    header="Jour d'entrée"
                    body={(rowData) => formatDatedmy(rowData.entry_date)}
                  />

                  <Column
                    header="Nom Prénom "
                    body={(rowData) => (
                      <strong>
                        <span style={{ textTransform: "uppercase" }}>
                          {rowData.last_name}
                        </span>{" "}
                        {rowData.first_name}
                      </strong>
                    )}
                  />
                  <Column
                    field="job_eng"
                    header="Poste"
                    headerClassName="center-title"
                    bodyStyle={{
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  />
                  <Column
                    field="contract_type"
                    header="Type de Contrat"
                    headerClassName="center-title"
                    bodyStyle={{ textAlign: "center" }}
                  />
                  <Column
                    field="city"
                    header="Ville"
                    headerClassName="center-title"
                    bodyStyle={{ textAlign: "center" }}
                  />
                  <Column
                    field="status"
                    header="Statut"
                    headerClassName="center-title"
                    bodyStyle={{ textAlign: "center" }}
                  />
                </DataTable>
              </div>
            ))}
        </>
      )}
    </div>
  );
}

export default Incoming;
