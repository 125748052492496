import React, { useEffect, useState } from "react";
import LoadCard from "./cards/LoadCard";
import TasksList from "./tasks/TasksList";
import { Container, Tab, Tabs } from "react-bootstrap";

function Email() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    try {
      // Chargement des tâches
      const tasksResponse = await fetch(
        process.env.REACT_APP_DATA_URL + "tasks.json?" + new Date().getTime()
      );
      const tasksParsed = await tasksResponse.json();
      setTasks(tasksParsed);

      // Réinitialisez les états de chargement
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors du chargement des données :", error);
      // Gérez l'erreur ici
    }
  };

  const reloadTasks = () => {
    loadData(); // Assurez-vous que cela ne cause pas de rendus infinis
  };

  useEffect(() => {
    // Chargez les données initiales
    loadData();
  }, []);

  const [key, setKey] = useState("notifications");
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleTabSelect = (k) => {
    setKey(k);
    setForceUpdate((prevForceUpdate) => !prevForceUpdate);
  };

  return (
    <div>
      <div className="title-section">
        <h2>Tâches</h2>
        <p>
          Vous pouvez mettre a jour la périodicité des emails suivant les
          thématiques suivantes
        </p>
      </div>

      {loading && <LoadCard />}

      <Container className="mt-5 mailing-page">
        <Tabs id="mailing-page" activeKey={key} onSelect={handleTabSelect}>
          <Tab
            className="p-5"
            key="tab-notif"
            eventKey="notifications"
            title="Notifications"
          >
            <Tabs id="mailing-tasks-page">
              {Object.entries(tasks).map(([category, tasks]) => (
                <Tab
                  className="pt-5"
                  eventKey={category}
                  title={category}
                  key={category}
                >
                  <div key={"tab-" + category} className="mb-5">
                    <h2>{category}</h2>
                    <div className="tasks-container">
                      <TasksList
                        tasks={tasks}
                        category={category}
                        onReload={reloadTasks}
                      />
                    </div>
                  </div>
                </Tab>
              ))}
            </Tabs>
          </Tab>

          <Tab
            eventKey="automatisation"
            key="tab-automatisation"
            title="Automatisation"
            className="p-5"
          >
            <div>
              <h2>Automatisation</h2>
            </div>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default Email;
