import React from "react";
import { CloseSVG } from "../../svg/close";
import { LoaderSVG } from "../../svg/loading";

function ConfirmationPopup({ message, onConfirm, onCancel, loader }) {
  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onCancel}>
          <CloseSVG />
        </span>
        <h2>Êtes vous sûr de vouloir supprimer ?</h2>
        <p>{message}</p>

        <div className="buttons">
          {loader ? (
            <LoaderSVG />
          ) : (
            <>
              <button onClick={onCancel} className="btn btn-secondary">
                Annuler la suppression
              </button>

              <button onClick={onConfirm} className="btn btn-primary">
                Valider la suppression
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPopup;
