export const ArrowLeftSVG = () => {
  return (
    <svg
      fill="#000000"
      height="35px"
      width="35px"
      version="1.1"
      id="Capa_1"
      viewBox="0 0 490 490"
    >
      <g>
        <polygon
          points="242.227,481.919 314.593,407.95 194.882,290.855 490,290.855 490,183.86 210.504,183.86 314.593,82.051 
     242.227,8.081 0,244.996 	"
        />
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </g>
    </svg>
  );
};
