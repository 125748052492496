export const PersonsSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
    >
      <path
        d="M11.6667 20C11.6667 20 10 20 10 18.3333C10 16.6667 11.6667 11.6667 18.3333 11.6667C25 11.6667 26.6667 16.6667 26.6667 18.3333C26.6667 20 25 20 25 20H11.6667ZM18.3333 10C19.6594 10 20.9312 9.47322 21.8689 8.53553C22.8065 7.59785 23.3333 6.32608 23.3333 5C23.3333 3.67392 22.8065 2.40215 21.8689 1.46447C20.9312 0.526784 19.6594 0 18.3333 0C17.0073 0 15.7355 0.526784 14.7978 1.46447C13.8601 2.40215 13.3333 3.67392 13.3333 5C13.3333 6.32608 13.8601 7.59785 14.7978 8.53553C15.7355 9.47322 17.0073 10 18.3333 10Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.69333 20.0001C8.44626 19.4798 8.32304 18.9093 8.33333 18.3334C8.33333 16.0751 9.46667 13.7501 11.56 12.1334C10.5152 11.8115 9.42658 11.654 8.33333 11.6667C1.66667 11.6667 0 16.6667 0 18.3334C0 20.0001 1.66667 20.0001 1.66667 20.0001H8.69333Z"
        fill="black"
      />
      <path
        d="M7.50004 9.99996C8.60511 9.99996 9.66492 9.56097 10.4463 8.77957C11.2277 7.99817 11.6667 6.93836 11.6667 5.83329C11.6667 4.72822 11.2277 3.66842 10.4463 2.88701C9.66492 2.10561 8.60511 1.66663 7.50004 1.66663C6.39497 1.66663 5.33516 2.10561 4.55376 2.88701C3.77236 3.66842 3.33337 4.72822 3.33337 5.83329C3.33337 6.93836 3.77236 7.99817 4.55376 8.77957C5.33516 9.56097 6.39497 9.99996 7.50004 9.99996Z"
        fill="black"
      />
    </svg>
  );
};
