import { useState } from "react";
import { Form } from "react-bootstrap";

export default function OnOffToggleInput(props) {
  const [isChecked, setIsChecked] = useState(props.position);

  const handleToggle = () => {
    props.handleClick(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <Form>
      <div className="d-flex align-items-center">
        <span className="me-2">{isChecked ? "Mode privé" : "Mode public"}</span>
        <Form.Check
          type="switch"
          id="toggle-switch"
          label=""
          checked={isChecked}
          onChange={handleToggle}
        />
      </div>
    </Form>
  );
}
