import React, { useEffect, useState } from "react";
import { CloseSVG } from "../../../svg/close";

import Select from "react-select";

import { LoaderSVG } from "../../../svg/loading";

function ConfirmationRemoveActivityPopUp({
  message,
  onConfirm,
  onCancel,
  activityPlaceObject,
}) {
  const [ActivityPlaceOptions, setActivityPlaceOptions] = useState([]);
  const [selectedActivityPlace, setSelectedActivityPlace] = useState(null);

  const [buttonDisable, setButtonDisable] = useState(true);
  const [IsDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const activityPlacesResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "activity_places.json?" +
            new Date().getTime()
        );
        const activityPlacesParsed = await activityPlacesResponse.json();

        const filteredActivityPlaceOptions =
          activityPlacesParsed.activity_places.filter(
            (activityPlace) =>
              activityPlace.id_activity_place !== activityPlaceObject
          );

        setActivityPlaceOptions(filteredActivityPlaceOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [activityPlaceObject]);

  const deleteActivityPlace = async () => {
    if (selectedActivityPlace) {
      setIsDeleteLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        id_activity: activityPlaceObject,
        new_activity: selectedActivityPlace.value,
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      console.log(requestOptions.body);
      try {
        const response = await fetch(
          process.env.REACT_APP_DELETE_ACTIVITY_PLACE,
          requestOptions
        );

        if (response.status === 200) {
          const result = await response.text();
          console.log(result);

          setIsDeleteLoading(false);
          onConfirm();
        } else {
          console.error("Request error:", response.status);
          setIsDeleteLoading(false);
          alert("Deletion error");
        }
      } catch (error) {
        setIsDeleteLoading(false);
        console.error("Error:", error);
      }
    } else {
      console.warn("No activity place selected.");
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onCancel}>
          <CloseSVG />
        </span>
        <h2>Êtes-vous sûr de vouloir supprimer ?</h2>
        <p>{message}</p>

        {IsDeleteLoading ? (
          <LoaderSVG />
        ) : (
          <>
            <div
              className="input-container"
              style={{ display: "flex", marginBottom: "2rem", gap: "10px" }}
            >
              <div className="activity-place-fr">
                <label>Assigner à l'employé le lieu d'activité:</label>

                <Select
                  options={ActivityPlaceOptions.map((activityPlace) => ({
                    value: activityPlace.id_activity_place,
                    label: activityPlace.name_activity_place
                      ? activityPlace.name_activity_place
                      : "",
                  }))}
                  placeholder="Choisir le lieu d'activité"
                  isClearable={true}
                  onChange={(selectedOption) => {
                    setSelectedActivityPlace(selectedOption);
                    setButtonDisable(!selectedOption);
                  }}
                />
              </div>
            </div>

            <div className="buttons">
              <button onClick={onCancel} className="btn btn-secondary">
                Annuler
              </button>

              <button
                onClick={deleteActivityPlace}
                disabled={buttonDisable}
                className="btn btn-primary"
              >
                Confirmer la suppression
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ConfirmationRemoveActivityPopUp;
