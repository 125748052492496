import React, { useEffect, useState } from "react";

import ContractCard from "./cards/contracts/ContratCard";
import AddContract from "./cards/contracts/AddContrat";

import PackageCard from "./cards/contracts/PackageCard";
import AddPackage from "./cards/contracts/AddPackage";
import LoadCard from "./cards/LoadCard";

function Contracts() {
  const [contracts, setContract] = useState([]);
  const [packages, setpackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPackage, setLoadingPackage] = useState(false);

  // Préparez les en-têtes pour la requête POST
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

  const loadData = async () => {
    try {
      // Chargement des contrats
      const contractsResponse = await fetch(
        process.env.REACT_APP_DATA_URL +
          "contracts.json?" +
          new Date().getTime()
      );
      const contractsParsed = await contractsResponse.json();
      setContract(contractsParsed.contracts);

      // Chargement des forfaits
      const jobsResponse = await fetch(
        process.env.REACT_APP_DATA_URL +
          "contract_packages.json?" +
          new Date().getTime()
      );
      const jobsParsed = await jobsResponse.json();
      setpackages(jobsParsed.contract_packages);

      // Réinitialisez les états de chargement
      setLoading(false);
      setLoadingPackage(false);
    } catch (error) {
      console.error("Erreur lors du chargement des données :", error);
      // Gérez l'erreur ici
    }
  };

  useEffect(() => {
    // Chargez les données initiales
    loadData();
  }, []);

  const handleAddContract = async (name) => {
    setLoading(true);
    // Créez un nouvel contrat en ajoutant le nom au tableau contrats
    if (name) {
      const requestData = {
        name_contract: name,
        visible: true,
      };

      try {
        // Préparez les options pour la requête POST, incluant les headers
        const requestOptions = {
          method: "POST",
          headers: myHeaders, // Incluez les en-têtes ici
          body: JSON.stringify(requestData),
        };

        console.log(requestData);

        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_CREATE_CONTRACT_TYPE,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 201) {
          console.log(name + " created");
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)

          let idContract = JSON.parse(result).contractId;

          const newContract = {
            id_contract: idContract,
            name_contract: name,
          };
          setContract([...contracts, newContract]);
          setLoading(false);
        } else {
          console.error("Erreur de requête:", response.status);
          setLoading(false);
        }
      } catch (error) {
        console.error("Erreur:", error);
        setLoading(false);
      }
    }
  };

  const handleAddPackage = async (name) => {
    setLoadingPackage(true);
    if (name) {
      const requestData = {
        name_payment: name,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_CREATE_PAYMENT,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 201) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)

          let idpayment = JSON.parse(result).paymentId;

          const newPayment = {
            id_payment: idpayment,
            name_payment: name,
          };
          setpackages([...packages, newPayment]);
          setLoadingPackage(false);
        } else {
          console.error("Erreur de requête:", response.status);
          setLoadingPackage(false);
        }
      } catch (error) {
        console.error("Erreur:", error);
      }
    }
  };

  return (
    <div>
      <div className="title-section">
        <h2>Contrats</h2>
        <p>Vous pouvez mettre a jour les différents types de contrats</p>
      </div>

      <div className="p-4 mt-3 bg-gray">
        <h3>Types</h3>
      </div>

      <div className="listing-cards">
        {contracts.map((contract) => (
          <ContractCard
            key={contract.id_contract}
            idContract={contract.id_contract}
            name={contract.name_contract}
          />
        ))}

        {loading && <LoadCard />}

        <AddContract onAddContract={handleAddContract} />
      </div>

      <div className="p-4 mt-5 bg-gray">
        <h3>Forfaits</h3>
      </div>
      <div className="listing-cards">
        {packages.map((Package) => (
          <PackageCard
            key={Package.id_payment}
            idPayment={Package.id_payment}
            name={Package.name_payment}
          />
        ))}

        {loadingPackage && <LoadCard />}
        <AddPackage onAddPackage={handleAddPackage} />
      </div>
    </div>
  );
}

export default Contracts;
