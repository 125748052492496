import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { LoupeSVG } from "../svg/loupe";

function FilterArchivedEmploye({ filterOptions, setFilterOptions }) {
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterOptions({
      ...filterOptions,
      [name]: value,
    });
  };

  const handleResetFilters = () => {
    // Réinitialiser tous les filtres à leur état initial
    setFilterOptions({
      search: "",
    });
  };

  return (
    <div className="filter-component mb-5">
      <br />
      <Row>
        <Col lg={9}>
          <InputGroup>
            <InputGroup.Text id="basic-addon1">
              <LoupeSVG />
            </InputGroup.Text>
            <Form.Control
              size="lg"
              type="text"
              name="search"
              placeholder="Rechercher un ancien collaborateur"
              aria-label="Rechercher"
              aria-describedby="basic-addon1"
              onChange={handleFilterChange}
              value={filterOptions.search}
            />
          </InputGroup>
        </Col>
        <Col lg={3} style={{ display: "flex" }}>
          <Button
            style={{ width: "100%" }}
            size="lg"
            variant="secondary"
            onClick={handleResetFilters}
          >
            Réinitialiser
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default FilterArchivedEmploye;
