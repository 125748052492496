import React, { useContext } from "react";
import { PersonsSVG } from "../../svg/persons";
import { ProfilContext } from "../ProfileContext";
import { Link } from "react-router-dom";

export default function ProfilSubordinates(props) {
  const data = useContext(ProfilContext);

  return (
    <div className="bg-gray p-4 mb-4 team" style={{ minHeight: "auto" }}>
      <h4>Subordonnées :</h4>
      {props.data.map((item, index) => (
        <Link key={index} to={"/collaborateurs/" + item.slug}>
          <span className="upppercase"> {item.last_name}</span>{" "}
          {item.first_name}
        </Link>
      ))}
    </div>
  );
}
