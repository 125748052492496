import React from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useLicenceForm } from "./UseLicenceForm";
import { useWizard } from "react-use-wizard";

export function BasicInfoStep() {
  const { nextStep } = useWizard();
  const { register, state } = useLicenceForm();
  const { touched, errors } = state;

  return (
    <Container>
      <h3 className="mt-4">Nom de la licence* </h3>
      <Form.Group as={Row}>
        <Col>
          <Form.Control type="text" {...register("name")} />
          {touched.name && errors.name && (
            <Form.Text className="text-danger">{errors.name}</Form.Text>
          )}
        </Col>
      </Form.Group>

      <h3 className="mt-4">Date de souscription</h3>
      <Form.Group as={Row}>
        <Col>
          <Form.Control type="date" {...register("subscription_date")} />
          {touched.subscription_date && errors.subscription_date && (
            <Form.Text className="text-danger">
              {errors.subscription_date}
            </Form.Text>
          )}
        </Col>
      </Form.Group>

      <h4 className="mt-4">Nombre de licences* </h4>
      <Form.Group as={Row}>
        <Col>
          <Form.Control type="number" {...register("number")} />
          {touched.number && errors.number && (
            <Form.Text className="text-danger">{errors.number}</Form.Text>
          )}
        </Col>
      </Form.Group>

      <h4 className="mt-4">Utilisation* </h4>
      <Form.Group as={Row}>
        <Col>
          <Form.Control rows={5} as="textarea" {...register("utilisations")} />
          {touched.utilisations && errors.utilisations && (
            <Form.Text className="text-danger">{errors.utilisations}</Form.Text>
          )}
        </Col>
      </Form.Group>

      <div className="buttons mt-4">
        <button
          className="btn btn-primary"
          style={{ minWidth: "225px" }}
          onClick={nextStep}
        >
          Suivant
        </button>
      </div>
    </Container>
  );
}
