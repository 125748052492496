export const HouseSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="23"
      viewBox="0 0 25 23"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5001 3.47599L22.4993 13.4751V20.4862C22.4993 21.1492 22.2359 21.785 21.7671 22.2538C21.2983 22.7226 20.6625 22.986 19.9995 22.986H5.00077C4.33778 22.986 3.70195 22.7226 3.23315 22.2538C2.76435 21.785 2.50098 21.1492 2.50098 20.4862V13.4751L12.5001 3.47599ZM20.8328 2.15443V7.98727L17.4997 4.65422V2.15443C17.4997 1.93344 17.5875 1.72149 17.7438 1.56522C17.9 1.40896 18.112 1.32117 18.333 1.32117H19.9995C20.2205 1.32117 20.4324 1.40896 20.5887 1.56522C20.745 1.72149 20.8328 1.93344 20.8328 2.15443Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3218 0.487936C11.6343 0.175511 12.0581 0 12.5 0C12.9419 0 13.3657 0.175511 13.6782 0.487936L24.7556 11.5637C24.9121 11.7201 25 11.9323 25 12.1536C25 12.3749 24.9121 12.5871 24.7556 12.7436C24.5992 12.9 24.387 12.9879 24.1657 12.9879C23.9444 12.9879 23.7322 12.9 23.5757 12.7436L12.5 1.66617L1.42427 12.7436C1.2678 12.9 1.05559 12.9879 0.834316 12.9879C0.613041 12.9879 0.40083 12.9 0.244365 12.7436C0.0879009 12.5871 0 12.3749 0 12.1536C0 11.9323 0.0879009 11.7201 0.244365 11.5637L11.3218 0.487936Z"
        fill="black"
      />
    </svg>
  );
};
