import React, { useEffect, useState } from "react";
import { CloseSVG } from "../../../svg/close";

import Select from "react-select";

import { LoaderSVG } from "../../../svg/loading";

function ConfirmationRemoveContractPopUp({
  message,
  onConfirm,
  onCancel,
  contractObject,
}) {
  const [ContractOptions, setContractOptions] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null); // State to track the selected contract

  const [buttonDisable, setButtonDisable] = useState(true);
  const [IsDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch contracts
        const contractsResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "contracts.json?" +
            new Date().getTime()
        );
        const contractsParsed = await contractsResponse.json();

        // Filter out the selected contract from the options
        const filteredContractOptions = contractsParsed.contracts.filter(
          (contract) => contract.id_contract !== contractObject
        );

        // Update ContractOptions state
        setContractOptions(filteredContractOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [contractObject]); // Add contractObject as a dependency to trigger the effect when it changes

  const deleteContract = async () => {
    if (selectedContract) {
      setIsDeleteLoading(true);

      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        id_contract: contractObject,
        new_id_contract: selectedContract.value,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_DELETE_CONTRACT_TYPE,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 200) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)
          console.log(result);

          setIsDeleteLoading(false);
          onConfirm();
        } else {
          console.error("Erreur de requête:", response.status);
          setIsDeleteLoading(false);
          alert("Erreur lors de la suppression");
        }
      } catch (error) {
        setIsDeleteLoading(false);
        console.error("Erreur:", error);
      }
    } else {
      console.warn("No contract selected.");
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onCancel}>
          <CloseSVG />
        </span>
        <h2>Êtes-vous sûr de vouloir supprimer ?</h2>
        <p>{message}</p>

        {IsDeleteLoading ? (
          <LoaderSVG />
        ) : (
          <>
            <div
              className="input-container"
              style={{ display: "flex", marginBottom: "2rem", gap: "10px" }}
            >
              <div className="fonction-fr">
                <label>Assigner les employés lié au contrat :</label>

                <Select
                  options={ContractOptions.map((contract) => ({
                    value: contract.id_contract,
                    label: contract.name_contract ? contract.name_contract : "",
                  }))}
                  placeholder="Sélectionnez la fonction"
                  isClearable={true}
                  onChange={(selectedOption) => {
                    setSelectedContract(selectedOption);
                    setButtonDisable(!selectedOption); // Si selectedOption est null (pas d'option sélectionnée), alors setButtonDisable(true), sinon setButtonDisable(false)
                  }}
                />
              </div>

              {/* <button
                className="add-contract"
                onClick={() => setAddContractView(true)}
                
              >
                + Ajouter une fonction
              </button> */}
            </div>

            <div className="buttons">
              <button onClick={onCancel} className="btn btn-secondary">
                Annuler la suppression
              </button>

              <button
                onClick={deleteContract}
                disabled={buttonDisable}
                className="btn btn-primary"
              >
                Valider la suppression
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ConfirmationRemoveContractPopUp;
