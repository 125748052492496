import React, { useEffect, useState } from "react";
import { CloseSVG } from "../../../svg/close";

import Select from "react-select";

import { LoaderSVG } from "../../../svg/loading";

function ConfirmationRemovePaymentPopUp({
  message,
  onConfirm,
  onCancel,
  paymentObject,
}) {
  const [PaymentOptions, setPaymentOptions] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const [buttonDisable, setButtonDisable] = useState(true);
  const [IsDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch payments
        const paymentsResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "contract_packages.json?" +
            new Date().getTime()
        );
        const paymentsParsed = await paymentsResponse.json();

        // Filter out the selected payment from the options
        const filteredPaymentOptions = paymentsParsed.contract_packages.filter(
          (payment) => payment.id_payment !== paymentObject
        );

        // Update PaymentOptions state
        setPaymentOptions(filteredPaymentOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [paymentObject]); // Add paymentObject as a dependency to trigger the effect when it changes

  const deletePayment = async () => {
    if (selectedPayment) {
      setIsDeleteLoading(true);

      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        id_payment: paymentObject,
        new_payment_type: selectedPayment.value,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_DELETE_PAYMENT,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 200) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)
          console.log(result);

          setIsDeleteLoading(false);
          onConfirm();
        } else {
          console.error("Erreur de requête:", response.status);
          setIsDeleteLoading(false);
          alert("Erreur lors de la suppression");
        }
      } catch (error) {
        setIsDeleteLoading(false);
        console.error("Erreur:", error);
      }
    } else {
      console.warn("No payment selected.");
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onCancel}>
          <CloseSVG />
        </span>
        <h2>Êtes-vous sûr de vouloir supprimer ?</h2>
        <p>{message}</p>

        {IsDeleteLoading ? (
          <LoaderSVG />
        ) : (
          <>
            <div
              className="input-container"
              style={{ display: "flex", marginBottom: "2rem", gap: "10px" }}
            >
              <div className="fonction-fr">
                <label>Assigner les employés lié au forfait :</label>

                <Select
                  options={PaymentOptions.map((payment) => ({
                    value: payment.id_payment,
                    label: payment.name_payment ? payment.name_payment : "",
                  }))}
                  placeholder="Sélectionnez le forfait"
                  isClearable={true}
                  onChange={(selectedOption) => {
                    setSelectedPayment(selectedOption);
                    setButtonDisable(!selectedOption); // Si selectedOption est null (pas d'option sélectionnée), alors setButtonDisable(true), sinon setButtonDisable(false)
                  }}
                />
              </div>
            </div>

            <div className="buttons">
              <button onClick={onCancel} className="btn btn-secondary">
                Annuler la suppression
              </button>

              <button
                onClick={deletePayment}
                disabled={buttonDisable}
                className="btn btn-primary"
              >
                Valider la suppression
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ConfirmationRemovePaymentPopUp;
