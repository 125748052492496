import React from "react";
import BreadcrumbComponent from "./Breadcrumb";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import LicencesTable from "./Licences/LicencesTable";

function Licenses() {
  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Licenses" },
  ];

  return (
    <div>
      <BreadcrumbComponent links={breadcrumbLinks} />
      <h2>Licenses</h2>

      <LicencesTable />
    </div>
  );
}

export default Licenses;
