export const ValidSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
    >
      <path
        d="M19.676 0.691747C19.8082 0.556832 19.966 0.449652 20.1402 0.376482C20.3144 0.303313 20.5014 0.265625 20.6903 0.265625C20.8792 0.265625 21.0662 0.303313 21.2404 0.376482C21.4145 0.449652 21.5723 0.556832 21.7046 0.691747C22.2587 1.25168 22.2664 2.1565 21.7239 2.72612L10.2675 16.2692C10.1374 16.4121 9.97958 16.5269 9.80361 16.6066C9.62765 16.6863 9.43727 16.7293 9.24412 16.733C9.05097 16.7366 8.85911 16.7008 8.68027 16.6277C8.50144 16.5546 8.3394 16.4458 8.20406 16.308L1.23293 9.24387C0.964077 8.96969 0.813477 8.601 0.813477 8.217C0.813477 7.83299 0.964077 7.46431 1.23293 7.19012C1.36516 7.05521 1.52297 6.94803 1.69713 6.87486C1.87129 6.80169 2.0583 6.764 2.24721 6.764C2.43612 6.764 2.62313 6.80169 2.79729 6.87486C2.97145 6.94803 3.12927 7.05521 3.26149 7.19012L9.17474 13.1828L19.6372 0.734372C19.6493 0.719399 19.6622 0.705164 19.676 0.691747Z"
        fill="black"
      />
    </svg>
  );
};
