import React, { useEffect, useState } from "react";

import TeamCard from "./cards/teams/TeamCard";
import AddTeam from "./cards/teams/AddTeam";
import { async } from "@dabeng/react-orgchart";
import LoadCard from "./cards/LoadCard";

function Teams() {
  const [teams, setTeams] = useState([]);
  const [loadingTeam, setLoadingTeam] = useState(false);

  const loadData = async () => {
    try {
      // Chargement des contrats
      const teamsResponse = await fetch(
        process.env.REACT_APP_DATA_URL + "teams.json?" + new Date().getTime()
      );

      const teamsParsed = await teamsResponse.json();
      setTeams(teamsParsed.teams);

      // Réinitialisez les états de chargement

      setLoadingTeam(false);
    } catch (error) {
      console.error("Erreur lors du chargement des données :", error);
      // Gérez l'erreur ici
    }
  };

  useEffect(() => {
    // Chargez les données initiales
    loadData();
  }, []);

  const handleAddTeam = async (name) => {
    setLoadingTeam(true);
    if (name) {
      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        name_team: name,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_CREATE_TEAM,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 201) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)

          let idTeam = JSON.parse(result).teamId;

          const newTeam = {
            id_team: idTeam,
            name_team: name,
          };

          setTeams([...teams, newTeam]);
          setLoadingTeam(false);
        } else {
          console.error("Erreur de requête:", response.status);
          setLoadingTeam(false);
        }
      } catch (error) {
        console.error("Erreur:", error);
      }
    }
  };

  return (
    <div>
      <div className="title-section">
        <h2>Teams</h2>
        <p>Vous pouvez mettre à jour les différentes teams.</p>
      </div>

      <div className="listing-cards">
        {teams.map((team) => (
          <TeamCard
            key={team.id_team}
            idTeam={team.id_team}
            name={team.name_team}
          />
        ))}

        {loadingTeam && <LoadCard />}

        <AddTeam onAddTeam={handleAddTeam} />
      </div>
    </div>
  );
}

export default Teams;
