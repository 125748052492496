import React, { useContext, useState } from "react";
import UserAddPopup from "./UserAddPopup.js"; // Importez UserAddPopup depuis son emplacement réel

function AddUser({ onAddUser }) {
  const [isEditing, setIsEditing] = useState(false);

  const handleCardClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  /*   const handleSaveClick = () => {
    if (email && selectedRoles.length > 0) {
      onAddUser(email, selectedRoles);
      setEmail(""); // Réinitialiser l'e-mail
      setSelectedRoles([]); // Réinitialiser les rôles sélectionnés
      setIsEditing(false); // Désactiver le mode d'édition
    }
  }; */

  return (
    <div className="admin-card add-user" onClick={handleCardClick}>
      {isEditing ? (
        <UserAddPopup
          onAddClick={onAddUser}
          onCancelAdd={() => setIsEditing(false)}
        />
      ) : (
        ""
      )}
      <span className="pointer">+ Ajouter un utilisateur</span>
    </div>
  );
}

export default AddUser;
