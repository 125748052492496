export const PenSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_80_950)">
        <path
          d="M20.0843 0.228007C19.9378 0.0815452 19.7391 -0.000732422 19.532 -0.000732422C19.3248 -0.000732422 19.1261 0.0815452 18.9796 0.228007L16.4062 2.80144L22.1984 8.59363L24.7718 6.02176C24.8446 5.94919 24.9023 5.86297 24.9417 5.76806C24.9811 5.67315 25.0013 5.57139 25.0013 5.46863C25.0013 5.36587 24.9811 5.26412 24.9417 5.1692C24.9023 5.07429 24.8446 4.98808 24.7718 4.91551L20.0843 0.228007ZM21.0937 9.69832L15.3015 3.90613L5.14525 14.0624H5.46869C5.67589 14.0624 5.87461 14.1447 6.02112 14.2912C6.16763 14.4377 6.24994 14.6364 6.24994 14.8436V15.6249H7.03119C7.23839 15.6249 7.43711 15.7072 7.58362 15.8537C7.73013 16.0002 7.81244 16.1989 7.81244 16.4061V17.1874H8.59369C8.80089 17.1874 8.99961 17.2697 9.14612 17.4162C9.29263 17.5627 9.37494 17.7614 9.37494 17.9686V18.7499H10.1562C10.3634 18.7499 10.5621 18.8322 10.7086 18.9787C10.8551 19.1252 10.9374 19.3239 10.9374 19.5311V19.8546L21.0937 9.69832ZM9.42494 21.3671C9.39206 21.2796 9.37513 21.187 9.37494 21.0936V20.3124H8.59369C8.38649 20.3124 8.18778 20.2301 8.04126 20.0836C7.89475 19.937 7.81244 19.7383 7.81244 19.5311V18.7499H7.03119C6.82399 18.7499 6.62528 18.6676 6.47876 18.5211C6.33225 18.3745 6.24994 18.1758 6.24994 17.9686V17.1874H5.46869C5.26149 17.1874 5.06278 17.1051 4.91626 16.9586C4.76975 16.812 4.68744 16.6133 4.68744 16.4061V15.6249H3.90619C3.81279 15.6247 3.72017 15.6078 3.63275 15.5749L3.35307 15.853C3.27861 15.928 3.22014 16.0173 3.18119 16.1155L0.0561911 23.928C-0.000638037 24.07 -0.0145498 24.2255 0.0161806 24.3753C0.046911 24.5251 0.120932 24.6626 0.229067 24.7708C0.337202 24.8789 0.474696 24.9529 0.624503 24.9836C0.77431 25.0144 0.929841 25.0005 1.07182 24.9436L8.88432 21.8186C8.98254 21.7797 9.07184 21.7212 9.14682 21.6468L9.42494 21.3671Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_80_950">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
