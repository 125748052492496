import React, { createContext, useState, useEffect } from "react";

// Créez le context des rôles
export const RolesContext = createContext();

const RolesProvider = ({ children }) => {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    // Chargez les options de contrats à partir du JSON

    const fetchData = async () => {
      try {
        //Teams
        const rolesResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "roles.json?" + new Date().getTime()
        );
        const rolesParsed = await rolesResponse.json();
        setRoles(rolesParsed.roles);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <RolesContext.Provider value={roles}>{children}</RolesContext.Provider>
  );
};

export default RolesProvider;
