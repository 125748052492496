import React, { useState } from "react";
import JobAddPopup from "./JobAddPopup"; // Importez JobAddPopup depuis son emplacement réel

function AddJob(onAddJob) {
  const [isCreating, setIsCreating] = useState(false);
  /*   const [inputValue, setInputValue] = useState(""); */

  const handleCardClick = () => {
    if (!isCreating) {
      setIsCreating(true);
    }
  };

  return (
    <div className="admin-card add-job" onClick={handleCardClick}>
      {isCreating ? (
        <JobAddPopup
          onAddClick={onAddJob.onAddJob}
          onCancelAdd={setIsCreating}
        />
      ) : (
        ""
      )}
      <span className="pointer">+ Ajouter un job</span>
    </div>
  );
}

export default AddJob;
