import React, { useEffect, useState } from "react";
import { CloseSVG } from "../../../svg/close";

import Select from "react-select";
import JobAddPopup from "./JobAddPopup";
import { LoaderSVG } from "../../../svg/loading";

function ConfirmationRemoveJobPopUp({
  message,
  onConfirm,
  onCancel,
  jobObject,
  addjob,
}) {
  const [fonctionOptions, setFonctionOptions] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null); // State to track the selected job

  const [AddJobView, setAddJobView] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [IsDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //jobs
        const jobsResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "jobs.json?" + new Date().getTime()
        );
        const jobsParsed = await jobsResponse.json();
        setFonctionOptions(jobsParsed.jobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jobsResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "jobs.json?" + new Date().getTime()
        );
        const jobsParsed = await jobsResponse.json();

        const filteredCompanyOptions = jobsParsed.jobs.filter(
          (job) => job.id_job !== jobObject
        );

        setFonctionOptions(filteredCompanyOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [jobObject]);

  const deleteJob = async () => {
    if (selectedJob) {
      setIsDeleteLoading(true);

      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        id_job: jobObject,
        new_job_employee: selectedJob.value,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_DELETE_JOB,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 200) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)
          console.log(result);

          setIsDeleteLoading(false);
          onConfirm();
        } else {
          console.error("Erreur de requête:", response.status);
          setIsDeleteLoading(false);
          alert("Erreur lors de la suppression");
        }
      } catch (error) {
        setIsDeleteLoading(false);
        console.error("Erreur:", error);
      }
    } else {
      console.warn("No job selected.");
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onCancel}>
          <CloseSVG />
        </span>
        <h2>Êtes-vous sûr de vouloir supprimer ?</h2>
        <p>{message}</p>

        {IsDeleteLoading ? (
          <LoaderSVG />
        ) : (
          <>
            <div
              className="input-container"
              style={{ display: "flex", marginBottom: "2rem", gap: "10px" }}
            >
              <div className="fonction-fr">
                <label>Fonction FR/EN :</label>
                <Select
                  menuPlacement="auto"
                  menuPosition="fixed"
                  options={fonctionOptions.map((fonction) => ({
                    value: fonction.id_job,
                    label:
                      fonction.name_job_eng +
                      (fonction.name_job_fr
                        ? " / " + fonction.name_job_fr
                        : ""),
                  }))}
                  placeholder="Sélectionnez la fonction"
                  isClearable={true}
                  onChange={(selectedOption) => {
                    setSelectedJob(selectedOption);
                    setButtonDisable(!selectedOption); // Si selectedOption est null (pas d'option sélectionnée), alors setButtonDisable(true), sinon setButtonDisable(false)
                  }}
                />
              </div>

              <button
                className="add-job"
                type="button"
                onClick={() => setAddJobView(true)}
              >
                + Ajouter une fonction
              </button>
            </div>

            {AddJobView && (
              <JobAddPopup onAddClick={addjob} onCancelAdd={setAddJobView} />
            )}

            <div className="buttons">
              <button onClick={onCancel} className="btn btn-secondary">
                Annuler la suppression
              </button>

              <button
                onClick={deleteJob}
                disabled={buttonDisable}
                className="btn btn-primary"
              >
                Valider la suppression
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ConfirmationRemoveJobPopUp;
