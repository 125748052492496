import { signInWithGoogle } from "../services/firebase.js";

const Login = () => {
  return (
    <div>
      <button
        style={{ borderRadius: "10px" }}
        className="btn btn-primary p-3"
        onClick={signInWithGoogle}
      >
        Se connecter avec un compte Google
      </button>
    </div>
  );
};

export default Login;
