export const SupportSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <g clipPath="url(#clip0_157_192)">
        <path
          d="M7.5 15C9.48912 15 11.3968 14.2098 12.8033 12.8033C14.2098 11.3968 15 9.48912 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 0 5.51088 0 7.5C0 9.48912 0.790176 11.3968 2.1967 12.8033C3.60322 14.2098 5.51088 15 7.5 15ZM13.5281 10.0988C12.8628 11.6365 11.6365 12.8628 10.0988 13.5281L9.05344 10.9144C9.87746 10.5382 10.5382 9.87746 10.9144 9.05344L13.5281 10.0988ZM4.90125 13.5281C3.36352 12.8628 2.13716 11.6365 1.47188 10.0988L4.08562 9.05344C4.46181 9.87746 5.12254 10.5382 5.94656 10.9144L4.90125 13.5281ZM13.5281 4.90125L10.9144 5.94656C10.5382 5.12254 9.87746 4.46181 9.05344 4.08562L10.0988 1.47188C11.6365 2.13716 12.8628 3.36352 13.5281 4.90125ZM5.94656 4.08562C5.12254 4.46181 4.46181 5.12254 4.08562 5.94656L1.47188 4.90125C2.13716 3.36352 3.36352 2.13716 4.90125 1.47188L5.94656 4.08562ZM7.5 10.3125C6.75408 10.3125 6.03871 10.0162 5.51126 9.48874C4.98382 8.96129 4.6875 8.24592 4.6875 7.5C4.6875 6.75408 4.98382 6.03871 5.51126 5.51126C6.03871 4.98382 6.75408 4.6875 7.5 4.6875C8.24592 4.6875 8.96129 4.98382 9.48874 5.51126C10.0162 6.03871 10.3125 6.75408 10.3125 7.5C10.3125 8.24592 10.0162 8.96129 9.48874 9.48874C8.96129 10.0162 8.24592 10.3125 7.5 10.3125Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_157_192">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
