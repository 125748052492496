import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbComponent from "../Breadcrumb";
import licenceJson from "../../../json/detail_licence.json";

import "../../../assets/style/profil.scss";
import DeleteLicence from "./DeleteLicence";
import UpdateLicence from "./UpdateLicence.js";
import { Link } from "react-router-dom";

function Licence() {
  const licenceData = licenceJson.licence;

  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Liste des licences", to: "/licences" },
    { label: licenceData.name },
  ];

  const [showUpdateLicence, setShowUpdateLicence] = useState(false);
  const [showDeleteLicence, setShowDeleteLicence] = useState(false);

  const [roles, setRoles] = useState(licenceData.responsible.team);
  const [users, setusers] = useState(licenceData.infos.users);

  function formatDateToDayMonthYear(originalDate) {
    const dateObject = new Date(originalDate);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  return (
    <div>
      <Container className="profil-page">
        <BreadcrumbComponent links={breadcrumbLinks} />
      </Container>

      <Row className="p-3 pt-4 pb-4 mb-4 bg-gray profileName">
        <Col className="info">
          <h1>
            <span>{licenceData.name}</span>
          </h1>
          <p className="email">
            Date de souscription :{" "}
            {formatDateToDayMonthYear(licenceData.subscription_date)}
          </p>
          <p className="birthday">Nombre de licences : {licenceData.number}</p>
        </Col>
        <Col className="profil-buttons">
          <button
            className="btn btn-primary"
            onClick={() => setShowUpdateLicence(true)}
          >
            Modifier la licence
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteLicence(true)}
          >
            Supprimer la licence
          </button>
        </Col>
      </Row>

      <Row className="p-3 pt-4 pb-4 mb-4 bg-gray profileName">
        <h3>Responsable</h3>
        <div>
          <p className="mt-2">Nom : {licenceData.responsible.name}</p>
          <p className="mt-2">Email : {licenceData.responsible.email}</p>
          <p className="mt-2">Poste : {licenceData.responsible.poste}</p>
        </div>

        <div className="roles-list mt-3">
          <p>{"Team  :"}</p>
          {roles.map((role) => (
            <span className="role" key={role.id}>
              {role.name}
            </span>
          ))}
        </div>

        <div className="align-right">
          <Link to={"/collaborateurs/" + licenceData.responsible.slug}>
            Voir le profil {">"}
          </Link>
        </div>
      </Row>

      <Row className="p-3 pt-4 pb-4 mb-4 bg-gray profileName">
        <h3>Informations </h3>

        <div className="roles-list mt-3">
          <p>{"Team  :"}</p>
          {users.map((user) => (
            <span className="role" key={user.id}>
              {user.role}
            </span>
          ))}
        </div>

        <div className="mt-3">
          <p>Utilisations :</p>
          <p className="description  mt-1">{licenceData.infos.utilisations}</p>
        </div>
      </Row>

      {showUpdateLicence && (
        <UpdateLicence updatePopVisibility={setShowUpdateLicence} />
      )}

      {showDeleteLicence && (
        <DeleteLicence
          deletePopVisibility={setShowDeleteLicence}
          licence={licenceData}
        />
      )}
    </div>
  );
}

export default Licence;
