export const LoupeSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        d="M11.5268 9.78959C11.4714 9.73351 11.4567 9.64557 11.5018 9.58092C12.3513 8.36286 12.7278 6.87473 12.5573 5.39615C12.3814 3.87036 11.6359 2.46727 10.47 1.4676C9.30414 0.467925 7.80385 -0.0546141 6.26931 0.00452304C4.73476 0.0636602 3.27912 0.700112 2.19361 1.78655C1.10811 2.87298 0.472787 4.32928 0.414756 5.86409C0.356726 7.39889 0.880264 8.89902 1.88063 10.0643C2.881 11.2297 4.28442 11.9743 5.81012 12.1491C7.33563 12.324 8.8709 11.9164 10.1089 11.0079C10.109 11.0078 10.1089 11.0075 10.1087 11.0075C10.1086 11.0075 10.1085 11.0077 10.1086 11.0078C10.1367 11.0452 10.1666 11.0807 10.2002 11.1153L13.8095 14.7251C13.9853 14.901 14.2237 14.9999 14.4724 15C14.7211 15.0001 14.9596 14.9014 15.1355 14.7256C15.3114 14.5498 15.4103 14.3113 15.4104 14.0625C15.4105 13.8138 15.3118 13.5753 15.136 13.3993L11.5268 9.78959ZM11.6608 6.09264C11.6608 6.76984 11.5275 7.44041 11.2683 8.06606C11.0092 8.69171 10.6294 9.26019 10.1506 9.73904C9.67186 10.2179 9.10346 10.5977 8.4779 10.8569C7.85234 11.116 7.18187 11.2494 6.50476 11.2494C5.82766 11.2494 5.15719 11.116 4.53163 10.8569C3.90606 10.5977 3.33766 10.2179 2.85888 9.73904C2.3801 9.26019 2.0003 8.69171 1.74119 8.06606C1.48207 7.44041 1.34871 6.76984 1.34871 6.09264C1.34871 4.72498 1.89193 3.41333 2.85888 2.44625C3.82583 1.47916 5.13729 0.93586 6.50476 0.93586C7.87223 0.93586 9.1837 1.47916 10.1506 2.44625C11.1176 3.41333 11.6608 4.72498 11.6608 6.09264Z"
        fill="black"
      />
    </svg>
  );
};
