import React, { useContext } from "react";
import { ProfilContext } from "../ProfileContext";

function ProfilComment() {
  const data = useContext(ProfilContext);

  return (
    <div className="bg-gray p-4 mb-4">
      <h3 className="mb-4">Commentaire</h3>
      <div>
        <p>{data.comment}</p>
      </div>
    </div>
  );
}

export default ProfilComment;
