import React from "react";
import { Breadcrumb, Row } from "react-bootstrap";
import { Link } from "react-router-dom"; // Importez Link depuis react-router-dom

const BreadcrumbComponent = ({ links }) => {
  return (
    <Row>
      <Breadcrumb>
        {links.map((link, index) => (
          <Breadcrumb.Item
            key={index}
            linkAs={link.to ? Link : undefined}
            linkProps={link.to ? { to: link.to } : undefined}
            active={!link.to}
          >
            {link.label}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Row>
  );
};

export default BreadcrumbComponent;
