import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function ListeEmployeBlocs(props) {
  const { filteredEmployees } = props;

  const groupedEmployees = {};

  filteredEmployees.forEach((employee) => {
    const last_name = employee.last_name;

    // Add a null check for last_name
    if (last_name) {
      const firstLetter = last_name.charAt(0).toUpperCase();

      if (!groupedEmployees[firstLetter]) {
        groupedEmployees[firstLetter] = [];
      }
      groupedEmployees[firstLetter].push(employee);
    }
  });

  function slugify(str) {
    if (str) {
      return str
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    }
    return ""; // or handle it in a way that makes sense for your application
  }

  const sortedKeys = Object.keys(groupedEmployees).sort();

  return (
    <div>
      {sortedKeys.map((letter) => (
        <Row className="mb-5" key={letter}>
          <Row>
            {" "}
            <h2>{letter}</h2>
          </Row>

          <Row className="row-collabo">
            {groupedEmployees[letter].map((employee, index) => (
              <div className="col-lg-3 col-md-6  collabo-div">
                <Link
                  to={"/collaborateurs/" + employee.slug}
                  className=" collabo-card"
                  key={index}
                >
                  <p className="title-person">
                    {employee.last_name} {employee.first_name}
                  </p>
                  <p>{employee.job}</p>
                  <p>{employee.contract_type}</p>
                  <p className="mt-3">{employee.city}</p>
                  <span
                    className={slugify(employee.status) + " status-collabo"}
                  ></span>
                </Link>
              </div>
            ))}
          </Row>
        </Row>
      ))}
    </div>
  );
}

export default ListeEmployeBlocs;
