import React from "react";
import BreadcrumbComponent from "./Breadcrumb";

function Freecomers() {
  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Freecomers" },
  ];

  return (
    <div>
      <BreadcrumbComponent links={breadcrumbLinks} />
      <h1>Freecomers</h1>
    </div>
  );
}

export default Freecomers;
