import React, { useState } from "react";
import flagFR from "../../../../../assets/img/fr.png";
import flagEN from "../../../../../assets/img/gb.png";
import { CloseSVG } from "../../../svg/close";
import { LoaderSVG } from "../../../svg/loading";

function JobAddPopup({ onCancelAdd, onAddClick }) {
  const [nameFR, setNameFR] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNameFRChange = (e) => {
    setNameFR(e.target.value);
  };

  const handleNameENChange = (e) => {
    setNameEN(e.target.value);
  };

  const addJob = async () => {
    setLoading(true);
    // Créez un nouvel contrat en ajoutant le nom au tableau contrats

    if (nameFR.trim() === "" || nameEN.trim() === "") {
      alert("Veuillez remplir les deux champs de nom.");
      setLoading(false);
      return;
    }

    if (nameFR && nameEN) {
      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        name_job_eng: nameEN,
        name_job_fr: nameFR,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_CREATE_JOB,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 201) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)

          let jobId = JSON.parse(result).jobId;

          onAddClick(jobId, nameEN, nameFR);
          // Vérifiez que les deux champs ne sont pas vides
          setNameFR(""); // Réinitialisez l'input de texte en français
          setNameEN(""); // Réinitialisez l'input de texte en anglais
          onCancelAdd(false);

          setLoading(false);
        } else {
          console.error("Erreur de requête:", response.status);
          setLoading(false);
        }
      } catch (error) {
        console.error("Erreur:", error);
      }
    }
  };

  return (
    <div className="job-add-popup popup">
      <div className="popup-content">
        <span className="close-button" onClick={() => onCancelAdd(false)}>
          <CloseSVG />
        </span>
        <h2>Ajouter un nouveau Jobs</h2>
        <p>
          Merci de préciser le nom en anglais et français. Pour vous aider :
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.deepl.com/translator"
          >
            Deepl
          </a>
        </p>

        <div className="job-lang">
          <label htmlFor="nameEN">
            <img alt="FR" src={flagEN} />
          </label>
          <input
            type="text"
            id="nameEN"
            placeholder="Nom de l'emploi en anglais"
            value={nameEN}
            onChange={handleNameENChange}
          />
        </div>

        <div className="job-lang">
          <label htmlFor="nameFR">
            <img alt="FR" src={flagFR} />
          </label>
          <input
            type="text"
            id="nameFR"
            value={nameFR}
            placeholder="Nom de l'emploi en français"
            onChange={handleNameFRChange}
          />
        </div>

        <div className="buttons mt-3">
          {!loading ? (
            <>
              <button
                className="btn btn-secondary"
                onClick={() => onCancelAdd(false)}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addJob}
              >
                Ajouter le job
              </button>
            </>
          ) : (
            <LoaderSVG />
          )}
        </div>
      </div>
    </div>
  );
}

export default JobAddPopup;
