import React from "react";
import BreadcrumbComponent from "./Breadcrumb";

function Cgu() {
  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Conditions Générales d'utilisations" },
  ];

  return (
    <div>
      <BreadcrumbComponent links={breadcrumbLinks} />
      <h1>Conditions Générales d'utilisations</h1>

      <p>Date de la dernière mise à jour : 27/10/2023</p>

      <h2>1. Accès au Site</h2>
      <p>
        Vous êtes responsable de l'accès au Site, y compris de l'obtention de
        l'équipement et des logiciels nécessaires pour accéder au Site.
      </p>

      <h2>2. Contenu du Site</h2>
      <p>
        Tout le contenu affiché sur le Site est la propriété de "Becoming" ou de
        ses concédants de licence. Ce contenu est protégé par les lois sur le
        droit d'auteur et autres lois sur la propriété intellectuelle. Vous
        acceptez de ne pas copier, distribuer, afficher, modifier, reproduire,
        publier, créer des œuvres dérivées, ou transmettre tout contenu du Site
        sans l'autorisation écrite de "Becoming".
      </p>

      <h2>3. Utilisation Autorisée</h2>
      <p>
        Vous acceptez d'utiliser le Site uniquement à des fins légales et
        conformément à ces CGU. Vous vous engagez à ne pas utiliser le Site de
        manière à porter atteinte aux droits d'autrui, violer des lois
        applicables ou causer des perturbations dans le fonctionnement du Site.
      </p>

      <h2>4. Comptes Utilisateurs</h2>
      <p>
        Certains secteurs du Site peuvent nécessiter la création d'un compte
        utilisateur. Vous êtes responsable de maintenir la confidentialité de
        vos informations de compte et de toutes les activités qui se déroulent
        sous votre compte. Vous acceptez de notifier immédiatement "Becoming" de
        toute utilisation non autorisée de votre compte.
      </p>

      <h2>5. Liens vers des Tiers</h2>
      <p>
        Le Site peut contenir des liens vers des sites web de tiers. "Becoming"
        n'est pas responsable du contenu de ces sites web tiers et n'endosse pas
        leur contenu. L'utilisation de ces liens est à vos propres risques.
      </p>

      <h2>6. Limitation de Responsabilité</h2>
      <p>
        "Becoming" ne garantit pas que le Site sera exempt d'erreurs ou de
        pannes. "Becoming" ne saurait être tenu responsable de tout dommage
        direct, indirect, spécial, consécutif ou exemplaire résultant de
        l'utilisation ou de l'incapacité à utiliser le Site.
      </p>

      <h2>7. Modifications des CGU</h2>
      <p>
        "Becoming" se réserve le droit de modifier ces CGU à tout moment. Les
        modifications seront publiées sur le Site et prendront effet
        immédiatement. Il est de votre responsabilité de consulter régulièrement
        les CGU pour rester informé des changements.
      </p>

      <h2>8. Loi Applicable et Juridiction</h2>
      <p>
        Ces CGU sont régies par les lois de France. Tout litige découlant de
        l'utilisation du Site sera soumis à la juridiction exclusive des
        tribunaux de Lille, France.
      </p>

      <h2>9. Contact</h2>
      <p>
        Pour toute question concernant ces CGU, veuillez nous contacter à
        l'adresse{" "}
        <a
          href="mailto:studiotech@becoming-group.com"
          rel="noreferrer"
          target="_blank"
        >
          {" "}
          studiotech@becoming-group.com
        </a>
        .
      </p>

      <p>
        En utilisant ce Site, vous acceptez ces CGU. Si vous n'acceptez pas ces
        CGU, veuillez ne pas utiliser le Site.
      </p>
    </div>
  );
}

export default Cgu;
