import React from "react";
import "../../../../assets/style/timeline.scss";

function ProfilHistory(props) {
  const { data, formatDate } = props;

  return (
    <div className="mb-4 p-4 bg-gray">
      <h3>Historique</h3>

      <ul className="timeline">
        {data.map((item, index) => (
          <li className="timeline-item " key={index}>
            <div>
              <span className="date ">{formatDate(item.date)}</span>
              <p className="history-desc   ">{item.info}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProfilHistory;
