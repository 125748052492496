import { createForm } from "@use-form/use-form";

export const useLicenceForm = createForm({
  initialValues: {
    name: "",
    subscription_date: "",
    number: 0,
    utilisations: "",
    responsible: {},
    users: [],
  },
});
