import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useLicenceForm } from "./UseLicenceForm";
import { useWizard } from "react-use-wizard";
import Select from "react-select";

import listeUsers from "../../../../json/liste_teams.json";
import listeResponsible from "../../../../json/liste_responsible.json";

export function Users() {
  const { previousStep } = useWizard();
  const { register, setFieldValue, state } = useLicenceForm();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedResponsible, setSelectedResponsible] = useState(null);

  const noMessage = "pas de résultat";

  const handleUserButtonClick = (user) => {
    const isSelected = selectedUsers.some(
      (selectedUser) => selectedUser.id === user.id
    );

    if (isSelected) {
      // If user is already selected, remove from the list
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
      );
    } else {
      // If user is not selected, add to the list
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const [userOptions, setUserOptions] = useState([]);
  const [responsibleOptions, setResponsibleOptions] = useState([]);

  useEffect(() => {
    setUserOptions(listeUsers.teams);
    setResponsibleOptions(listeResponsible.responsible);
  }, []);

  return (
    <Container>
      <h3 className="mt-4">Responsable*</h3>
      <Form.Group as={Row}>
        <Col>
          <Form.Control type="hidden" {...register("responsible")} disabled />
          <Select
            options={responsibleOptions.map((nPlus1) => ({
              value: nPlus1.id,
              label: nPlus1.first_name + " " + nPlus1.last_name,
            }))}
            value={selectedResponsible}
            onChange={(selectedOption) => {
              setFieldValue("responsible", selectedOption);
              setSelectedResponsible(selectedOption);
            }}
            placeholder="Sélectionnez un responsable"
            noOptionsMessage={noMessage}
            isClearable={true}
          />
        </Col>
      </Form.Group>

      <h3 className="mt-4">Utilisateurs*</h3>
      <div className="buttons buttons-container">
        {userOptions.map((option) => (
          <Button
            key={option.id}
            className="button-select"
            variant={
              selectedUsers.some(
                (selectedUser) => selectedUser.id === option.id
              )
                ? "primary"
                : "secondary"
            }
            onClick={() => handleUserButtonClick(option)}
          >
            {option.name}
          </Button>
        ))}
      </div>

      <div className="buttons mt-4">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ minWidth: "225px" }}
        >
          Créer la licence
        </button>
      </div>
      <button className="no-style-btn" onClick={previousStep}>
        {"<"} Page précédente
      </button>
    </Container>
  );
}
