import React, { Component } from "react";
import Login from "../Login";
import { Col, Row } from "react-bootstrap";

import imgLogin from "../../assets/img/home.png";

export class LoginPage extends Component {
  render() {
    return (
      <Row className="vh-100 ">
        <Col className="login-home">
          <div>
            <h1 className="mb-0">BeBook</h1>
            <p className="mt-0">Un outil pour les gouverner tous</p>

            <div className="mt-5">
              <p>
                Welcome back.
                <br /> Log in to your account.
              </p>
            </div>

            <Login />

            <div></div>
          </div>
        </Col>

        <Col>
          <img
            src={imgLogin}
            alt=""
            className="w-100 vh-100 object-fit-cover"
          />
        </Col>
      </Row>
    );
  }
}

export default LoginPage;
