import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "./Breadcrumb";
import { useLocation } from "react-router-dom";
import { LoaderSVG } from "./svg/loading";
import ListeArchivedEmployeTable from "./employe/ListeArchivedEmployeTable";
import FilterArchivedEmploye from "./employe/FilterArchivedEmploye";

function Archived() {
  /* blocs ou liste */
  const [dataEmployees, setDataEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Employés archivés" },
  ];

  const [filterOptions, setFilterOptions] = useState({
    search: "",
  });

  function normalizeString(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }

  const filteredEmployees = dataEmployees
    ? dataEmployees.filter((employee) => {
        const fullName = normalizeString(
          employee.first_name + " " + employee.last_name
        );
        const searchQuery = normalizeString(filterOptions.search);
        return !filterOptions.search || fullName.includes(searchQuery);
      })
    : [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_DATA_URL +
            "archived_employees.json?" +
            new Date().getTime()
        );
        const parsed = await response.json();
        setDataEmployees(parsed);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <BreadcrumbComponent links={breadcrumbLinks} />
      <h1>Employés archivé</h1>

      <FilterArchivedEmploye
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
      />

      {loading ? (
        <LoaderSVG />
      ) : (
        <div>
          <ListeArchivedEmployeTable filteredEmployees={filteredEmployees} />
        </div>
      )}
    </div>
  );
}

export default Archived;
