import { Tab } from "bootstrap";
import React, { useContext, useState } from "react";
import { Container, Row, Tabs } from "react-bootstrap";

import Email from "./admin/Email";

import Jobs from "./admin/Jobs";
import Users from "./admin/Users";
import BreadcrumbComponent from "./Breadcrumb";
import Contracts from "./admin/Contracts";
import Cities from "./admin/Cities";
import Companies from "./admin/Companies";
import Teams from "./admin/Teams";
import { AuthorizedUserContext } from "../AuthorizedUserContext";
import Unauthorized from "./Unauthorized";

function Admin() {
  const rolesUserRolesUser = useContext(AuthorizedUserContext).roles;

  const autoriserAffichage = (rolesUserRolesUser) => {
    return rolesUserRolesUser.some((role) => role.id === 2 || role.id === 3);
  };

  const [key, setKey] = useState("email");
  const [forceUpdate, setForceUpdate] = useState(false);

  const breadcrumbLinks = [{ label: "Accueil", to: "/" }, { label: "Admin" }];

  const handleTabSelect = (k) => {
    setKey(k);
    setForceUpdate((prevForceUpdate) => !prevForceUpdate);
  };

  return (
    <Container id="page-admin">
      {autoriserAffichage(rolesUserRolesUser) ? (
        <>
          <BreadcrumbComponent links={breadcrumbLinks} />

          <Row>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleTabSelect}
            >
              <Tab key={`email-${forceUpdate}`} eventKey="email" title="Emails">
                <Email />
              </Tab>
              <Tab
                key={`contrat-${forceUpdate}`}
                eventKey="contrat"
                title="Contrats"
              >
                <Contracts />
              </Tab>
              <Tab key={`lieux-${forceUpdate}`} eventKey="lieux" title="Lieux">
                <Cities />
              </Tab>

              <Tab
                key={`entreprises-${forceUpdate}`}
                eventKey="entreprises"
                title="Entreprises"
              >
                <Companies />
              </Tab>

              <Tab key={`jobs-${forceUpdate}`} eventKey="jobs" title="Jobs">
                <Jobs />
              </Tab>

              <Tab key={`teams-${forceUpdate}`} eventKey="teams" title="Teams">
                <Teams />
              </Tab>

              <Tab
                key={`utilisateurs-${forceUpdate}`}
                eventKey="utilisateurs"
                title="Utilisateurs"
              >
                <Users />
              </Tab>
            </Tabs>
          </Row>
        </>
      ) : (
        <Unauthorized />
      )}
    </Container>
  );
}

export default Admin;
