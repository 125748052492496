import React from "react";
import BreadcrumbComponent from "./Breadcrumb";

function Privacy() {
  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Politique de confidentialité" },
  ];

  return (
    <div>
      <BreadcrumbComponent links={breadcrumbLinks} />
      <h1>Politique de confidentialité</h1>

      <p>
        La présente Politique de Confidentialité régit la manière dont
        "Becoming" collecte, utilise, maintient et divulgue les informations
        collectées auprès des utilisateurs de notre site web interne
        "bebook.becomers.fr". Cette politique s'applique aux informations que
        nous collectons lorsque vous utilisez notre site web. En utilisant ce
        site, vous acceptez les termes de cette politique de confidentialité.
      </p>

      <h2>1. Collecte d'Informations</h2>
      <p>
        Nous pouvons collecter des informations personnelles d'identification de
        l'utilisateur de différentes manières, notamment, mais sans s'y limiter,
        lorsque les utilisateurs visitent notre site, remplissent un formulaire,
        et en relation avec d'autres activités, services, fonctionnalités ou
        ressources que nous mettons à disposition sur notre site. Les
        utilisateurs peuvent être invités, le cas échéant, à fournir leur nom,
        leur adresse e-mail, et d'autres informations.
      </p>

      <h2>2. Utilisation des Informations Collectées</h2>
      <p>
        Nous pouvons collecter et utiliser les informations personnelles des
        utilisateurs aux fins suivantes :
      </p>
      <ul>
        <li>Pour améliorer le service client</li>
        <li>Pour personnaliser l'expérience de l'utilisateur</li>
        <li>Pour améliorer notre site web</li>
        <li>Pour envoyer des informations périodiques par e-mail</li>
      </ul>

      <h2>3. Divulgation des Informations</h2>
      <p>
        Nous ne vendons, n'échangeons ni ne louons les informations personnelles
        d'identification des utilisateurs à des tiers. Nous pouvons partager des
        informations démographiques agrégées qui ne sont pas liées à des
        informations personnelles d'identification concernant nos utilisateurs
        avec nos partenaires commerciaux, affiliés de confiance et annonceurs à
        des fins décrites ci-dessus.
      </p>

      <h2>4. Sécurité des Informations</h2>
      <p>
        Nous mettons en œuvre des mesures de sécurité appropriées pour protéger
        contre l'accès non autorisé, l'altération, la divulgation ou la
        destruction de vos informations personnelles, de votre nom
        d'utilisateur, de votre mot de passe, de vos informations de transaction
        et de vos données stockées sur notre site.
      </p>

      <h2>5. Modifications de la Politique de Confidentialité</h2>
      <p>
        "Becoming" se réserve le droit de mettre à jour cette politique de
        confidentialité à tout moment. Lorsque nous le ferons, nous réviserons
        la date de mise à jour en haut de cette page. Nous encourageons les
        utilisateurs à consulter fréquemment cette page pour rester informés de
        la manière dont nous aidons à protéger les informations personnelles que
        nous collectons. Vous reconnaissez et acceptez qu'il est de votre
        responsabilité de vérifier périodiquement cette politique de
        confidentialité et de vous familiariser avec les modifications.
      </p>

      <h2>6. Acceptation de ces Termes</h2>
      <p>
        En utilisant ce Site, vous signifiez votre acceptation de cette
        politique. Si vous n'acceptez pas cette politique, veuillez ne pas
        utiliser notre site. Votre utilisation continue du site suite à la
        publication de changements à cette politique sera considérée comme votre
        acceptation de ces changements.
      </p>

      <h2>7. Contact</h2>
      <p>
        Pour toute question concernant cette Politique de Confidentialité,
        veuillez nous contacter à l'adresse{" "}
        <a
          href="mailto:studiotech@becoming-group.com"
          rel="noreferrer"
          target="_blank"
        >
          {" "}
          studiotech@becoming-group.com
        </a>
        .
      </p>

      <p>
        En utilisant ce Site, vous acceptez cette Politique de Confidentialité.
        Si vous n'acceptez pas cette Politique de Confidentialité, veuillez ne
        pas utiliser le Site.
      </p>
    </div>
  );
}

export default Privacy;
