import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Container } from "react-bootstrap";

function SearchEmploye() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchData, setSearchData] = useState([]);

  function normalizeString(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        //search
        const searchResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "search.json?" + new Date().getTime()
        );
        const searchParsed = await searchResponse.json();
        setSearchData(searchParsed.search);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearchTermChange = (event) => {
    const value = event.target.value;

    setSearchTerm(value);

    if (value === "") {
      clearSearchTerm();
    } else {
      const normalizedSearchTerm = normalizeString(value);
      const filteredResults = searchData.filter((item) => {
        const fullName = normalizeString(
          `${item.first_name} ${item.last_name}`
        );
        const reverseFullName = normalizeString(
          `${item.last_name} ${item.first_name}`
        );
        return (
          fullName.includes(normalizedSearchTerm) ||
          reverseFullName.includes(normalizedSearchTerm)
        );
      });
      setSearchResults(filteredResults);
    }
  };

  const clearSearchTerm = () => {
    setSearchTerm("");
    setSearchResults([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedItem((prevItem) =>
        prevItem < searchResults.length - 1 ? prevItem + 1 : prevItem
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedItem((prevItem) => (prevItem > 0 ? prevItem - 1 : prevItem));
    } else if (event.key === "Enter" && selectedItem !== null) {
      // Naviguer vers la page du collaborateur sélectionné
      const selectedEmployee = searchResults[selectedItem];
      if (selectedEmployee) {
        window.location.href = `/collaborateurs/${selectedEmployee.slug}`;
      }
    }
  };

  // Fonction pour mettre en évidence les lettres correspondantes
  const highlightSearchTerm = (text, term) => {
    const normalizedTerm = normalizeString(term);
    const parts = normalizeString(text).split(
      new RegExp(`(${normalizedTerm})`, "gi")
    );

    let currentIndex = 0;
    return (
      <span>
        {parts.map((part, index) => {
          const originalTextPart = text.substring(
            currentIndex,
            currentIndex + part.length
          );
          currentIndex += part.length;
          return (
            <span
              key={index}
              style={
                normalizeString(originalTextPart) === normalizedTerm
                  ? { fontWeight: "bold" }
                  : {}
              }
            >
              {text.substring(currentIndex - part.length, currentIndex)}
            </span>
          );
        })}
      </span>
    );
  };

  return (
    <Container className="search-person mt-3 mb-5">
      <h3>Chercher un.e employé.e</h3>
      <div className="search-input">
        <div className="search-with-elems">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
          >
            <path
              d="M11.2117 9.69676C12.1194 8.45791 12.526 6.92194 12.35 5.39615C12.1741 3.87036 11.4286 2.46727 10.2627 1.4676C9.09687 0.467925 7.59658 -0.0546141 6.06203 0.00452304C4.52748 0.0636602 3.07184 0.700112 1.98634 1.78655C0.900833 2.87298 0.265512 4.32928 0.207481 5.86409C0.14945 7.39889 0.672989 8.89902 1.67336 10.0643C2.67372 11.2297 4.07714 11.9743 5.60284 12.1491C7.12854 12.324 8.66401 11.9163 9.90204 11.0075H9.9011C9.92923 11.045 9.95922 11.0807 9.99297 11.1153L13.6022 14.7251C13.778 14.901 14.0165 14.9999 14.2651 15C14.5138 15.0001 14.7523 14.9014 14.9283 14.7256C15.1042 14.5498 15.203 14.3113 15.2031 14.0625C15.2032 13.8138 15.1045 13.5753 14.9287 13.3993L11.3195 9.78959C11.286 9.75565 11.2499 9.72525 11.2117 9.69676ZM11.4535 6.09264C11.4535 6.76984 11.3202 7.44041 11.0611 8.06606C10.8019 8.69171 10.4222 9.26019 9.94337 9.73904C9.46459 10.2179 8.89619 10.5977 8.27063 10.8569C7.64506 11.116 6.97459 11.2494 6.29749 11.2494C5.62038 11.2494 4.94991 11.116 4.32435 10.8569C3.69879 10.5977 3.13039 10.2179 2.65161 9.73904C2.17282 9.26019 1.79303 8.69171 1.53391 8.06606C1.2748 7.44041 1.14143 6.76984 1.14143 6.09264C1.14143 4.72498 1.68466 3.41333 2.65161 2.44625C3.61855 1.47916 4.93002 0.93586 6.29749 0.93586C7.66496 0.93586 8.97642 1.47916 9.94337 2.44625C10.9103 3.41333 11.4535 4.72498 11.4535 6.09264Z"
              fill="black"
            />
          </svg>

          <input
            type="text"
            placeholder="Rechercher un employé"
            value={searchTerm}
            onChange={handleSearchTermChange}
            onKeyDown={handleKeyDown}
          />
          {searchTerm.length > 0 && (
            <button className="clear-search" onClick={clearSearchTerm}>
              &#10005; {/* Cette icône représente une croix (X) */}
            </button>
          )}
        </div>
      </div>

      <ul>
        {searchResults.map((employee, index) => (
          <li
            key={employee.slug}
            className={index === selectedItem ? "selected-item" : ""}
          >
            <Link to={`/collaborateurs/${employee.slug}`}>
              {highlightSearchTerm(
                `${employee.last_name} ${employee.first_name}`,
                searchTerm
              )}
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  );
}

export default SearchEmploye;
