import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import "../../../assets/style/datatables.scss";

import liste_Responsible from "../../../json/liste_responsible.json";
import liste_Teams from "../../../json/liste_teams.json";
import liste_Users from "../../../json/list_role_users.json";
import liste_Licences from "../../../json/list_licences.json";

function LicencesTable() {
  const [totalNumber, setTotalNumber] = useState(0);

  const [licenses, setLicenses] = useState([]);
  const [listeResponsible, setResponsibleOptions] = useState([]);
  const [listeTeams, setTeamOptions] = useState([]);
  const [listeUsers, setUserOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    responsible: null,
    team: null,
    user: null,
  });
  const [filteredLicences, setFilteredLicences] = useState(licenses);

  const navigate = useNavigate();

  const handleFilterChange = (name, value, label) => {
    const newValue = value ? { value, label } : null;

    setFilterOptions((prevFilterOptions) => ({
      ...prevFilterOptions,
      [name]: newValue,
    }));
  };

  const handleResetFilters = () => {
    setFilterOptions({
      responsible: null,
      team: null,
      user: null,
    });
  };

  const LinkToPage = (info) => {
    navigate("/licences/" + info.slug);
  };

  const noOptionsMessage = () => "Aucun résultat trouvé";
  const applyFilters = () => {
    setFilteredLicences((prevLicences) => {
      const matchingLicences = licenses.filter((license) => {
        const matchResponsible =
          !filterOptions.responsible ||
          license.responsible.id === filterOptions.responsible.value;

        const matchTeam =
          !filterOptions.team || license.team.id === filterOptions.team.value;

        const matchUser =
          !filterOptions.user ||
          license.users.some((user) => {
            const isMatchingUser = user.role === filterOptions.user.label;
            return isMatchingUser;
          });

        return matchResponsible && matchTeam && matchUser;
      });

      if (JSON.stringify(prevLicences) !== JSON.stringify(matchingLicences)) {
        return matchingLicences;
      }

      return prevLicences;
    });
  };

  useEffect(() => {
    setLicenses(liste_Licences.licences);
    setResponsibleOptions(liste_Responsible.responsible);
    setTeamOptions(liste_Teams.teams);
    setUserOptions(liste_Users.app_user_roles);

    handleResetFilters();
  }, []);

  // À l'intérieur de LicenseList
  const calculateTotalNumber = () => {
    const total = filteredLicences.reduce(
      (accumulator, license) => accumulator + license.number,
      0
    );
    setTotalNumber(total);
  };

  useEffect(() => {
    calculateTotalNumber();
  }, [filteredLicences]);

  useEffect(() => {
    applyFilters();
  }, [filterOptions]);

  return (
    <>
      <Row>
        <div className="p-4 mt-3 bg-gray space-beetween">
          <div className="flex align-items gap-10">
            <h4>Nombres de licences :</h4>
            <h2>{totalNumber}</h2>
          </div>

          <div>
            <Link
              to={"/licences/creer-une-licence"}
              className="btn btn-primary"
            >
              Ajouter une licence
            </Link>
          </div>
        </div>
      </Row>

      <Row className="mt-3">
        <Col>
          <Select
            options={listeResponsible.map((responsible) => ({
              value: responsible.id,
              label: responsible.first_name + " " + responsible.last_name,
            }))}
            value={filterOptions.responsible}
            onChange={(selectedOption) =>
              handleFilterChange(
                "responsible",
                selectedOption?.value,
                selectedOption?.label
              )
            }
            placeholder="Responsables"
            noOptionsMessage={noOptionsMessage}
            isClearable={true}
          />
        </Col>

        <Col>
          <Select
            options={listeTeams.map((team) => ({
              value: team.id,
              label: team.name,
            }))}
            value={filterOptions.team}
            onChange={(selectedOption) =>
              handleFilterChange(
                "team",
                selectedOption?.value,
                selectedOption?.label
              )
            }
            placeholder="Teams"
            noOptionsMessage={noOptionsMessage}
            isClearable={true}
          />
        </Col>

        <Col>
          <Select
            options={listeUsers.map((user) => ({
              value: user.id,
              label: user.role,
            }))}
            value={filterOptions.user}
            onChange={(selectedOption) =>
              handleFilterChange(
                "user",
                selectedOption?.value,
                selectedOption?.label
              )
            }
            placeholder="Utilisateurs"
            noOptionsMessage={noOptionsMessage}
            isClearable={true}
          />
        </Col>

        <Col>
          <Button onClick={handleResetFilters}>
            Réinitialiser les filtres
          </Button>
        </Col>
      </Row>
      <Row>
        <div className="p-4 mt-3 bg-gray space-between">
          <Table bordered hover>
            <thead>
              <tr>
                <th>Titre</th>
                <th>Nombre</th>
                <th>Responsable</th>
                <th>Team</th>
                <th>Utilisateurs</th>
              </tr>
            </thead>
            <tbody>
              {filteredLicences.length === 0 ? (
                <tr>
                  <td colSpan="4">Aucun résultat trouvé</td>
                </tr>
              ) : (
                filteredLicences.map((license, index) => (
                  <tr key={index} onClick={() => LinkToPage(license)}>
                    <td>{license.title}</td>
                    <td>{license.number}</td>
                    <td>
                      {license.responsible.first_name +
                        " " +
                        license.responsible.last_name}
                    </td>
                    <td>
                      <div className="roles-list">
                        <div className="role">{license.team.name}</div>
                      </div>
                    </td>
                    <td>
                      <div className="roles-list">
                        {license.users.map((user, userIndex) => (
                          <div className="role" key={userIndex}>
                            {user.role}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </Row>
    </>
  );
}

export default LicencesTable;
