import React, { useState } from "react";
import { ValidSVG } from "../../../svg/valide";
import { CloseBoldSVG } from "../../../svg/close-bold";

function AddTeam({ onAddTeam }) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleCardClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const handleCancelClick = () => {
    setInputValue("");
    setIsEditing(false);
  };

  const handleSaveClick = () => {
    if (inputValue) {
      onAddTeam(inputValue);
      setInputValue("");
      setIsEditing(false);
    }
  };

  return (
    <div className="admin-card add-team" onClick={handleCardClick}>
      {isEditing ? (
        <>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Nom de la team"
          />

          <button className="validBtn bg-blue" onClick={handleSaveClick}>
            <ValidSVG />
          </button>

          <button className="closeBtn bg-red" onClick={handleCancelClick}>
            <CloseBoldSVG />
          </button>
        </>
      ) : (
        <span className="pointer">+ Ajouter une team</span>
      )}
    </div>
  );
}

export default AddTeam;
