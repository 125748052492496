import React from "react";
import { CloseSVG } from "../../svg/close";

function DeleteTaskModal({ task, onClose, onDelete }) {
  if (!task) return null;

  const handleDelete = async () => {
    await onDelete(task.id);
    await onClose();
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onClose}>
          <CloseSVG />
        </span>
        {/*  */}
        <h2>Êtes-vous sûr de vouloir supprimer ?</h2>
        Tâche : <strong>{task.name}</strong>
        <div className="buttons">
          <button type="button" onClick={onClose} className="btn btn-secondary">
            Annuler
          </button>

          <button
            type="button"
            onClick={handleDelete}
            className="btn btn-primary"
          >
            Confirmer la suppression
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteTaskModal;
