import React from "react";
import { CloseSVG } from "../svg/close";

function DeleteLicence({ deletePopVisibility, licence }) {
  return (
    <div className="popup">
      <div className="popup-content">
        <span
          className="close-button"
          onClick={() => deletePopVisibility(false)}
        >
          <CloseSVG />
        </span>
        <h2>Êtes vous sûr de vouloir supprimer ? </h2>
        <p>Vous allez supprimer la licence : {licence.name}</p>

        <div className="buttons mt-5">
          <button
            className="btn btn-primary"
            onClick={() => deletePopVisibility(false)}
          >
            Valider
          </button>

          <button
            className="btn btn-secondary"
            onClick={() => deletePopVisibility(false)}
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteLicence;
