import { usePersonForm } from "./usePersonForm";
import { Wizard } from "react-use-wizard";
import { BasicInfoStep } from "./BasicInfoStep";
import { AddressStep } from "./AddressStep";
import { ContractStep } from "./ContractStep";
import { TeamStep } from "./TeamStep";
import { useNavigate } from "react-router-dom";
import "../../../../assets/style/create-user.scss";
import { useContext, useEffect, useRef, useState } from "react";

import { AuthorizedUserContext } from "../../../AuthorizedUserContext";

export function PersonForm() {
  const [loading, setLoading] = useState(false);
  const form = usePersonForm();
  const navigate = useNavigate();
  const User = useContext(AuthorizedUserContext);
  const [users, setUsers] = useState([]);
  const [wizardKey, setWizardKey] = useState(0);

  const resetButtonRef = useRef(null);

  const loadData = async () => {
    try {
      //users
      const activitiesResponse = await fetch(
        process.env.REACT_APP_DATA_URL + "users.json?" + new Date().getTime()
      );
      const activitiesParsed = await activitiesResponse.json();

      // Filtrer les utilisateurs qui ne sont pas des administrateurs
      const nonAdminUsers = activitiesParsed.app_users.filter(
        (user) => !user.roles.some((role) => role.name === "Admin")
      );

      // Extraire les adresses e-mail des utilisateurs non administrateurs
      const nonAdminEmails = nonAdminUsers.map((user) => ({
        email: user.email,
      }));

      setUsers(nonAdminEmails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // Reset the form when the component mounts
    form.$form.set(form.$form.getInitialState());
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    if (e) {
      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      let dataBithday = e.birthdate === "" ? null : e.birthdate;

      let dataPotential_incoming_date =
        e.potential_incoming_date === "" ? null : e.potential_incoming_date;

      let dataIncoming_date = e.incoming_date === "" ? null : e.incoming_date;

      let dataExiting_date = e.exiting_date === "" ? null : e.exiting_date;

      let dataManager = e.manager.value === "" ? null : e.manager.value;

      console.log(e.manager);

      const requestData = {
        last_name: e.lastName ?? null,
        first_name: e.firstName ?? null,
        email: e.email ?? null,
        personal_email: e.personal_email ?? null,
        phone: e.tel ?? null,
        birth_date: dataBithday,
        attachment_place: e.attachment !== "" ? e.attachment : null,
        activity_place: e.activity !== "" ? e.activity : null,
        company: e.company !== "" ? e.company : null,
        type_contract: e.contract !== "" ? e.contract : null,
        type_payment: e.package !== "" ? e.package : null,
        date_arrival_approx: dataPotential_incoming_date,
        date_arrival_effect: dataIncoming_date,
        date_departure: dataExiting_date,
        job: (e.job && e.job.value) !== "" ? e.job.value : null,
        team: (e.team && e.team.id_team) ?? null,
        superior: dataManager,
        comment: e.comment ?? null,
        validator: User.id,
      };

      console.log(requestData);

      let isHistoryCreated = false;

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_CREATE_EMPLOYEE,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 201) {
          const result = await response.text();
          let dataReponse = JSON.parse(result);

          // Check if history creation is already completed
          if (!isHistoryCreated) {
            isHistoryCreated = true; // Set the flag to true

            setLoading(false);
            navigate("/collaborateurs/" + dataReponse.employeeId.slug);

            /*        
            // Add history
            const historyRequest = {
              employe: dataReponse.employeeId.id,
              type_log: 1,
              comment: "Création de l'employé",
              validator: User.id,
            };

            const historyRequestOptions = {
              method: "POST",
              body: JSON.stringify(historyRequest),
            };

            // Execute history creation only once
            await fetch(
              process.env.REACT_APP_CREATE_HISTORY,
              historyRequestOptions
            ).then((response) => {
              if (!response.ok) {
                throw new Error(
                  `Failed to create history, status: ${response.status}`
                );
              } else {
           
              }
            }); */
          }
        } else {
          console.error("Erreur de requête:", response.status);
          setLoading(false);
          alert("Erreur lors de l'envoi en base de données");
        }
      } catch (error) {
        console.error("Erreur:", error);
        setLoading(false);
      }
    }
  };

  const handleFormReset = (event) => {
    event.preventDefault();
    // Your custom logic when the form is reset
    console.log("Form reset");

    // Reset form state
    form.$form.set(form.$form.getInitialState());

    setWizardKey(wizardKey + 1);
  };

  useEffect(() => {
    // Chargez les données initiales
    loadData();

    // Trigger a click on the reset button
    if (resetButtonRef.current) {
      resetButtonRef.current.click();
    }
  }, []);

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      onReset={handleFormReset}
      className="create-form-collab"
    >
      <Wizard key={wizardKey}>
        {/* Use different keys for each step */}
        <BasicInfoStep key="basicInfo" />
        <AddressStep key="address" />
        <ContractStep key="contract" />
        <TeamStep key="team" loader={loading} />
      </Wizard>
      <button
        style={{
          height: "0px",
          border: 0,
          backgroundColor: "transparent",
          fontSize: 0,
        }}
        type="reset"
        ref={resetButtonRef}
      >
        reset
      </button>
    </form>
  );
}
