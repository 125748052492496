export const InOutSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_176_1670)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.56255 17.9688C1.56255 18.176 1.64486 18.3747 1.79138 18.5212C1.93789 18.6677 2.1366 18.75 2.3438 18.75H20.7704L15.8532 23.6656C15.7065 23.8123 15.6241 24.0113 15.6241 24.2188C15.6241 24.4262 15.7065 24.6252 15.8532 24.7719C15.9999 24.9186 16.1988 25.001 16.4063 25.001C16.6138 25.001 16.8127 24.9186 16.9594 24.7719L23.2094 18.5219C23.2822 18.4493 23.3399 18.3631 23.3793 18.2682C23.4187 18.1733 23.4389 18.0715 23.4389 17.9688C23.4389 17.866 23.4187 17.7642 23.3793 17.6693C23.3399 17.5744 23.2822 17.4882 23.2094 17.4156L16.9594 11.1656C16.8127 11.0189 16.6138 10.9365 16.4063 10.9365C16.1988 10.9365 15.9999 11.0189 15.8532 11.1656C15.7065 11.3123 15.6241 11.5113 15.6241 11.7188C15.6241 11.9262 15.7065 12.1252 15.8532 12.2719L20.7704 17.1875H2.3438C2.1366 17.1875 1.93789 17.2698 1.79138 17.4163C1.64486 17.5628 1.56255 17.7616 1.56255 17.9688ZM23.4376 7.03126C23.4376 7.23846 23.3552 7.43717 23.2087 7.58369C23.0622 7.7302 22.8635 7.81251 22.6563 7.81251H4.22974L9.14693 12.7281C9.21957 12.8008 9.27719 12.887 9.3165 12.9819C9.35581 13.0768 9.37604 13.1785 9.37604 13.2813C9.37604 13.384 9.35581 13.4857 9.3165 13.5806C9.27719 13.6755 9.21957 13.7617 9.14693 13.8344C9.07429 13.907 8.98806 13.9646 8.89315 14.004C8.79825 14.0433 8.69653 14.0635 8.5938 14.0635C8.49108 14.0635 8.38936 14.0433 8.29445 14.004C8.19955 13.9646 8.11332 13.907 8.04068 13.8344L1.79068 7.58439C1.71792 7.51181 1.6602 7.4256 1.62082 7.33069C1.58143 7.23577 1.56116 7.13402 1.56116 7.03126C1.56116 6.9285 1.58143 6.82675 1.62082 6.73183C1.6602 6.63692 1.71792 6.55071 1.79068 6.47814L8.04068 0.228135C8.18738 0.0814374 8.38634 -0.000976562 8.5938 -0.000976562C8.80127 -0.000976563 9.00023 0.0814374 9.14693 0.228135C9.29363 0.374833 9.37604 0.573798 9.37604 0.78126C9.37604 0.988723 9.29363 1.18769 9.14693 1.33439L4.22974 6.25001H22.6563C22.8635 6.25001 23.0622 6.33232 23.2087 6.47883C23.3552 6.62535 23.4376 6.82406 23.4376 7.03126Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_176_1670">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
