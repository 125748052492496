import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { ProfilContext } from "../ProfileContext";
import { AuthorizedUserContext } from "../../../AuthorizedUserContext";

function ProfileName(props) {
  const {
    formatDate,
    updatePopVisibility,
    deletePopVisibility,
    deleteStatus,
    updateStatus,
    archived = false,
  } = props;

  const data = useContext(ProfilContext);

  const rolesUserRolesUser = useContext(AuthorizedUserContext).roles;

  const autoriserAffichageRH = (rolesUserRolesUser) => {
    return rolesUserRolesUser.some((role) => role.id === 2 || role.id === 3);
  };

  return (
    <Row className="p-3 pt-4 pb-4 mb-4 bg-gray profileName">
      <Col className="info">
        <h1>
          <span className="uppercase">{data.last_name}</span>
          <br />
          {data.first_name}
        </h1>

        {data.phone && data.phone !== "nan" && (
          <p className="email">
            Telephone :{" "}
            <a target="_top" href={"tel:" + data.phone}>
              {data.phone}
            </a>
          </p>
        )}

        <p className="email">
          Email pro :{" "}
          <a target="_top" href={"mailto:" + data.email}>
            {data.email}
          </a>
        </p>

        {data.personal_email && (
          <p className="email">
            Email perso :{" "}
            <a target="_top" href={"mailto:" + data.personal_email}>
              {data.personal_email}
            </a>
          </p>
        )}

        {data.date_of_birth && (
          <p className="birthday">
            Date de naissance : {formatDate(data.date_of_birth)}
          </p>
        )}
      </Col>
      {autoriserAffichageRH(rolesUserRolesUser) && !archived && (
        <Col className="profil-buttons">
          {updateStatus &&
            !updateStatus.status &&
            deleteStatus &&
            !deleteStatus.status && (
              <button
                className="btn btn-primary"
                onClick={() => updatePopVisibility(true)}
              >
                Modifier le profil
              </button>
            )}

          {deleteStatus && !deleteStatus.status && (
            <button
              className="btn btn-secondary"
              onClick={() => deletePopVisibility(true)}
            >
              Supprimer le profil
            </button>
          )}
        </Col>
      )}
    </Row>
  );
}

export default ProfileName;
