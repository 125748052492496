import React, { useContext } from "react";
import { ProfilContext } from "../ProfileContext";
import { HouseSVG } from "../../svg/house";
import { ContractSVG } from "../../svg/contract";
import { CardSVG } from "../../svg/card";

function ProfilInfo(props) {
  const data = useContext(ProfilContext);
  const { formatDate } = props;

  return (
    <div className="bg-gray p-4 mb-4">
      <h3 className="mb-4">Profil</h3>

      <div>
        <div className="profil-infos">
          <div className="illustration">
            <HouseSVG />
          </div>
          <div>
            {data.work_location && (
              <div>Lieu d'activité : {data.work_location} </div>
            )}
            {data.attachment_location && (
              <div>Lieu de rattachement : {data.attachment_location}</div>
            )}

            {data.company && <div>Entreprise : {data.company}</div>}
          </div>
        </div>

        {(data.employment_type ||
          data.package ||
          data.exit_date ||
          data.entry_date) && (
          <div className="profil-infos">
            <div className="illustration">
              <ContractSVG />
            </div>

            <div>
              {data.employment_type && (
                <div>Contrat : {data.employment_type}</div>
              )}

              {data.package && <div>Forfait : {data.package}</div>}

              {data.entry_date && (
                <div>Date d'arrivée : {formatDate(data.entry_date)}</div>
              )}

              {data.exit_date && (
                <div>Date de sortie : {formatDate(data.exit_date)}</div>
              )}
            </div>
          </div>
        )}

        {(data.job || data.registration_number) && (
          <div className="profil-infos">
            <div className="illustration">
              <CardSVG />
            </div>
            <div>
              {data.job && data.job.name_fr && (
                <div>Fonction français : {data.job.name_fr}</div>
              )}
              {data.job && data.job.name_en && (
                <div>Fonction anglais : {data.job.name_en}</div>
              )}

              {data.registration_number && (
                <div>Matricule : {data.registration_number}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfilInfo;
