import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LoaderSVG } from "../svg/loading";
import { toast } from "react-toastify";

function ListingInfos() {
  const [dataHome, setDataHome] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Set loading to true when starting to fetch data
        setLoading(true);

        // Fetch data
        const companiesResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "home.json?" + new Date().getTime()
        );
        const companiesParsed = await companiesResponse.json();

        // Update state with the fetched data
        setDataHome(companiesParsed.home_information);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Set loading to false after data is fetched (or if there's an error)
        setLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  return loading ? (
    <LoaderSVG />
  ) : (
    <div className="home-infos">
      {console.log(dataHome)}
      <Row className="infos mb-3">
        <Link to={"/collaborateurs"} className="info">
          <p>Nombre d’employé.e.s</p>
          <span className="number">
            {dataHome.total_number ? dataHome.total_number : 0}
          </span>
        </Link>

        <Link
          to={"/collaborateurs/entree-sortie?tab=incoming"}
          className="info"
        >
          <p>Employé.e.s en attente d’arrivée</p>
          <span className="number">
            {dataHome.incoming_number ? dataHome.incoming_number : 0}
          </span>
        </Link>

        <Link to={"/collaborateurs/entree-sortie?tab=exiting"} className="info">
          <p>Employé.e.s en attente de sortie</p>
          <span className="number">
            {dataHome.exiting_number ? dataHome.exiting_number : 0}
          </span>
        </Link>

        <Link to={"/collaborateurs?status=incoming"} className="info">
          <p>Employé.e.s en cours d'entrée</p>
          <span className="number">
            {dataHome.creating_number ? dataHome.creating_number : 0}
          </span>
        </Link>

        <Link to={"/collaborateurs?status=updating"} className="info">
          <p>Employé.e.s en cours de modification</p>
          <span className="number">
            {dataHome.updating_number ? dataHome.updating_number : 0}
          </span>
        </Link>

        <Link to={"/collaborateurs?status=exiting"} className="info">
          <p>Employé.e.s en cours de sortie</p>
          <span className="number">
            {dataHome.exiting_number_status
              ? dataHome.exiting_number_status
              : 0}
          </span>
        </Link>

        {dataHome.contract_types && (
          <>
            {dataHome.contract_types &&
              dataHome.contract_types.find((type) => type.name === "CDI") && (
                <Link to={"/collaborateurs?contrat=cdi"} className="info">
                  <p>CDI</p>
                  <span className="number">
                    {
                      dataHome.contract_types.find(
                        (type) => type.name === "CDI"
                      ).number
                    }
                  </span>
                </Link>
              )}

            {dataHome.contract_types &&
              dataHome.contract_types.find((type) => type.name === "CDD") && (
                <Link to={"/collaborateurs?contrat=cdd"} className="info">
                  <p>CDD</p>
                  <span className="number">
                    {
                      dataHome.contract_types.find(
                        (type) => type.name === "CDD"
                      ).number
                    }
                  </span>
                </Link>
              )}

            {dataHome.contract_types &&
              dataHome.contract_types.find(
                (type) => type.name === "Intérim"
              ) && (
                <Link to={"/collaborateurs?contrat=interim"} className="info">
                  <p>Intérim</p>
                  <span className="number">
                    {
                      dataHome.contract_types.find(
                        (type) => type.name === "Intérim"
                      ).number
                    }
                  </span>
                </Link>
              )}

            {dataHome.contract_types &&
              dataHome.contract_types.find(
                (type) => type.name === "Alternance"
              ) && (
                <Link
                  to={"/collaborateurs?contrat=alternance"}
                  className="info"
                >
                  <p>Alternance</p>
                  <span className="number">
                    {
                      dataHome.contract_types.find(
                        (type) => type.name === "Alternance"
                      ).number
                    }
                  </span>
                </Link>
              )}

            {dataHome.contract_types &&
              dataHome.contract_types.find((type) => type.name === "Stage") && (
                <Link to={"/collaborateurs?contrat=stage"} className="info">
                  <p>Stage</p>
                  <span className="number">
                    {
                      dataHome.contract_types.find(
                        (type) => type.name === "Stage"
                      ).number
                    }
                  </span>
                </Link>
              )}

            {dataHome.contract_types &&
              dataHome.contract_types.find((type) => type.name === "stage") && (
                <Link to={"/collaborateurs?contrat=stage"} className="info">
                  <p>Stage</p>
                  <span className="number">
                    {
                      dataHome.contract_types.find(
                        (type) => type.name === "stage"
                      ).number
                    }
                  </span>
                </Link>
              )}
          </>
        )}
      </Row>
    </div>
  );
}

export default ListingInfos;
