import React, { useContext } from "react";
import { PersonsSVG } from "../../svg/persons";
import { ProfilContext } from "../ProfileContext";
import { Link } from "react-router-dom";

export default function ProfilTeam() {
  const data = useContext(ProfilContext);

  return (
    <div className="bg-gray p-4 mb-4 team">
      <PersonsSVG />
      <p>
        N+1 :{" "}
        <Link to={"/collaborateurs/" + data.manager.slug}>
          <span className="upppercase"> {data.manager.last_name}</span>{" "}
          {data.manager.first_name}
        </Link>
      </p>
      {data.team && <p>Team :{data.team}</p>}
    </div>
  );
}
