import React, { useEffect, useState } from "react";
import { CloseSVG } from "../svg/close";
import Select from "react-select";

import dataUpdates from "../../../json/update_licence.json";

import liste_Teams from "../../../json/liste_teams.json";
import liste_Responsible from "../../../json/liste_responsible.json";
import liste_Users from "../../../json/list_role_users.json";
import { Button, Form } from "react-bootstrap";

function UpdateLicence({ updatePopVisibility, licence }) {
  useEffect(() => {
    setTeamOptions(liste_Teams.teams);
    setResponsibleOptions(liste_Responsible.responsible);
    setUserOptions(liste_Users.app_user_roles);
  }, []);

  /* remplissage des selects */
  const [teamOptions, setTeamOptions] = useState([]);
  const [responsibleOptions, setResponsibleOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const updateData = dataUpdates.licence[0];

  const [name, setName] = useState(updateData.name);
  const [team, setTeam] = useState({
    value: updateData.team.id,
    label: updateData.team.name,
  });
  const [subscription_date, setSubscriptionDate] = useState(
    updateData.subscription_date
  );
  const [number, setLicenceNumber] = useState(updateData.number);

  const [utilisations, setUtilisations] = useState(
    updateData.infos.utilisations
  );
  const [responsible, setResponsible] = useState({
    value: updateData.responsible.id,
    label: updateData.responsible.name,
  });

  const [users, setUser] = useState(
    updateData.infos.users.map((user) => ({
      id: user.id,
      role: user.role,
    }))
  );

  const noOptionsMessage = () => "Aucun résultat trouvé";

  const handleSubmit = (e) => {
    e.preventDefault();
    // Vous pouvez traiter les données ici
    console.log({
      name,
      team,
      subscription_date,
      number,
      utilisations,
      responsible,
      users,
    });
  };

  const toggleRole = (user) => {
    if (users.some((u) => u.id === user.id)) {
      // If the user is already selected, remove it
      setUser(users.filter((u) => u.id !== user.id));
    } else {
      // If the user is not selected, add it
      setUser([...users, user]);
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span
          className="close-button"
          onClick={() => updatePopVisibility(false)}
        >
          <CloseSVG />
        </span>
        <h2>Update licence </h2>

        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Nom :</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </div>

          <div className="input-container">
            <label>Team :</label>
            <Select
              options={teamOptions.map((team) => ({
                value: team.id,
                label: team.name,
              }))}
              value={team}
              onChange={(selectedOption) => setTeam(selectedOption)}
              placeholder="Sélectionnez une team"
              noOptionsMessage={noOptionsMessage}
              isClearable={true}
            />
          </div>

          <div className="input-container">
            <Form.Group>
              <Form.Label>Date de souscription :</Form.Label>

              <Form.Control
                type="date"
                value={subscription_date}
                onChange={(e) => setSubscriptionDate(e.target.value)}
              />
            </Form.Group>
          </div>

          <div className="input-container">
            <Form.Group>
              <Form.Label>Nombre de licenses en cours :</Form.Label>

              <Form.Control
                type="number"
                value={number}
                onChange={(e) => setLicenceNumber(e.target.value)}
              />
            </Form.Group>
          </div>

          <div className="input-container">
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Utilisation :</Form.Label>
              <Form.Control
                as="textarea"
                value={utilisations}
                onChange={(e) => setUtilisations(e.target.value)}
                rows={3}
              />
            </Form.Group>
          </div>

          <div className="input-container">
            <label>Responsable : </label>
            <Select
              options={responsibleOptions.map((responsible) => ({
                value: responsible.id,
                label: responsible.first_name + " " + responsible.last_name,
              }))}
              value={responsible}
              onChange={(selectedOption) => setResponsible(selectedOption)}
              placeholder="Sélectionnez le responsable"
              isClearable={true}
            />
          </div>

          <div className="input-container mt-3">
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Utilisateurs :</Form.Label>

              <div className="buttons buttons-container user-list small">
                {console.log(users)}
                {console.log(userOptions)}
                {userOptions.map((userOption) => (
                  <Button
                    key={userOption.id}
                    className="button-select "
                    variant={
                      users.some((user) => user.id === userOption.id)
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() => toggleRole(userOption)}
                  >
                    {userOption.role}
                  </Button>
                ))}
              </div>
            </Form.Group>
          </div>

          <div className="buttons mt-5">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Valider
            </button>

            <button
              className="btn btn-secondary"
              onClick={() => updatePopVisibility(false)}
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateLicence;
