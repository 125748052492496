import React, { Component, useContext } from "react";
import BreadcrumbComponent from "../Breadcrumb";
import { PersonForm } from "./CreateForm/Form";
import { AuthorizedUserContext } from "../../AuthorizedUserContext";
import Unauthorized from "../Unauthorized";

function CreateEmploye() {
  const breadcrumbLinks = [
    { label: "Accueil", to: "/" },
    { label: "Liste des collaborateurs", to: "/collaborateurs" },
    { label: "Ajouter un collaborateur" },
  ];

  const rolesUserRolesUser = useContext(AuthorizedUserContext).roles;

  const autoriserAffichageRH = (rolesUserRolesUser) => {
    return rolesUserRolesUser.some((role) => role.id === 2 || role.id === 3);
  };

  return (
    <div>
      {autoriserAffichageRH(rolesUserRolesUser) ? (
        <>
          <BreadcrumbComponent links={breadcrumbLinks} />
          <h1>Création d'un collaborateur</h1>
          <PersonForm />
        </>
      ) : (
        <Unauthorized />
      )}
    </div>
  );
}

export default CreateEmploye;
