import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { LoupeSVG } from "../svg/loupe";
import Select from "react-select";

function FilterEmploye({ filterOptions, setFilterOptions }) {
  const [contratsOptions, setContratsOptions] = useState([]);
  const [postesOptions, setPostesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const location = useLocation();

  // Utilisez un effet pour mettre à jour l'état initial en fonction du paramètre "status"
  useEffect(() => {
    // Extract the "status" parameter from the URL
    const searchParams = new URLSearchParams(location.search);
    const statusParam = searchParams.get("status");

    const contractParam = searchParams.get("contrat");

    if (statusParam === "updating" || contractParam === "Updating") {
      setFilterOptions({
        ...filterOptions,
        status: "En cours de modification",
      });
    }

    if (statusParam === "incoming" || contractParam === "Incoming") {
      setFilterOptions({
        ...filterOptions,
        status: "Non finalisé",
      });
    }

    if (statusParam === "exiting" || contractParam === "Exiting") {
      setFilterOptions({
        ...filterOptions,
        status: "En cours de sortie",
      });
    }

    if (contractParam === "alternance" || contractParam === "Alternance") {
      setFilterOptions({
        ...filterOptions,
        contrat: "Alternance",
      });
    }

    if (contractParam === "stage" || contractParam === "Stage") {
      setFilterOptions({
        ...filterOptions,
        contrat: "Stage",
      });
    }

    if (
      contractParam === "interim" ||
      contractParam === "Interim" ||
      contractParam === "Intérim" ||
      contractParam === "intérim"
    ) {
      setFilterOptions({
        ...filterOptions,
        contrat: "Intérim",
      });
    }

    if (contractParam === "cdd" || contractParam === "CDD") {
      setFilterOptions({
        ...filterOptions,
        contrat: "CDD",
      });
    }

    if (contractParam === "cdi" || contractParam === "CDI") {
      setFilterOptions({
        ...filterOptions,
        contrat: "CDI",
      });
    }
  }, []);

  useEffect(() => {
    // Chargez les options de contrats à partir du JSON

    const fetchData = async () => {
      try {
        //contrats
        const contractsResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "contracts.json?" +
            new Date().getTime()
        );
        const contractsParsed = await contractsResponse.json();
        setContratsOptions(contractsParsed.contracts);

        //jobs
        const jobsResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "jobs.json?" + new Date().getTime()
        );
        const jobsParsed = await jobsResponse.json();
        setPostesOptions(jobsParsed.jobs);

        //villes
        const attachementResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "attachment_places.json?" +
            new Date().getTime()
        );
        const attachemenParsed = await attachementResponse.json();
        setCitiesOptions(attachemenParsed.attachment_places);

        //status
        const statusResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "status.json?" + new Date().getTime()
        );
        const statusParsed = await statusResponse.json();
        setStatusOptions(statusParsed.status);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterOptions({
      ...filterOptions,
      [name]: value,
    });
  };

  const handleResetFilters = () => {
    // Réinitialiser tous les filtres à leur état initial
    setFilterOptions({
      contrat: "",
      poste: "",
      city: "",
      status: "",
      search: "",
    });
  };

  return (
    <div className="filter-component mb-5">
      <Row>
        <Col lg={3}>
          <Select
            className="mb-3"
            size="lg"
            name="contrat"
            value={
              filterOptions.contrat
                ? { value: filterOptions.contrat, label: filterOptions.contrat }
                : null
            }
            options={contratsOptions
              .filter((contrat) => contrat.visible === 1)
              .map((contrat) => ({
                value: contrat.name_contract,
                label: contrat.name_contract,
              }))}
            onChange={(selectedOption) =>
              handleFilterChange({
                target: {
                  name: "contrat",
                  value: selectedOption ? selectedOption.value : "",
                },
              })
            }
            placeholder="Contrat"
            isClearable={true}
          />
        </Col>

        <Col lg={3}>
          <Select
            name="poste"
            className="mb-3"
            value={
              filterOptions.poste
                ? { value: filterOptions.poste, label: filterOptions.poste }
                : null
            }
            options={postesOptions.map((poste) => {
              const value = poste.name_job_eng;
              const labelParts = [poste.name_job_eng, poste.name_job_fr].filter(
                Boolean
              );

              // Conditionally display "name_job_eng / name_job_fr" if both are non-null
              const label =
                labelParts.length === 2
                  ? labelParts.join(" / ").toLowerCase()
                  : (labelParts[0] || labelParts[1]).toLowerCase();

              return {
                value: value,
                label: label,
              };
            })}
            onChange={(selectedOption) => {
              const selectedValue = selectedOption ? selectedOption.value : "";
              handleFilterChange({
                target: { name: "poste", value: selectedValue },
              });
            }}
            placeholder="Poste"
            isClearable={true}
          />
        </Col>

        <Col lg={3}>
          <Select
            className="mb-3"
            size="lg"
            name="city"
            value={
              filterOptions.city
                ? { value: filterOptions.city, label: filterOptions.city }
                : null
            }
            options={citiesOptions.map((city) => ({
              value: city.name_attachment_place,
              label: city.name_attachment_place,
            }))}
            onChange={(selectedOption) =>
              handleFilterChange({
                target: {
                  name: "city",
                  value: selectedOption ? selectedOption.value : "",
                },
              })
            }
            placeholder="Ville"
            isClearable={true}
          />
        </Col>

        <Col lg={3}>
          <Select
            name="poste"
            className="mb-3"
            value={
              filterOptions.status
                ? { value: filterOptions.status, label: filterOptions.status }
                : null
            }
            options={statusOptions.map((status) => ({
              value: status.name_status,
              label: status.name_status,
            }))}
            onChange={(selectedOption) => {
              const selectedValue = selectedOption ? selectedOption.value : "";
              handleFilterChange({
                target: { name: "status", value: selectedValue },
              });
            }}
            placeholder="Statut"
            isClearable={true}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={3}></Col>
        <Col lg={6}>
          <InputGroup>
            <InputGroup.Text id="basic-addon1">
              <LoupeSVG />
            </InputGroup.Text>
            <Form.Control
              size="lg"
              type="text"
              name="search"
              placeholder="Rechercher un collaborateur"
              aria-label="Rechercher"
              aria-describedby="basic-addon1"
              onChange={handleFilterChange}
              value={filterOptions.search}
            />
          </InputGroup>
        </Col>
        <Col lg={3} style={{ display: "flex" }}>
          <Button size="lg" variant="secondary" onClick={handleResetFilters}>
            Réinitialiser
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default FilterEmploye;
