import React, { useContext, useState } from "react";
import { CloseSVG } from "../../../svg/close";
import { RolesContext } from "./RolesContext";
import { Form, Button } from "react-bootstrap"; // Import Button component
import { LoaderSVG } from "../../../svg/loading";

function UserAddPopup({ onCancelAdd, onAddClick }) {
  const allRoles = useContext(RolesContext);

  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const isEmailValid = emailRegex.test(email);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onAddUser = async () => {
    if (isEmailValid) {
      if (email && roles) {
        setLoading(true);

        // Préparez les en-têtes pour la requête POST
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

        const requestData = {
          email: email,
          roles: roles,
        };

        // Préparez les options pour la requête POST
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(requestData),
        };

        try {
          console.log(requestData);
          // Effectuez la requête POST directement depuis l'application React
          const response = await fetch(
            process.env.REACT_APP_CREATE_USER,
            requestOptions
          );

          // Vérifiez si la requête OPTIONS préalable est nécessaire
          if (response.status === 201) {
            const result = await response.text();
            // Gérez la réponse du serveur (si nécessaire)

            let userId = JSON.parse(result).userId;

            onAddClick(userId, email, roles);

            setEmail("");
            setRoles([]);
            onCancelAdd(false);

            setLoading(false);
          } else {
            console.error("Erreur de requête:", response.status);
            setLoading(false);
          }
        } catch (error) {
          console.error("Erreur:", error);
        }
      }
    } else {
      alert(
        "Oups, il semble que votre adresse e-mail ne soit pas valide. Veuillez la vérifier et réessayer."
      );
    }
  };
  const toggleRole = (roleId) => {
    // Check if roleId is not null
    if (roleId !== null) {
      // Check if the role ID is already in the roles array
      const isRoleSelected = roles.includes(roleId);

      if (isRoleSelected) {
        // If the role is already selected, remove it
        setRoles(roles.filter((id) => id !== roleId));
      } else {
        // If the role is not selected, add it
        setRoles([...roles, roleId]);
      }
    }
  };

  const isAddButtonDisabled = !email || roles.length === 0;

  return (
    <div className="user-add-popup popup">
      <div className="popup-content">
        <span className="close-button" onClick={() => onCancelAdd(false)}>
          <CloseSVG />
        </span>
        <h2>Ajouter un nouvel utilisateur</h2>
        <label htmlFor="email">Adresse e-mail</label>

        <Form.Control
          onChange={handleEmailChange}
          value={email}
          type="email"
          id="email"
          placeholder="Adresse e-mail de l'utilisateur"
        />

        <label htmlFor="roles">Rôles</label>

        <div className="buttons buttons-container ">
          {allRoles.map((role) => (
            <Button
              key={role.id_role}
              className="button-select"
              variant={roles.includes(role.id_role) ? "primary" : "secondary"}
              onClick={() => toggleRole(role.id_role)}
            >
              {role.name_role}
            </Button>
          ))}
        </div>

        <div className="buttons mt-5">
          {loading ? (
            <LoaderSVG />
          ) : (
            <>
              <button
                className="btn btn-secondary"
                onClick={() => onCancelAdd(false)}
              >
                Annuler
              </button>
              <button
                className="btn btn-primary"
                onClick={onAddUser}
                disabled={isAddButtonDisabled}
              >
                Ajouter l'utilisateur
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserAddPopup;
