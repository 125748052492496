import React, { useContext, useEffect, useState } from "react";
import UserCard from "./cards/users/UserCard"; // Assurez-vous que le chemin d'importation est correct
import AddUser from "./cards/users/AddUser"; // Assurez-vous que le chemin d'importation est correct

import RolesProvider from "./cards/users/RolesContext";

function Users() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  const loadData = async () => {
    try {
      //users
      const usersResponse = await fetch(
        process.env.REACT_APP_DATA_URL + "users.json?" + new Date().getTime()
      );
      const usersParsed = await usersResponse.json();
      setUsers(usersParsed.app_users);

      //roles
      const rolesResponse = await fetch(
        process.env.REACT_APP_DATA_URL + "roles.json?" + new Date().getTime()
      );
      const rolesParsed = await rolesResponse.json();
      setRoles(rolesParsed.roles);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Chargez les données initiales
    loadData();
  }, []);

  const handleAddUser = (iduser, email, roleIds) => {
    // Assurez-vous que l'adresse e-mail n'est pas vide avant d'ajouter l'utilisateur
    if (typeof email === "string" && email.trim() === "") {
      alert("Veuillez fournir une adresse e-mail.");
      return;
    }

    if (!Array.isArray(roleIds)) {
      alert("Les rôles doivent être fournis sous forme de tableau.");
      return;
    }

    // Remplacez les IDs de rôle par les objets de rôle correspondants
    const userRoles = roleIds.map((roleId) => {
      const matchingRole = roles.find((role) => role.id_role === roleId);
      return matchingRole
        ? { id: matchingRole.id_role, name: matchingRole.name_role }
        : null;
    });

    // Créez un nouvel utilisateur en ajoutant l'adresse e-mail et les rôles au tableau des utilisateurs

    const newUser = {
      id: iduser, // Utilisez un identifiant unique
      email: email,
      roles: userRoles.filter((role) => role !== null), // Filter out null values if any
    };

    setUsers([...users, newUser]);
  };

  return (
    <div>
      <div className="title-section">
        <h2>Utilisateurs</h2>
        <p>Vous pouvez mettre à jour les différents utilisateurs</p>
      </div>

      <RolesProvider>
        <div className="listing-cards users">
          {users.map((user) => (
            <UserCard
              key={user.id}
              idUser={user.id}
              email={user.email}
              roles={user.roles}
            />
          ))}{" "}
          <AddUser onAddUser={handleAddUser} />
        </div>
      </RolesProvider>
    </div>
  );
}

export default Users;
