import React from "react";

function LoadCard() {
  return (
    <div className="admin-card contract">
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
          margin: "auto",
          background: "none",
          display: "block",
          shapeRendering: "auto",
        }}
        height="50px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <path
          fill="none"
          stroke="#a3d0ce"
          strokeWidth={8}
          strokeDasharray="192.4416961669922 64.14723205566406"
          d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
          strokeLinecap="round"
          style={{ transform: "scale(1)", transformOrigin: "50px 50px" }}
        >
          <animate
            attributeName="stroke-dashoffset"
            repeatCount="indefinite"
            dur="1.25s"
            keyTimes="0;1"
            values="0;256.58892822265625"
          />
        </path>
        {/* [ldio] generated by https://loading.io/ */}
      </svg>
    </div>
  );
}

export default LoadCard;
