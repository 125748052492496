import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Toggle3Positions from "./components/Toggle3Positions";
import OnOffToggleInput from "./components/ToggleSwitch";
import { AuthorizedUserContext } from "../../../AuthorizedUserContext";
import { LoaderSVG } from "../../svg/loading";
import { async } from "@dabeng/react-orgchart";
import { useNavigate } from "react-router-dom";

function ProfilSuppress(props) {
  const data = props.data;

  const idEmployee = props.idEmployee;
  const TogglesHR = data.data.RH;
  const TogglesAccounting = data.data.Compta;
  const TogglesIt = data.data.IT;
  const TogglesOther = data.data.Autres;
  const User = useContext(AuthorizedUserContext);
  const rolesUserRolesUser = useContext(AuthorizedUserContext).roles;

  const autoriserAffichageRH = (rolesUserRolesUser) => {
    return rolesUserRolesUser.some((role) => role.id === 2 || role.id === 3);
  };

  const navigate = useNavigate();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

  useEffect(() => {
    // Logic to reload the component when reloadParent changes
    console.log("ProfilSupress component reloaded");
  }, [props.profileData, props.onReloadParent]);

  const onToggleChange = async () => {
    // Vous pouvez passer des arguments à cette fonction si nécessaire, par exemple l'ID du toggle qui a changé
    console.log("Un toggle a changé, mise à jour nécessaire");
    props.onReloadParent(); // Si vous utilisez cette méthode pour informer le parent de recharger les données
  };

  const CancelList = async () => {
    setIsArchivingLoading(true);

    const confirmCancel = window.confirm(
      "Êtes-vous sûr de vouloir supprimer la liste des tâches?"
    );

    if (confirmCancel) {
      const requestData = {
        todo_list_id: data.id_todo_list,
      };

      // Prepare options for the POST request
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Perform the POST request directly from the React application
        const response = await fetch(
          process.env.REACT_APP_CANCEL_TODO_LIST,
          requestOptions
        );

        //update employee status
        const statusRequest = {
          id_employee: idEmployee,
          status: 1,
        };

        const statusRequestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(statusRequest),
        };

        await fetch(
          process.env.REACT_APP_UPDATE_EMPLOYEE_STATUS,
          statusRequestOptions
        ).then((response) => {
          if (!response.ok) {
            throw new Error(
              `Failed to update employee status, status: ${response.status}`
            );
          }
        });

        // Check if the OPTIONS request is necessary
        if (response.status === 200) {
          const result = await response.text();
          // Handle the server response if needed
          console.log(result);

          // Call the parent callback to trigger a reload
          props.onReloadParent();

          setIsArchivingLoading(false);
        } else {
          console.error("Request error:", response.status);
          alert("Error : can't cancel todo list");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setIsArchivingLoading(false);
    }
  };

  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isArchivingLoading, setIsArchivingLoading] = useState(false);

  const setPrivate = async (status) => {
    setIsEditingLoading(true);

    const requestData = {
      id_todo_list: data.id_todo_list,
      private_status: status,
    };

    console.log(requestData);

    // Préparez les options pour la requête POST
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData),
    };

    try {
      // Effectuez la requête POST directement depuis l'application React
      const response = await fetch(
        process.env.REACT_APP_CHANGE_DELETE_PRIVATE_MODE,
        requestOptions
      );

      const statusRequest = {
        id_employee: idEmployee,
        status: 5,
      };

      const statusRequestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(statusRequest),
      };

      await fetch(
        process.env.REACT_APP_UPDATE_EMPLOYEE_STATUS,
        statusRequestOptions
      ).then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to update employee status, status: ${response.status}`
          );
        }
      });

      // Vérifiez si la requête OPTIONS préalable est nécessaire
      if (response.status === 200) {
        const result = await response.text();
        // Gérez la réponse du serveur (si nécessaire)
        console.log(result);
        setIsEditingLoading(false);

        // Call the parent callback to trigger a reload
        props.onReloadParent();
      } else {
        console.error("Erreur de requête:", response.status);
        setIsEditingLoading(false);
      }
    } catch (error) {
      console.error("Erreur:", error);
      setIsEditingLoading(false);
    }
  };

  const archiveEmploye = async () => {
    setIsArchivingLoading(true);

    // Vérifier si tous les toggles sont validés (status !== 0)
    const allToggles = [
      ...TogglesHR,
      ...TogglesAccounting,
      ...TogglesIt,
      ...(TogglesOther ?? []),
    ];
    const areAllTogglesValidated = allToggles.every(
      (toggle) => toggle.status !== 0
    );

    if (!areAllTogglesValidated) {
      alert(
        "Veuillez valider tous les toggles avant de procéder à l'archivage."
      );
      setIsArchivingLoading(false);
      return; // Arrête l'exécution si tous les toggles ne sont pas validés
    }

    const confirmArchive = window.confirm(
      "Êtes-vous sûr de vouloir archiver cet employé ?\n Cette action est irreversible !"
    );

    if (confirmArchive) {
      const requestData = {
        id_employee: idEmployee,
        id_todo_list: data.id_todo_list,
        validator: User.id,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_DELETE_EMPLOYEE,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 200) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)
          console.log(result);

          navigate("/collaborateurs");

          setIsArchivingLoading(false);
        } else {
          console.error("Erreur de requête:", response.status);

          alert("erreur lors de l'archivage");
        }
      } catch (error) {
        console.error("Erreur:", error);
      }
    } else {
      setIsArchivingLoading(false);
      console.log("Annulation de l'archivage");
    }
  };

  return (
    <>
      {data.private === null ? (
        <>
          <div className="bg-gray p-4 mb-4 create-checks checks">
            <h3 className="mb-2">Suppression</h3>
            <div className="private-delete">
              {autoriserAffichageRH(rolesUserRolesUser) && (
                <>
                  <p>
                    Le mode privé n’enverra aucune notification au différent
                    intervenant.
                  </p>

                  {isEditingLoading ? (
                    <LoaderSVG />
                  ) : (
                    <div className="buttons">
                      <button
                        className="btn btn-primary"
                        onClick={() => setPrivate(true)}
                      >
                        Privé
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => setPrivate(false)}
                      >
                        Public
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            {(Boolean(data.private) === false ||
              autoriserAffichageRH(rolesUserRolesUser)) && (
              <>
                <div className="bg-gray p-4 mb-4 create-checks checks">
                  <h3 className="mb-2">Suppression</h3>

                  {autoriserAffichageRH(rolesUserRolesUser) && (
                    <Row>
                      <OnOffToggleInput
                        position={data.private}
                        handleClick={setPrivate}
                      />
                    </Row>
                  )}

                  <Row>
                    <Col size={6}>
                      {TogglesHR && TogglesHR.length > 0 && (
                        <div className="user-checks">
                          <h5>Ressources Humaines</h5>

                          <div className="toggle-list">
                            {TogglesHR.map((toggle, index) => (
                              <Toggle3Positions
                                key={index}
                                role={toggle.role}
                                listId={data.id_todo_list}
                                toggleID={toggle.id}
                                initialValue={toggle.status}
                                leftText={toggle.text}
                                onToggleChange={onToggleChange}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                    </Col>

                    <Col size={6}>
                      {TogglesIt && TogglesIt.length > 0 && (
                        <div className="user-checks">
                          <h5>IT</h5>
                          <div className="toggle-list">
                            {TogglesIt.map((toggle, index) => (
                              <Toggle3Positions
                                key={index}
                                role={toggle.role}
                                listId={data.id_todo_list}
                                toggleID={toggle.id}
                                initialValue={toggle.status}
                                leftText={toggle.text}
                                onToggleChange={onToggleChange}
                              />
                            ))}
                          </div>
                        </div>
                      )}

                      {TogglesAccounting && TogglesAccounting.length > 0 && (
                        <div className="user-checks">
                          <h5>Compta</h5>

                          <div className="toggle-list">
                            {TogglesAccounting.map((toggle, index) => (
                              <Toggle3Positions
                                key={index}
                                role={toggle.role}
                                listId={data.id_todo_list}
                                toggleID={toggle.id}
                                initialValue={toggle.status}
                                leftText={toggle.text}
                                onToggleChange={onToggleChange}
                              />
                            ))}
                          </div>
                        </div>
                      )}

                      {TogglesOther && TogglesOther.length > 0 && (
                        <div className="user-checks">
                          <h5>Autres</h5>
                          <div className="toggle-list">
                            {TogglesOther.map((toggle, index) => (
                              <Toggle3Positions
                                key={index}
                                role={toggle.role}
                                listId={data.id_todo_list}
                                toggleID={toggle.id}
                                initialValue={toggle.status}
                                leftText={toggle.text}
                                onToggleChange={onToggleChange}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  {autoriserAffichageRH(rolesUserRolesUser) && (
                    <Row>
                      <div className="buttons">
                        {isArchivingLoading ? (
                          <LoaderSVG />
                        ) : (
                          <>
                            <button
                              type="button"
                              onClick={CancelList}
                              className="btn btn-secondary  p-15"
                            >
                              Annuler la liste de tâches
                            </button>
                            <button
                              className="btn btn-primary p-15"
                              onClick={archiveEmploye}
                            >
                              Valider la sortie
                            </button>
                          </>
                        )}
                      </div>
                    </Row>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ProfilSuppress;
