export const ListeTableSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
    >
      <g clipPath="url(#clip0_46_925)">
        <rect x="-12" y="-12" width="51" height="51" fill="#D9D9D9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.09375 29.25C6.09375 28.9268 6.22215 28.6168 6.45071 28.3882C6.67927 28.1597 6.98927 28.0312 7.3125 28.0312H31.6875C32.0107 28.0312 32.3207 28.1597 32.5493 28.3882C32.7778 28.6168 32.9062 28.9268 32.9062 29.25C32.9062 29.5732 32.7778 29.8832 32.5493 30.1118C32.3207 30.3403 32.0107 30.4688 31.6875 30.4688H7.3125C6.98927 30.4688 6.67927 30.3403 6.45071 30.1118C6.22215 29.8832 6.09375 29.5732 6.09375 29.25ZM6.09375 19.5C6.09375 19.1768 6.22215 18.8668 6.45071 18.6382C6.67927 18.4097 6.98927 18.2812 7.3125 18.2812H31.6875C32.0107 18.2812 32.3207 18.4097 32.5493 18.6382C32.7778 18.8668 32.9062 19.1768 32.9062 19.5C32.9062 19.8232 32.7778 20.1332 32.5493 20.3618C32.3207 20.5903 32.0107 20.7188 31.6875 20.7188H7.3125C6.98927 20.7188 6.67927 20.5903 6.45071 20.3618C6.22215 20.1332 6.09375 19.8232 6.09375 19.5ZM6.09375 9.75C6.09375 9.42677 6.22215 9.11677 6.45071 8.88821C6.67927 8.65965 6.98927 8.53125 7.3125 8.53125H31.6875C32.0107 8.53125 32.3207 8.65965 32.5493 8.88821C32.7778 9.11677 32.9062 9.42677 32.9062 9.75C32.9062 10.0732 32.7778 10.3832 32.5493 10.6118C32.3207 10.8403 32.0107 10.9688 31.6875 10.9688H7.3125C6.98927 10.9688 6.67927 10.8403 6.45071 10.6118C6.22215 10.3832 6.09375 10.0732 6.09375 9.75Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_925">
          <rect width="39" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
