export const CloseSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.501 5.49897C35.6203 5.61799 35.715 5.75938 35.7796 5.91504C35.8442 6.0707 35.8774 6.23757 35.8774 6.4061C35.8774 6.57463 35.8442 6.7415 35.7796 6.89716C35.715 7.05282 35.6203 7.19421 35.501 7.31322L7.31353 35.5007C7.07294 35.7413 6.74664 35.8765 6.4064 35.8765C6.06617 35.8765 5.73986 35.7413 5.49928 35.5007C5.25869 35.2601 5.12354 34.9338 5.12354 34.5936C5.12354 34.2534 5.25869 33.9271 5.49928 33.6865L33.6868 5.49897C33.8058 5.37966 33.9472 5.28499 34.1028 5.2204C34.2585 5.15581 34.4254 5.12256 34.5939 5.12256C34.7624 5.12256 34.9293 5.15581 35.085 5.2204C35.2406 5.28499 35.382 5.37966 35.501 5.49897Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49897 5.49897C5.37966 5.61799 5.28499 5.75938 5.2204 5.91504C5.15581 6.0707 5.12256 6.23757 5.12256 6.4061C5.12256 6.57463 5.15581 6.7415 5.2204 6.89716C5.28499 7.05282 5.37966 7.19421 5.49897 7.31322L33.6865 35.5007C33.9271 35.7413 34.2534 35.8765 34.5936 35.8765C34.9338 35.8765 35.2601 35.7413 35.5007 35.5007C35.7413 35.2601 35.8765 34.9338 35.8765 34.5936C35.8765 34.2534 35.7413 33.9271 35.5007 33.6865L7.31322 5.49897C7.19421 5.37966 7.05282 5.28499 6.89716 5.2204C6.7415 5.15581 6.57463 5.12256 6.4061 5.12256C6.23757 5.12256 6.0707 5.15581 5.91504 5.2204C5.75938 5.28499 5.61799 5.37966 5.49897 5.49897Z"
        fill="black"
      />
    </svg>
  );
};
