import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Toggle3Positions from "./components/Toggle3Positions";
import { AuthorizedUserContext } from "../../../AuthorizedUserContext";
import { LoaderSVG } from "../../svg/loading";

function ProfilUpdate(props) {
  const data = props.data;
  const idEmployee = props.idEmployee;

  const TogglesHR = data.data.RH;
  const TogglesAccounting = data.data.Compta;
  const TogglesIt = data.data.IT;
  const TogglesOther = data.data.Autres;
  const User = useContext(AuthorizedUserContext);

  const rolesUserRolesUser = useContext(AuthorizedUserContext).roles;
  const [isUpdatingLoading, setIsUpdatingLoading] = useState(false);

  const autoriserAffichageRH = (rolesUserRolesUser) => {
    return rolesUserRolesUser.some((role) => role.id === 2 || role.id === 3);
  };

  const onToggleChange = async () => {
    // Vous pouvez passer des arguments à cette fonction si nécessaire, par exemple l'ID du toggle qui a changé
    console.log("Un toggle a changé, mise à jour nécessaire");
    props.onReloadParent(); // Si vous utilisez cette méthode pour informer le parent de recharger les données
  };

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

  useEffect(() => {
    // Logic to reload the component when reloadParent changes
    console.log("ProfilUpdate component reloaded");
  }, [props.profileData, props.onReloadParent]);

  const CancelList = async () => {
    setIsUpdatingLoading(true);

    const confirmCancel = window.confirm(
      "Êtes-vous sûr de vouloir supprimer la liste des tâches?"
    );

    if (confirmCancel) {
      const requestData = {
        todo_list_id: data.id_todo_list,
      };

      // Prepare options for the POST request
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Perform the POST request directly from the React application
        const response = await fetch(
          process.env.REACT_APP_CANCEL_TODO_LIST,
          requestOptions
        );

        //update employee status
        const statusRequest = {
          id_employee: idEmployee,
          status: 1,
        };

        const statusRequestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(statusRequest),
        };

        await fetch(
          process.env.REACT_APP_UPDATE_EMPLOYEE_STATUS,
          statusRequestOptions
        ).then((response) => {
          if (!response.ok) {
            throw new Error(
              `Failed to update employee status, status: ${response.status}`
            );
          }
        });

        // Check if the OPTIONS request is necessary
        if (response.status === 200) {
          const result = await response.text();
          // Handle the server response if needed
          console.log(result);

          // Call the parent callback to trigger a reload
          props.onReloadParent();

          setIsUpdatingLoading(false);
        } else {
          console.error("Request error:", response.status);
          alert("Error : can't cancel todo list");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setIsUpdatingLoading(false);
    }
  };

  const UpdateEmploye = async () => {
    setIsUpdatingLoading(true);
    const confirmUpdate = window.confirm(
      "Êtes-vous sûr de vouloir valider la modification ?"
    );

    if (confirmUpdate) {
      const requestData = {
        id_todo_list: data.id_todo_list,
        type: "modification",
        id_employee: idEmployee,
        validator: User.id,
      };

      console.log(requestData);

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_CONFIRM_TODO_LIST_CLOSE,
          requestOptions
        );

        //add history
        const historyRequest = {
          employe: idEmployee,
          type_log: 2,
          comment: 'Liste des tâches "modification" validé ',
          validator: User.id,
        };
        const historyRequestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(historyRequest),
        };

        await fetch(
          process.env.REACT_APP_CREATE_HISTORY,
          historyRequestOptions
        ).then((response) => {
          if (!response.ok) {
            throw new Error(
              `Failed to create history, status: ${response.status}`
            );
          }
        });

        //update employee status
        const statusRequest = {
          id_employee: idEmployee,
          status: 1,
        };

        console.log(statusRequest);

        const statusRequestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(statusRequest),
        };

        await fetch(
          process.env.REACT_APP_UPDATE_EMPLOYEE_STATUS,
          statusRequestOptions
        ).then((response) => {
          if (!response.ok) {
            throw new Error(
              `Failed to update employee status, status: ${response.status}`
            );
          }
        });

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 200) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)
          console.log(result);

          setIsUpdatingLoading(false);

          // Call the parent callback to trigger a reload
          props.onReloadParent();
        } else {
          console.error("Erreur de requête:", response.status);

          alert("erreur lors de la validation");
        }
      } catch (error) {
        console.error("Erreur:", error);
      }
    } else {
      setIsUpdatingLoading(false);
      console.log("Annulation de la validation");
    }
  };

  return (
    <div className="bg-gray p-4 mb-4 create-checks checks">
      <h3 className="mb-2">Modification</h3>

      <Row>
        <Col size={6}>
          {TogglesHR !== undefined && TogglesHR.length > 0 && (
            <div className="user-checks">
              <h5>Ressources Humaines</h5>

              <div className="toggle-list">
                {TogglesHR.map((toggle, index) => (
                  <Toggle3Positions
                    key={index}
                    role={toggle.role}
                    listId={data.id_todo_list}
                    toggleID={toggle.id}
                    initialValue={toggle.status}
                    leftText={toggle.text}
                    onToggleChange={onToggleChange}
                  />
                ))}
              </div>
            </div>
          )}

          {TogglesAccounting !== undefined && TogglesAccounting.length > 0 && (
            <div className="user-checks">
              <h5>Compta</h5>

              <div className="toggle-list">
                {TogglesAccounting.map((toggle, index) => (
                  <Toggle3Positions
                    key={index}
                    role={toggle.role}
                    listId={data.id_todo_list}
                    toggleID={toggle.id}
                    initialValue={toggle.status}
                    leftText={toggle.text}
                    onToggleChange={onToggleChange}
                  />
                ))}
              </div>
            </div>
          )}
        </Col>

        <Col size={6}>
          {TogglesIt !== undefined && TogglesIt.length > 0 && (
            <div className="user-checks">
              <h5>IT</h5>
              <div className="toggle-list">
                {TogglesIt.map((toggle, index) => (
                  <Toggle3Positions
                    key={index}
                    role={toggle.role}
                    listId={data.id_todo_list}
                    toggleID={toggle.id}
                    initialValue={toggle.status}
                    leftText={toggle.text}
                    onToggleChange={onToggleChange}
                  />
                ))}
              </div>
            </div>
          )}

          {TogglesOther !== undefined && TogglesOther.length > 0 && (
            <div className="user-checks">
              <h5>Autres</h5>
              <div className="toggle-list">
                {TogglesOther.map((toggle, index) => (
                  <Toggle3Positions
                    key={index}
                    role={toggle.role}
                    listId={data.id_todo_list}
                    toggleID={toggle.id}
                    initialValue={toggle.status}
                    leftText={toggle.text}
                    onToggleChange={onToggleChange}
                  />
                ))}
              </div>
            </div>
          )}
        </Col>
      </Row>

      {autoriserAffichageRH(rolesUserRolesUser) && (
        <Row>
          <div className="buttons">
            {isUpdatingLoading ? (
              <LoaderSVG />
            ) : (
              <>
                <button
                  type="button"
                  onClick={CancelList}
                  className="btn btn-secondary  p-15"
                >
                  Annuler la liste de tâches
                </button>

                <button
                  type="button"
                  className="btn btn-primary  p-15"
                  onClick={UpdateEmploye}
                >
                  Valider la modification
                </button>
              </>
            )}
          </div>
        </Row>
      )}
    </div>
  );
}

export default ProfilUpdate;
