import React, { useEffect, useState } from "react";
import { CloseSVG } from "../../../svg/close";

import Select from "react-select";

import { LoaderSVG } from "../../../svg/loading";

function ConfirmationRemoveCompanyPopUp({
  message,
  onConfirm,
  onCancel,
  companyObject,
}) {
  const [CompanyOptions, setCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [buttonDisable, setButtonDisable] = useState(true);
  const [IsDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companysResponse = await fetch(
          process.env.REACT_APP_DATA_URL +
            "companies.json?" +
            new Date().getTime()
        );
        const companysParsed = await companysResponse.json();

        const filteredCompanyOptions = companysParsed.companies.filter(
          (company) => company.id_company !== companyObject
        );

        setCompanyOptions(filteredCompanyOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [companyObject]);

  const deleteCompany = async () => {
    if (selectedCompany) {
      setIsDeleteLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        id_company: companyObject,
        new_company: selectedCompany.value,
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      console.log(requestOptions.body);
      try {
        const response = await fetch(
          process.env.REACT_APP_DELETE_COMPANY,
          requestOptions
        );

        if (response.status === 200) {
          const result = await response.text();
          console.log(result);

          setIsDeleteLoading(false);
          onConfirm();
        } else {
          console.error("Request error:", response.status);
          setIsDeleteLoading(false);
          alert("Deletion error");
        }
      } catch (error) {
        setIsDeleteLoading(false);
        console.error("Error:", error);
      }
    } else {
      console.warn("No company selected.");
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onCancel}>
          <CloseSVG />
        </span>
        <h2>Êtes-vous sûr de vouloir supprimer ?</h2>
        <p>{message}</p>

        {IsDeleteLoading ? (
          <LoaderSVG />
        ) : (
          <>
            <div
              className="input-container"
              style={{ display: "flex", marginBottom: "2rem", gap: "10px" }}
            >
              <div className="company--fr">
                <label>Assigner aux employés lié l'entreprise:</label>

                <Select
                  options={CompanyOptions.map((company) => ({
                    value: company.id_company,
                    label: company.name_company ? company.name_company : "",
                  }))}
                  placeholder="Choisir l'entreprise"
                  isClearable={true}
                  onChange={(selectedOption) => {
                    setSelectedCompany(selectedOption);
                    setButtonDisable(!selectedOption);
                  }}
                />
              </div>
            </div>

            <div className="buttons">
              <button onClick={onCancel} className="btn btn-secondary">
                Annuler
              </button>

              <button
                onClick={deleteCompany}
                disabled={buttonDisable}
                className="btn btn-primary"
              >
                Confirmer la suppression
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ConfirmationRemoveCompanyPopUp;
