import React, { useEffect, useState } from "react";
import { CloseSVG } from "../../../svg/close";

import Select from "react-select";
import TeamAddPopup from "./AddTeam";
import { LoaderSVG } from "../../../svg/loading";

function ConfirmationRemoveTeamPopUp({
  message,
  onConfirm,
  onCancel,
  teamObject,
  addteam,
}) {
  const [teamOptions, setFonctionOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null); // State to track the selected team

  const [AddTeamView, setAddTeamView] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [IsDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //teams
        const teamsResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "teams.json?" + new Date().getTime()
        );
        const teamsParsed = await teamsResponse.json();
        setFonctionOptions(teamsParsed.teams);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const teamsResponse = await fetch(
          process.env.REACT_APP_DATA_URL + "teams.json?" + new Date().getTime()
        );
        const teamsParsed = await teamsResponse.json();

        const filteredCompanyOptions = teamsParsed.teams.filter(
          (team) => team.id_team !== teamObject
        );

        setFonctionOptions(filteredCompanyOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [teamObject]);

  const deleteTeam = async () => {
    if (selectedTeam) {
      setIsDeleteLoading(true);

      // Préparez les en-têtes pour la requête POST
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

      const requestData = {
        id_team: teamObject,
        new_team: selectedTeam.value,
      };

      // Préparez les options pour la requête POST
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestData),
      };

      try {
        // Effectuez la requête POST directement depuis l'application React
        const response = await fetch(
          process.env.REACT_APP_DELETE_TEAM,
          requestOptions
        );

        // Vérifiez si la requête OPTIONS préalable est nécessaire
        if (response.status === 200) {
          const result = await response.text();
          // Gérez la réponse du serveur (si nécessaire)
          console.log(result);

          setIsDeleteLoading(false);
          onConfirm();
        } else {
          console.error("Erreur de requête:", response.status);
          setIsDeleteLoading(false);
          alert("Erreur lors de la suppression");
        }
      } catch (error) {
        setIsDeleteLoading(false);
        console.error("Erreur:", error);
      }
    } else {
      console.warn("No team selected.");
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-button" onClick={onCancel}>
          <CloseSVG />
        </span>
        <h2>Êtes-vous sûr de vouloir supprimer ?</h2>
        <p>{message}</p>

        {IsDeleteLoading ? (
          <LoaderSVG />
        ) : (
          <>
            <div
              className="input-container"
              style={{ display: "flex", marginBottom: "2rem", gap: "10px" }}
            >
              <div className="fonction-fr">
                <label>Team :</label>
                <Select
                  menuPlacement="auto"
                  menuPosition="fixed"
                  options={teamOptions.map((team) => ({
                    value: team.id_team,
                    label: team.name_team ? team.name_team : "",
                  }))}
                  placeholder="Sélectionnez la Team"
                  isClearable={true}
                  onChange={(selectedOption) => {
                    setSelectedTeam(selectedOption);
                    setButtonDisable(!selectedOption); // Si selectedOption est null (pas d'option sélectionnée), alors setButtonDisable(true), sinon setButtonDisable(false)
                  }}
                />
              </div>
            </div>

            {AddTeamView && (
              <TeamAddPopup onAddClick={addteam} onCancelAdd={setAddTeamView} />
            )}

            <div className="buttons">
              <button onClick={onCancel} className="btn btn-secondary">
                Annuler la suppression
              </button>

              <button
                onClick={deleteTeam}
                disabled={buttonDisable}
                className="btn btn-primary"
              >
                Valider la suppression
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ConfirmationRemoveTeamPopUp;
