export const FreecomersSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <g clipPath="url(#clip0_186_676)">
        <path
          d="M16.25 14.7063C17.3274 14.7063 18.3608 14.2783 19.1226 13.5164C19.8845 12.7546 20.3125 11.7212 20.3125 10.6438C20.3125 9.56636 19.8845 8.53304 19.1226 7.77118C18.3608 7.00931 17.3274 6.5813 16.25 6.5813C15.1726 6.5813 14.1392 7.00931 13.3774 7.77118C12.6155 8.53304 12.1875 9.56636 12.1875 10.6438C12.1875 11.7212 12.6155 12.7546 13.3774 13.5164C14.1392 14.2783 15.1726 14.7063 16.25 14.7063Z"
          fill="black"
        />
        <path
          d="M3.25 1.625C2.38805 1.625 1.5614 1.96741 0.951903 2.5769C0.34241 3.1864 0 4.01305 0 4.875L0 19.5C0 20.362 0.34241 21.1886 0.951903 21.7981C1.5614 22.4076 2.38805 22.75 3.25 22.75H22.75C23.612 22.75 24.4386 22.4076 25.0481 21.7981C25.6576 21.1886 26 20.362 26 19.5V4.875C26 4.01305 25.6576 3.1864 25.0481 2.5769C24.4386 1.96741 23.612 1.625 22.75 1.625H3.25ZM1.625 4.875C1.625 4.44402 1.7962 4.0307 2.10095 3.72595C2.4057 3.4212 2.81902 3.25 3.25 3.25H6.5V6.5H1.625V4.875ZM8.125 21.125V3.25H22.75C23.181 3.25 23.5943 3.4212 23.899 3.72595C24.2038 4.0307 24.375 4.44402 24.375 4.875V19.5C24.375 19.9631 24.18 20.3824 23.8713 20.6781C22.9937 18.6778 20.8358 16.25 16.25 16.25C11.3149 16.25 9.18937 19.0613 8.45325 21.125H8.125ZM1.625 17.875H6.5V21.125H3.25C2.81902 21.125 2.4057 20.9538 2.10095 20.649C1.7962 20.3443 1.625 19.931 1.625 19.5V17.875ZM6.5 16.25H1.625V13H6.5V16.25ZM6.5 11.375H1.625V8.125H6.5V11.375Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_186_676">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
